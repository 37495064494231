const initialState = {
  claims: [],
  claim: null,
  profiles: [],
  entities: [],
  submit_status: false,
  error_message: "",
};

export default function claim(state = initialState, action) {
  switch (action.type) {
    case "SET_CLAIMS":
      return {
        ...state,
        claims: action.payload,
      };
    case "SET_CLAIM":
      return {
        ...state,
          claim: action.payload
      }
    case "SET_PROFILES":
      return {
        ...state,
        profiles: action.payload,
      };
    case "SET_ENTITIES":
      return {
        ...state,
        entities: action.payload,
      };
    case "SEND_CLAIM":
      return {
        ...state,
        submit_status: action.payload.success,
        error_message: action.payload.message,
      };
    default:
      return state;
  }
}
