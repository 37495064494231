const initalState = {
  member: null,
  stats: null,
  transactions: [],
  errors: null
};

export default function member (state = initalState, action) {
  switch (action.type) {
    case "SET_MEMBER" : 
      return {
        ...state,
        member: action.payload.member,
        stats: action.payload.stats,
        transactions: action.payload.transactions
      };
    case "SET_ERROR" : 
      return {
        ...state,
        errors: action.payload
      };
    default:
      return state;
  }
}

