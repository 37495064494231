import React, { Component } from 'react';
import SideBar from '../../partials/SideBar';
import HeaderBar from '../../partials/Header/HeaderBar';
import { getStores } from '../../../store/actions/stores';
import { fetchProfile, updateProfile } from '../../../store/actions/invoiceProfile';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Redirect } from 'react-router-dom';
import MultiStoreSelector from '../widgets/stores/MultiStoreSelector';
import {formatStoresForSelect} from '../../../helpers/stores';
import RegionSelector from '../widgets/RegionSelector';

class EditInvoiceProfile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      entity_name: '',
      address: '',
      region: '',
      postal_code: '',
      vat_number: '',
      bank: '',
      branch_code: '',
      account_number: '',
      include_vat: '',
      loading: false,
      stores: [],
      system_stores: []
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount () {
    const {match: {params}} = this.props;
    this.setState({
      loading: true
    });
    this.props.fetchProfile(params.id)
      .then(() => {
        this.setState({
          entity_name: this.props.profile.entity_name,
          address: this.props.profile.address,
          region: this.props.profile.region,
          postal_code: this.props.profile.postal_code,
          vat_number: this.props.profile.vat_number,
          include_vat: this.props.include_vat,
          bank: this.props.profile.bank,
          branch_code: this.props.profile.branch_code,
          account_number: this.props.profile.account_number,
          stores: this.props.profile.stores
        });
        this.props.getStores()
          .then(() => {
            this.setState({
              loading: false,
              system_stores: this.props.stores ?? []
            });
          });
      });
  }

  saveProfile = async (e) => {
    const {match: {params}} = this.props;
    e.preventDefault();
    this.setState({
      loading: true
    });
    let data = {
      entity_name: this.state.entity_name,
      address: this.state.address,
      region: this.state.region,
      postal_code: this.state.postal_code,
      vat_number: this.state.vat_number,
      include_vat: this.state.include_vat,
      bank: this.state.bank,
      branch_code: this.state.branch_code,
      account_number: this.state.account_number,
      stores: this.state.stores
    };
    await this.props.updateProfile(params.id, data);
    if (this.props.status) {
      this.setState({
        redirect: true
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  handleRegionChange = (value) => {
    this.setState({region: value});
  }

  setSelectedStores = (stores) => {
    this.setState({
      stores: stores
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/invoice/profiles' />
    }
    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-12'>
                <div className='pull-right'>
                  <a href='/invoice/profiles' className='btn btn-link'>Back</a>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <h3>Edit Invoice Profile</h3>
                  </div>
                  <div className='card-body'>
                    <div className='form-group'>
                      <label>Entity name</label>
                      <input type='text' name='entity_name' disabled={this.state.loading} className='form-control' onChange={this.handleChange} value={this.state.entity_name} />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.entity_name}</span>
                        )
                      }
                    </div>

                    <div className='form-group'>
                      <label>Address</label>
                      <input type='text' name='address' disabled={this.state.loading} className='form-control' onChange={this.handleChange} value={this.state.address}  />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.address}</span>
                        )
                      }
                    </div>

                    <div className='form-group'>
                      <label>Region</label>
                      <RegionSelector
                        selectedValue={this.state.region}
                        handleChange={this.handleRegionChange}
                      />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.region}</span>
                        )
                      }
                    </div>

                    <div className='form-group'>
                      <label>Postal code</label>
                      <input type='text' name='postal_code' disabled={this.state.loading} className='form-control'  onChange={this.handleChange} value={this.state.postal_code} />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.postal_code}</span>
                        )
                      }
                    </div>

                    <div className='form-group'>
                      <input type='checkbox' name='include_vat' disabled={this.state.loading}  onChange={this.handleChange} value={this.state.include_vat} />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.include_vat}</span>
                        )
                      }
                      <label className='ml-2'>Include vat</label>
                    </div>

                    <div className='form-group'>
                      <label>VAT number</label>
                      <input type='text' name='vat_number' disabled={this.state.loading} className='form-control'  onChange={this.handleChange} value={this.state.vat_number} />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.vat_number}</span>
                        )
                      }
                    </div>

                    <div className='form-group'>
                      <label>Bank</label>
                      <input type='text' name='bank' disabled={this.state.loading} className='form-control'  onChange={this.handleChange} value={this.state.bank} />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.bank}</span>
                        )
                      }
                    </div>

                    <div className='form-group'>
                      <label>Branch code</label>
                      <input type='text' name='branch_code' disabled={this.state.loading} className='form-control'  onChange={this.handleChange} value={this.state.branch_code} />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.branch_code}</span>
                        )
                      }
                    </div>

                    <div className='form-group'>
                      <label>Account number</label>
                      <input type='text' name='account_number' disabled={this.state.loading} className='form-control'  onChange={this.handleChange} value={this.state.account_number} />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.account_number}</span>
                        )
                      }
                    </div>

                    {
                      (this.state.system_stores.length > 0) && (
                        <MultiStoreSelector
                          stores={formatStoresForSelect(this.state.system_stores)}
                          handleSetSelectedStores={this.setSelectedStores}
                          selectedStores={this.state.stores}
                        />
                      )
                    }
                    {
                        this.props.errors && (
                          <p>
                            <span className='text-danger'>{this.props.errors.stores}</span>
                          </p>
                        )
                      }

                    <button type='button' disabled={this.state.loading} onClick={this.saveProfile} className='btn btn-primary'>
                      {
                        this.state.loading && (
                          <Spinner animation='grow' />
                        )
                      }
                      <i className='fa fa-save'> </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stores: state.storesReducer.stores,
    status: state.invoiceProfileReducer.status,
    errors: state.invoiceProfileReducer.errors,
    profile: state.invoiceProfileReducer.profile
  };
};

export default connect (mapStateToProps, { getStores, fetchProfile, updateProfile }) (EditInvoiceProfile);
