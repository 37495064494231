
import http from '../../instances/http';


export const getProfile = () => {
  return dispatch => {
    return http.get('user/me?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_USER",
          payload: response?.data
        });
      })
      .catch(error => {
        dispatch({
          type: "SET_USER",
          payload: error?.data
        });
      });
  };
};

export const updateProfile = (data) => {
  return dispatch => {
    return http.put('user/me?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "UPDATE_USER",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "UPDATE_USER",
          payload: error.data
        });
      });
  };
};

export const createAdmin = (data) => {
  return dispatch => {
    return http.post('auth/register/admin?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "CREATE_USER",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "CREATE_USER",
          payload: error.data
        });
      });
  };
};

export const createClient = (data) => {
  return dispatch => {
    return http.post('auth/register/client?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "CREATE_USER",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "CREATE_USER",
          payload: error.data
        });
      });
  };
};

export const getClients = () => {
  return dispatch => {
    return http.get('client?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_USERS",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "SET_USERS",
          payload: error.data
        });
      });
  };
};

export const getAdmins = () => {
  return dispatch => {
    return http.get('user/admin/all?api_token=' + sessionStorage.getItem('access_token'))
    .then(response => {
      dispatch({
        type: "SET_USERS",
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: "SET_USERS",
        payload: error.data
      });
    });
  };
};

export const deleteUser = (id) => {
  return dispatch => {
    return http.delete('user/delete/' + id + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "DELETE_USER",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "DELETE_USER",
          payload: error.data
        });
      });
  };
};

export const getMembers = () => {
  return dispatch => {
    let url = 'loyalty/member/all';
    return http.get(url + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_USERS",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "SET_USERS",
          payload: error.data
        });
      });
  };
};

export const updateMember = (cell_number, data) => {
  return dispatch => {
    return http.put('loyalty/member/update/' + cell_number + '?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "UPDATE_USER",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "UPDATE_USER",
          payload: error.data
        })
      });
  };
};

export const updateLoyalMember = (cell_number, data) => {
  try {
    const response = http.put('loyalty/member/update/' + cell_number + '?api_token=' + sessionStorage.getItem('access_token'), data)
  return response;
  }
  catch (error) {
    console.log(error);
    return error;
  }



}

export const createManager = (data) => {
  return dispatch => {
    return http.post('auth/register/manager?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "CREATE_USER",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "CREATE_USER",
          payload: error.data
        });
      });
  }
};

export const getManagers = () => {
  return dispatch => {
    let url = 'manager/all';
    /* if (user_id !== null) {
      url = url + '/' + user_id;
    } */
    return http.get(url + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_USERS",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "SET_USERS",
          payload: error.data
        });
      });
  };
};

export const getManager = (id) => {
  return dispatch => {
    return http.get('user/get/' + id + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_MANAGER",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "SET_MANAGER",
          payload: error.data
        });
      });
  };
};

export const updateManager = (id, data) => {
  return dispatch => {
    return http.put('manager/update/' + id + '?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "UPDATE_USER",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "UPDATE_USER",
          payload: error.data
        });
      });
  };
}
