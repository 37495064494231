import http from '../../instances/http';
import { toast } from 'react-toastify';

export const getFields = (promotion_id) => {
  return dispatch => {
    return http.get('field/' + promotion_id + '/all?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_FIELDS",
          payload: response.data.data
        })
      })
      .catch(error => {
        console.error(error.data);
        toast.error('Could not fetch the fields');
      });
  }
}

export const storeField = (data) => {
  return dispatch => {
    return http.post('field?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "SAVE_FIELD",
          payload: response.data
        })
      })
      .catch(error => {
        console.error(error.data);
        toast.error('Could not store the field');
        dispatch({
          type: "SET_FIELD_ERRORS",
          payload: error.data
        })
      });
  }
}

export const deleteField = (id) => {
  return dispatch => {
    return http.delete('field/' + id + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SAVE_FIELD",
          payload: response.data
        })
      })
      .catch(error => {
        toast.error('Could not delete field');
        console.log(error.data);
      });
  } 
}
