import React from 'react';
import HeaderBar from '../../../partials/Header/HeaderBar';
import SideBar from '../../../partials/SideBar';
import {connect} from 'react-redux';
import {
  getAccountProfiles,
  fetchProfiles,
  getAllProfiles,
} from "../../../../store/actions/invoiceProfile";
import DataTable from 'react-data-table-component';
import DeleteStore from '../modals/DeleteStore';
import BeatLoader from "react-spinners/ClipLoader";

class InvoiceProfiles extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      profiles: [],
      account_id: null,
      search: '',
      loading: false,
    };

    
  }
  
  componentDidMount () {
    this.fetchInvoiceProfiles();
  }

  fetchInvoiceProfiles = () => {
    this.setState({
      loading: true,
    });

    const {match: {params}} = this.props;
    this.props.getAllProfiles().then(() => {
      this.setState({
        profiles: this.props.profiles,
        account_id: params.id,
        loading: false,
      });
    });
  }

  render () {
    const columns = [
      {
        name: 'Entity name',
        selector: 'entity_name',
        cell: row => <a href={'/admin/invoice/profiles/edit/' + row.id + '?return=' + this.state.account_id } className="text-primary">{row.entity_name}</a>,
        sortable: true
      },
      {
        name: 'Address',
        selector: 'address',
        sortable: true
      },
      {
        name: 'Region',
        selector: 'region',
        sortable: true
      },
      {
        name: 'Postal code',
        selector: 'postal_code',
        sortable: true
      },
      {
        name: 'VAT number',
        selector: 'vat_number',
        sortable: true
      },
      {
        name: 'Bank',
        selector: 'bank',
        sortable: true
      },
      {
        name: 'Stores',
        selector: row => row.stores.length,
        sortable: true
      },
      {
        name: 'User',
        selector: row => row.user?.name,
        sortable: true
      },
      {
        name: 'Actions',
        cell: row => <DeleteStore id={row.id} />
      }
    ];

      const handleSearch = (e) =>{
      this.setState({
        search: e.target.value,
      })
        
    }
    

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Invoice profiles</h3>
                  </div>
                  <div className="card-header justify-content-between">
                    <div class="align-items-center">
                      <a
                        href={
                          "/admin/invoice/add/profiles?return=" +
                          this.state.account_id
                        }
                        className="btn btn-primary"
                      >
                        <i className="ik ik-plus"> </i>
                      </a>
                    </div>

                    <div class="align-items-center">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search"
                        class="p-2 form-control"
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <DataTable
                      progressPending={this.state.loading}
                      progressComponent={<BeatLoader color="#30A2FF" />}
                      columns={columns}
                      data={this.state.profiles.filter((item) => {
                        if (this.state.search === "") {
                          return item;
                        } else {
                          // return item;

                          return Object.values(item)
                            .join("")
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase());
                        }
                      })}
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profiles: state.invoiceProfileReducer.profiles
  };
};

export default connect(mapStateToProps, {
  getAccountProfiles,
  fetchProfiles,
  getAllProfiles,
})(InvoiceProfiles);