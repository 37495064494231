import React, { Component } from "react";
import HeaderBar from "../../../partials/Header/HeaderBar";
import SideBar from "../../../partials/SideBar";
import { getMembers } from "../../../../store/actions/user";
import { getAccountById } from "../../../../store/actions/account";
import { getProfile } from "../../../../store/actions/user";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { can } from "../../../../helpers/permission";
import MemberStatusModal from "../modals/MemberStatusModal";
import Axios from "axios";
import ImportModal from "../../../partials/ImportModal";
import MemberVipStatusModal from "../modals/MemberVipStatusModal";
import { getBlockedMembers } from "../../../../store/actions/member";
import { formatDate } from "../../../../helpers/date";
import { downLoadExcel } from "../../../../helpers/export";
import MemberBlackListModal from "../modals/MemberBlackListModal";
import EditMember from "./Widgets/EditMember";

class MembersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      roles : '',
      loading: false,
      showMembers: false,
      account_id: null,
      account: null,
      errors: null,
      canViewTransactions: false,
      members: [],
      blocked_members: [],

      email: "",

      q: "",
      paginationData: {},
      pageNumber: 10,
      sortBy: "id",
      sortDirection: "asc",
    };
  }

  fetchProfile = () => {
    this.props.getProfile().then(() => {
      this.setState({
        email: this.props.user.email,
      });

      
    });
  };

  fetchMembers = () => {
    this.props.getProfile().then(() => {
      this.setState({
        canViewTransactions: can(
          "view member transactions",
          this.props.user.permissions
        ),
      });

      this.props.getMembers(this.state.account_id).then((res) => {
        this.setState({
          users: this.props.users,
        });
      });
    });
  };

  filterMembers = (q = "", pagination = false) => {
    const body = {
      page: this.state.current_page,
      page_number: this.state.pageNumber,
      q: pagination ? this.state.q : q,
      sort_by: this.state.sortBy,
      sort_direction: this.state.sortDirection,
    };
    this.setState({
      loading: true,
    });

    const url = `${process.env.REACT_APP_API_URL}loyalty/member/search?api_token=tbTPwCFQk0iQk3RgwPDIAUtpwXkeS08W`;
    Axios.post(url, body)
      .then((res) => {
        const pageData = {
          current_page: res.data.data.current_page,
          first_page_url: res.data.data.first_page_url,
          from: res.data.data.from,
          last_page: res.data.data.last_page,
          last_page_url: res.data.data.last_page_url,
          next_page_url: res.data.data.next_page_url,
          path: res.data.data.path,
          per_page: res.data.data.per_page,
          prev_page_url: res.data.data.prev_page_url,
          to: res.data.data.to,
          total: res.data.data.total,
        };

        this.setState({
          paginationData: pageData,
          members: res.data.data.data,
          loading: false,
        });
      })
      .catch((err) => {});
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      account_id: params.account_id ?? null,
    });
    this.fetchAccount();
    this.fetchProfile();
    // this.fetchBlockedMembers();

    // this.filterMembers()

       // get logged in user roles
    let role = sessionStorage.getItem("roles");
    this.setState({
      roles: role,
    });
  }

  searchMembers = (e) => {
    let searchText = e.target.value;
    this.filterMembers(e.target.value);
    this.setState({
      q: e.target.value,
    });
    // show members on type event
    searchText.length
      ? this.setState({ showMembers: true })
      : this.setState({ showMembers: false });
  };

  fetchAccount = async () => {
    await this.props.getAccountById(this.state.account_id);
    this.setState({
      account: this.props.account,
    });
  };

  fetchBlockedMembers = () => {
    getBlockedMembers()
      .then((res) => {
        this.setState({
          blocked_members: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let Searching = () => {
      return (
        <>
          <span>Searching for members........</span>
        </>
      );
    };

    let columns = [
      {
        name: "Date",
        selector: "created_at",
        cell: (row) => row.created_at.split(" ")[0],
        sortable: true,
      },
      {
        name: "Cell number",
        selector: "cell_number",
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => (row.first_name ?? "") + " " + (row.last_name ?? ""),
        cell: (row) => (row.first_name ?? "") + " " + (row.last_name ?? ""),
        sortable: true,
      },

      {
        name: "Balance",
        selector: "balance",
        width: "80px",
        cell: (row) => row?.balance ?? 0 + " points",
        sortable: true,
      },
      {
        name: "User Status",
        selector: "is_active",
        cell: (row) => (
          <>
            {
              <>
                {this.state?.email?.includes("vetro.co.za") ||
                this.state?.email?.includes("devosd@kingpie.co.za") ||
                this.state?.roles?.includes("Franchise Owner") ||
                this.state?.email?.includes("Kajeez@kingpie.co.za") ? (
                  <MemberStatusModal user={row} />
                ) : row.is_active === 1 ? (
                  <span className="badge badge-success">Active</span>
                ) : (
                  <span className="badge badge-danger">Blocked</span>
                )}
              </>
            }
          </>
        ),
      },
      {
        name: "VIP Status",
        selector: "is_vip",
        cell: (row) => (
          <>
            {
              <>
                {this.state?.email?.includes("vetro.co.za") ||
                this.state?.email?.includes("devosd@kingpie.co.za") ||
                this.state?.roles?.includes("Franchise Owner") ||
                this.state?.email?.includes("Kajeez@kingpie.co.za") ? (
                  <MemberVipStatusModal user={row} />
                ) : row.is_vip === 1 ? (
                  <span className="badge badge-success">VIP</span>
                ) : (
                  <span className="badge badge-danger">Non Vip</span>
                )}
              </>
            }
          </>
        ),
      },

      {
        name: "Blacklist Status",
        selector: "is_blacklist",
        cell: (row) => (
          <>
            {
              <>
                {this.state?.email?.includes("vetro.co.za") ||
                this.state?.email?.includes("devosd@kingpie.co.za") ||
                this.state?.email?.includes("Kajeez@kingpie.co.za") ? (
                  <MemberBlackListModal user={row} />
                ) : row.is_blacklist === 0 || row.is_blacklist === null ? (
                  <span className="badge badge-light">Not Blacklisted</span>
                ) : (
                  <span className="badge badge-dark">Blacklisted</span>
                )}
              </>
            }
          </>
        ),
      },
      {
        name: "Profile",

        cell: (row) => (
          <>
            <a className="btn btn-link" href={"/transactions/" + row.id}>
              <i className="fa fa-eye"> </i>
            </a>
          </>
        ),
      },
      {
        name: "Edit",
        width: "50px",
        cell: (row) => (
          <>
            <EditMember
              id={row?.id}
              name={row?.first_name}
              last_name={row?.last_name}
              cell_number={row?.cell_number}
            />
          </>
        ),
      },
    ];
    let NonAdmincolumns = [
      {
        name: "Date",
        selector: "created_at",
        cell: (row) => row.created_at.split(" ")[0],
        sortable: true,
      },
      {
        name: "Cell number",
        selector: "cell_number",
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => (row.first_name ?? "") + " " + (row.last_name ?? ""),
        cell: (row) => (row.first_name ?? "") + " " + (row.last_name ?? ""),
        sortable: true,
      },
      // {
      //   name: "Gender",
      //   selector: "gender",
      //   sortable: true,
      // },
      // {
      //   name: "Date of birth",
      //   selector: "dob",
      //   sortable: true,
      // },
      {
        name: "Balance",
        selector: "balance",
        cell: (row) => row?.balance ?? 0 + " points",
        sortable: true,
      },
      {
        name: "User Status",
        selector: "is_active",
        cell: (row) => (
          <>
            {
              <>
                {this.state?.email?.includes("vetro.co.za") ||
                this.state?.email?.includes("devosd@kingpie.co.za") ||
                this.state?.roles?.includes("Franchise Owner") ||
                this.state?.email?.includes("Kajeez@kingpie.co.za") ? (
                  <MemberStatusModal user={row} />
                ) : row.is_active === 1 ? (
                  <span className="badge badge-success">Active</span>
                ) : (
                  <span className="badge badge-danger">Blocked</span>
                )}
              </>
            }
          </>
        ),
      },
      {
        name: "VIP Status",
        selector: "is_vip",
        cell: (row) => (
          <>
            {
              <>
                {this.state?.email?.includes("vetro.co.za") ||
                this.state?.email?.includes("devosd@kingpie.co.za") ||
                this.state?.roles?.includes("Franchise Owner") ||
                this.state?.email?.includes("Kajeez@kingpie.co.za") ? (
                  <MemberVipStatusModal user={row} />
                ) : row.is_vip === 1 ? (
                  <span className="badge badge-success">VIP</span>
                ) : (
                  <span className="badge badge-danger">Non Vip</span>
                )}
              </>
            }
          </>
        ),
      },


    

      
     
      {
        name: "Profile",
        cell: (row) => (
          <>
            <a className="btn btn-link" href={"/transactions/" + row.id}>
              <i className="fa fa-eye"> </i>
            </a>
          </>
        ),
      },
    ];

    // const blocked_members_data =
    //   this.state.blocked_members &&
    //   this.state.blocked_members?.map((member) => {
    //     return {
    //       "First Name": member.first_name,
    //       "Last Name": member.last_name,
    //       "Cell Number": member.cell_number,
    //       "Date created": formatDate(member.date_reg),

    //       "Last Store purchased at": member.store_name ?? 0,
    //       "Last transaction Date": formatDate(member.transaction_date) ?? 0,
    //       "Number of transactions": member.transactions ?? 0,
    //       "Crowns Redeemed": member.crowns_redeemed ?? 0,
    //       "Crowns Balance": member.crowns_balance ?? 0,
    //     };
    //   });

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                {this.state.account_id && (
                  <a href="/client">
                    <i className="fa fa-angle-left"> </i> Back to client list
                  </a>
                )}
                <div className="card">
                  <div className="card-header">
                    <h3>
                      {this.state.account
                        ? this.state.account.name + "'s "
                        : "My "}
                      members
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row ">
                      <div className="form-group col-md-4">
                        <label>&nbsp;</label>
                        <input
                          value={this.state.q}
                          type="search"
                          onChange={this.searchMembers}
                          onPaste={this.searchMembers}
                          className="form-control"
                          placeholder="Search members"
                        />
                      </div>

                      {this.state?.email?.includes("vetro.co.za") ||
                      this.state?.email?.includes("vetro.co.za") ||
                      this.state?.email?.includes("Kajeez@kingpie.co.za") ? (
                        <div className="import-modal-container">
                          <ImportModal data={this.state?.members} />
                        </div>
                      ) : null}

                      <div className="admin-container">
                        <a
                          href="/user/admin/unblocked-members"
                          className="btn btn-primary"
                        >
                          Admin
                        </a>
                      </div>
                    </div>

                    {this.state.showMembers && (
                      <DataTable
                        paginationServer={true}
                        columns={ this.state?.email?.includes("vetro.co.za") ||
                this.state?.email?.includes("devosd@kingpie.co.za") ||
                // this.state?.roles?.includes("Franchise Owner") ||
                this.state?.email?.includes("Kajeez@kingpie.co.za") ? columns : NonAdmincolumns}
                        data={this.state.members}
                        responsive={true}
                        noDataComponent="No members found"
                        pagination
                        progressPending={this.state.loading}
                        progressComponent={<Searching />}
                        className="transaction-table"
                        paginationTotalRows={parseInt(
                          this.state.paginationData?.total
                        )}
                        paginationPerPage={parseInt(this.state.pageNumber)}
                        onChangePage={(page, value) => {
                          this.setState({
                            current_page: page,
                          });
                          this.filterMembers("", true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.userReducer.users,
    account: state.accountReducer.account,
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps, {
  getMembers,
  getAccountById,
  getProfile,
})(MembersList);
