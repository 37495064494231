import React from 'react'
import { BounceLoader } from 'react-spinners'

function Loader() {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <BounceLoader color="#000" size={50} />
    </div>
  );
}

export default Loader