import React from "react";
import SideBar from "../../../partials/SideBar";
import HeaderBar from "../../../partials/Header/HeaderBar";
import Select from "react-select";
import { fetchMonths, fetchYears } from "../../../../helpers/date";
import { getProfiles, getEntities } from "../../../../store/actions/claim";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import InvoiceProfileStores from "./partials/InvoiceProfileStores";
import InvoicePreviewModal from "./partials/InvoicePreviewModal";

class AddClaim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      month: new Date().getMonth() + 1,
      entity: null,
      year: "2021",
      months: fetchMonths(new Date().getFullYear()),
      years: fetchYears(),
      entities: [],
      profiles: [],
      isShowingSendNowMonth: false,
      isShowingSendNowYear: false,
      isShowingSendNow: false,
      loading: false
    };
  }

  handleMonthChange = (option) => {
    this.setState(
      {
        month: option.value,
        profiles: [],
      });
  };

  handleEntityChange = (option) => {
    
    this.setState(
      {
        entity: option.value,
        profiles: [],
      },
      () => {
        console.log(`Selected entity: ${this.state.entity}`);
      }
    );
  };

  handleYearChange = (option) => {
    this.setState(
      {
        year: option.value,
        profiles: [],
        month: null,
        months: [],
      },
      () => {
        // Callback function to ensure state is updated before logging
        const updatedMonths = fetchMonths(option.value);
        this.setState(
          {
            months: updatedMonths,
          });
      }
    );
  };

  componentDidMount() {
    setTimeout(() => {
      this.fetchEntities();
    }, 1000);
  }

  fetchEntities = () => {
    this.props.getEntities().then(() => {
      this.setState({
        entities: this.props.entities,
      });
    });
  };

  fetchProfiles = () => {
    this.setState({
      loading: true,
      profiles: []
    });

    this.props
      .getProfiles(this.state.month, this.state.year, this.state.entity)
      .then(() => {
        this.setState({
          profiles: this.props.profiles,
          loading: false
        });
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      profiles: [],
    });
  };

  render() {
    const columns = [
      {
        name: "Invoice Profile",
        selector: "entity_name",
        sortable: true,
      },
      {
        name: "Stores",
        minWidth: "50%",
        cell: (row) => (
          <>
            <InvoiceProfileStores stores_data={row.stats.stores_data} />
          </>
        ),
      },
      {
        name: "Actions",
        cell: (row) => (
          <>
            <InvoicePreviewModal
              profile={row}
              month={this.state.month}
              year={this.state.year}
              send={row.can_claim}
            />
          </>
        ),
      },
    ];

    const entityOptions = this.state.entities.length > 0 && this.state.entities.map((entity, index) => {
      console.log(entity)
      return {
        value: entity.id,
        label: entity.entity_name
      }
    })

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Submit a claim</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-3">
                        <label>Select Year</label>
                        <Select
                          options={this.state.years}
                          onChange={this.handleYearChange}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Select Month</label>
                        <Select
                          value={this.state.months.filter(
                            (option) => option.value === this.state.month
                          )}
                          options={this.state.months}
                          onChange={this.handleMonthChange}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>Select Profile</label>
                        {
                          this.state.entities.length > 0 && (
                            <>
                              <Select
                                value={entityOptions.filter(
                                  (option) =>
                                    option.value === this.state.entity
                                )}
                                options={entityOptions}
                                onChange={this.handleEntityChange}
                              />
                            </>
                          )
                        }
                      </div>
                      <div className="form-group col-md-3">
                        <p>&nbsp;</p>
                        <button
                          className="btn btn-primary"
                          onClick={this.fetchProfiles}
                          disabled={this.state.loading}
                        >
                          {
                            this.state.loading ? <>Please wait...</>: <p>Load data</p>
                          }
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <DataTable columns={columns} data={this.state.profiles} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profiles: state.claimReducer.profiles,
    entities: state.claimReducer.entities,
    error_message: state.claimReducer.error_message,
  };
};

export default connect(mapStateToProps, { getProfiles, getEntities })(AddClaim);
