import React from 'react';

class InvoiceProfileStores extends React.Component {
  render () {
    const store_lines = this.props?.stores_data && this.props?.stores_data?.map((data, index) => 
      
      <tr key={index} className="store-row">
        <td className="store-report-item">
          <tr>
            <td>Store name</td> <td>{data.store_name}</td>
          </tr>
          <tr>
            <td>Store code</td> <td>{data.store_code}</td>
          </tr>
          <tr>
            <td>Total crown value</td> <td>{data.total_crown_value} Crowns</td>
          </tr>
          <tr>
            <td>Total amount value</td> <td>ZAR {data.total_amount_value}</td>
          </tr>
          <tr>
            <td>Total free pie redeemed</td> <td>{data.total_free_pie_redeemed}</td>
          </tr>
          <tr>
            <td>Total free pie value</td> <td>ZAR {data.total_free_pie_redeemed_value}</td>
          </tr>
          <tr>
            <td>Total free chip value</td> <td>ZAR {data.total_free_chip_redeemed_value}</td>
          </tr>
          <tr>
            <td>Total free coke value</td> <td>ZAR {data.total_free_coke_redeemed_value}</td>
          </tr>
        </td>
      </tr>
    );

    return (
      <>
        <table width="100%" className="profile-stores">
          <tbody>
            {
              store_lines
            }
          </tbody>
        </table>
      </>
    )
  }
}

export default InvoiceProfileStores;
