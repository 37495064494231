import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import MultipleValueTextInput from "react-multivalue-text-input";
import { toast } from "react-toastify";
import { formatDate } from "../../helpers/date";
import { downLoadExcel } from "../../helpers/export";
import { getMembersReporter } from "../../store/actions/member";
import DateRangePickerWidget from "../pages/widgets/DateRangePicker";
import Loader from "../utils/Loader";

function ImportModal({ data }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [cellNumbers, setCellNumbers] = useState([]);
  const [membersData, setMemberData] = useState();
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );

  const handleDateChange = (ranges) => {
    setStartDate(ranges.start_date);
    setEndDate(ranges.end_date);

    setTimeout(() => {
      getData(ranges.start_date + "00:00:00", ranges.end_date + "23:59:59");
    }, 1000);
  };

  const getData = (start_date, end_date) => {
    setLoading(true);
    let number = cellNumbers.map((item) => item);

    const body = {
      cell_number: number.toString(),

      from: formatDate(start_date + " 00:00:00"),
      to: formatDate(end_date + " 23:59:59"),
    };

    getMembersReporter(body)
      .then((res) => {
        if (res.length > 0) {
          setMemberData(res);
          setLoading(false);
        } else {
          toast.error("No data found in selected period");
          setLoading(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  let columns = [
    {
      name: "Date",
      selector: "register_date",
      cell: (row) => row.registration_date,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: "cell_number",
      cell: (row) => row.cell_number,
      sortable: true,
    },
    {
      name: "Name",
      selector: "name",
      cell: (row) => row.name,
      sortable: true,
    },

    {
      name: "Store Last Purchase",
      selector: "store_last_purchase",
      cell: (row) => row.store_last_purchase,
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      cell: (row) => row.status,
      sortable: true,
    },
    {
      name: "Number of Purchases",
      selector: "number_of_purchases",
      cell: (row) => row.number_of_purchases,
      sortable: true,
    },
    {
      name: "Total Amount Spent",
      selector: "amount_spent",
      cell: (row) => `R${row.amount_spent}`,
      sortable: true,
    },
    {
      name: "Crowns Earned",
      selector: "crowns_earned",
      cell: (row) => row.crowns_earned,
      sortable: true,
    },
    {
      name: "Crowns Redeemed",
      selector: "crowns_redeemed",
      cell: (row) => row.crowns_redeemed,
      sortable: true,
    },
    {
      name: "Period",
      selector: "period",
      cell: (row) => row?.period,
      sortable: true,
    },
  ];
  const members =
    membersData &&
    membersData?.map((item) => {
      return {
        registration_date: item?.registration_date ?? "",
        name: item?.name ?? "",

        cell_number: item?.number ?? "",
        status: item?.is_active ? "active" : "Blocked",
        number_of_purchases: item?.purchases ?? 0,
        amount_spent: item?.amount_spent ?? "0",
        crowns_earned: item?.crowns_earned ?? "0",
        crowns_redeemed: item?.crowns_redeemed ?? "0",

        store_last_purchase: item?.store_name ?? "",
        period: `${formatDate(startDate)} - ${formatDate(endDate)}`,
      };
    });

  const DeleteBtn = () => {
    return <i className="fa fa-trash text-danger ml-10" aria-hidden="true"></i>;
  };

  return (
    <>
      <button className="btn  btn-primary " onClick={handleShow}>
        Import Members
      </button>

      <Modal
        size="xl
      "
        onHide={handleClose}
        show={show}
      >
        <Modal.Header>Import Members</Modal.Header>
        <Modal.Body>
          <div>
            <MultipleValueTextInput
              onItemAdded={(item, allItems) => {
                setCellNumbers(allItems);
              }}
              onItemDeleted={(item, allItems) => {
                setCellNumbers(allItems);
              }}
              label="Phone Numbers"
              name="item-input"
              placeholder="Enter phone numbers to import seperated by comma or press enter"
              values={cellNumbers ?? []}
              deleteButton={<DeleteBtn />}
            />

            {cellNumbers.length > 0 && (
              <>
                <div>
                  <p>Select Period</p>
                  {!loading ? (
                    <DateRangePickerWidget
                      start_date={startDate + " 00:00:00"}
                      end_date={endDate + " 23:59:59"}
                      handleUpdateDates={handleDateChange}
                    />
                  ) : (
                    <Loader />
                  )}
                </div>

               
              </>
            )}
          </div>
          {members?.length > 0 && (
            <div>
              <div className="pt-50 pb-50">
                <button
                  onClick={() => downLoadExcel(members, "members")}
                  className="btn  btn-primary "
                >
                  Download
                </button>
              </div>

              <DataTable
                columns={columns}
                data={members}
                responsive={true}
                noDataComponent="No members found"
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                paginationComponentOptions={{
                  rowsPerPageText: "Rows per page:",
                  rangeSeparatorText: "of",
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                className="transaction-table"
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="btn btn-danger">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ImportModal;
