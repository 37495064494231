const initialState = {
  rewards: [],
  status: false,
  errors: null
};

export default function reward (state = initialState, action) {
  switch (action.type) {
    case "SET_REWARDS":
      return {
        ...state,
        rewards: action.payload
      };
    case "SAVE_REWARD":
      return {
        ...state,
        status: action.payload.success ?? false
      }
    case "SET_REWARD_ERRORS":
      return {
        ...state,
        errors: action.payload.errors
      }
    default:
      return state;
  }
}
