import React, { useState, useEffect } from "react";
import HeaderBar from "../../partials/Header/HeaderBar";
import SideBar from "../../partials/SideBar";
import MembersShimmer from "../../utils/shimmers/MembersShimmer";
import { getStoreMembers } from "../../../store/actions/stores";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import MemberStatusModal from "../user/modals/MemberStatusModal";
import MemberVipStatusModal from "../user/modals/MemberVipStatusModal";
import DownLoadTransactionsReport from "../user/modals/DownLoadTransactions";
import BackButton from "../../utils/BackButton";

export default function Showstoremembers() {
  const { log } = console;
  const { uid, status } = useParams();
  const [searchText, setSearchText] = useState("");

  const [loading, setLoading] = useState(true);

  const [members, setMembers] = useState([]);

  useEffect(() => {
    const getMembers = () => {
      setLoading(true);
      getStoreMembers(uid, status).then((data) => {
        const members =
          status === "active"
            ? data.active
            : status === "vip"
            ? data.vip
            : data.blocked;

        setMembers(members);

        setLoading(false);
      });
    };

    getMembers();
  }, [uid, status, log]);

  const onSearch = (e) => {
    setSearchText(e.target.value);

    // map then filter the results
    const filteredItems = members
      .map((item) => {
        // merge data in item to lowercase
        const mergeData = Object.values(item).join("").toLowerCase();

        // if search text is not found in mergeData then return undefined else return item
        return mergeData.includes(searchText.toLowerCase()) ? item : undefined;
      })
      .filter((item) => item !== undefined);
    
    if (searchText === "") {
      setMembers(members);
    }
    else {
      setMembers(filteredItems);
    }
   
    
  };

  const membersData =
    members &&
    members?.map((item) => {
      return {
        id: item.id,
        first_name: item.first_name + " " + item.last_name ?? "",
        email: item.email ? item.email : "N/A",
        cell_number: item.cell_number,
        is_active: item.is_active,
        is_vip: item.is_vip,

        created_at: item.created_at,
        updated_at: item.updated_at,
        download: item.id,
      };
    });

  const columnsVip = [
    {
      name: "Date registered",
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Name",
      selector: "first_name",
      sortable: true,
    },

    {
      name: "Phone",
      selector: "cell_number",

      sortable: true,
    },
    {
      name: "Status",
      selector: "is_vip",
      cell: (row) => (
        <>
          {
            <>
              <MemberVipStatusModal user={row} />
            </>
          }
        </>
      ),
    },

    {
      name: "Transaction Report",
      selector: "download",
      cell: (row) => (
        <div className="flex items-center">
          <DownLoadTransactionsReport user={row} store_id={uid} />
        </div>
      ),
    },

    {
      name: "Profile",
      cell: (row) => (
        <>
          <a className="btn btn-link" href={"/transactions/" + row.id}>
            <i className="fa fa-eye"> </i>
          </a>
        </>
      ),
    },
  ];

  const columnsActive = [
    {
      name: "Date registered",
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Name",
      selector: "first_name",
      sortable: true,
    },

    {
      name: "Phone",
      selector: "cell_number",
      sortable: true,
    },
    {
      name: "Status",
      selector: "is_active",

      cell: (row) => (
        <>
          {
            <>
              <MemberStatusModal user={row} />
            </>
          }
        </>
      ),
    },

    {
      name: "Vip Status",
      selector: "is_vip",

      cell: (row) => (
        <>
          <>
            <MemberVipStatusModal user={row} />
          </>
        </>
      ),
    },

    {
      name: "Transaction Report",
      selector: "download",
      cell: (row) => (
        <div className="flex items-center">
          <DownLoadTransactionsReport user={row} store_id={uid} />
        </div>
      ),
    },
    {
      name: "Profile",
      cell: (row) => (
        <>
          <a className="btn btn-link" href={"/transactions/" + row.id}>
            <i className="fa fa-eye"> </i>
          </a>
        </>
      ),
    },
  ];

  return (
    <>
      <HeaderBar />
      <SideBar />
      <main className="main-content">
        {loading ? (
          <MembersShimmer />
        ) : (
          <div className="container-fluid">
            <>
              <BackButton />
            </>

            <div className="row">
              <div className="card">
                <div className="card-header">
                  <h3>
                    {status === "active"
                      ? "Not blocked members"
                      : `${status} members`}{" "}
                  </h3>
                </div>
                <div className="card-body">
                  <div className="row ">
                    <div className="form-group col-md-4">
                      <label>&nbsp;</label>
                      <input
                        value={searchText}
                        type="search"
                        onChange={onSearch}
                          onPaste={onSearch}
                          
                        className="form-control"
                        placeholder="Search members"
                      />
                      </div>
                    
                  </div>
                  {membersData && (
                    <DataTable
                      columns={status === "vip" ? columnsVip : columnsActive}
                      data={membersData}
                      responsive={true}
                      noDataComponent="No members found"
                      pagination
                      progressPending={loading}
                      className="transaction-table"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
}
