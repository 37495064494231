import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { getCategories } from '../../../../store/actions/voucherCategory';
import Spinner from 'react-bootstrap/Spinner';
import DeleteVoucherCategory from '../../widgets/vouchers/DeleteVoucherCategory';
import { getProfile } from '../../../../store/actions/user';
import { can } from '../../../../helpers/permission';
import { BeatLoader } from 'react-spinners';

class VoucherCategoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: false,
      user_id: null,
      canUpdateVoucher: false,
      canAddVoucher: false,
      canDeleteVoucher: false,
      canViewVoucher: false,
    };
  }

  fetchCategories = () => {
    this.loading();
    let user_id = this.props.user_id ?? null;
    this.props.getCategories(user_id).then(() => {
      this.setState({
        categories: this.props.categories,
      });
     
      this.props.getProfile().then(() => {
        this.loading();
        this.setState({
          canUpdateVoucher: can("update voucher", this.props?.user?.permissions),
          canAddVoucher: can("add voucher", this.props?.user?.permissions),
          canDeleteVoucher: can("delete voucher", this?.props?.user?.permissions),
          canViewVoucher: can("view vouchers", this.props?.user?.permissions),
        });
      });
    });
  };

  loading() {
    this.setState({
      loading: !this.state.loading,
    });
  }

  componentDidMount() {
    this.fetchCategories();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.categories !== this.props.categories) {
      this.setState({
        categories: this.props.categories,
      });
    }
  }
  searchVouchers = (e) => {
    let searchText = e.target.value;
    let filteredCatergories = this.props.categories.filter((voucher) => {

      let codes = voucher.voucher_codes.map((code) => code.code).join(" ")
      let phones = voucher.voucher_codes.map((code) => code.cell_number).join(" ")
     

      let row = voucher.name + voucher.description + voucher.created_at + phones + codes;
     
      return row.indexOf(searchText.toString().toLowerCase()) !== -1;



      // let vouchers = voucher?.voucher_codes.some(
      //   (code) =>
      //     code.code +
      //     code.cell_number +
      //     code.name +
      //     code.order_reference +
      //     code.product_code
      // );
      // let row =
      //   voucher.created_at +
      //   voucher.name +
      //   voucher.description +
      //   voucher.vouchers +
      //   voucher?.user?.name +
      //   voucher?.user?.email +
      //   voucher?.user?.cell_number +
      //   vouchers;
      
      // console.log("row?>>" , row)

      

      // return row.indexOf(searchText.toString().toLowerCase()) !== -1;
    });

    this.setState({
      categories: filteredCatergories,
    });
  };

  render() {
    let columns = [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        cell: (row) => (
          <>
            <a href={"/vouchers/" + row.id}>
              <span className={"badge badge-pill mb-1 badge-info text-center"}>
                {row.name}
              </span>
            </a>
          </>
        )
      },
      {
        name: "Description",
        selector: "description",
      },
      {
        name: "Date",
        selector: "created_at",
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <>
            {this.state.canUpdateVoucher && (
              <a
                className="btn btn-link text-primary"
                href={
                  this.state.user_id
                    ? "/voucher/categories/edit/" +
                      row.id +
                      "/" +
                      this.state.user_id
                    : "/voucher/categories/edit/" + row.id
                }
              >
                <i className="fa fa-edit"> </i>
              </a>
            )}
            {this.state.canDeleteVoucher && (
              <DeleteVoucherCategory category={row} />
            )}
          </>
        ),
      },
    ];
    return (
      <>
        
        <div className="row">
          <div className="form-group col-md-4">
            <label>&nbsp;</label>
            <input
              type="text"
              name="search"
              onChange={this.searchVouchers}
              className="form-control"
              placeholder="Search Voucer catergory"
            />
          </div>
        </div>
        <DataTable
          columns={columns}
          progressPending={this.state.loading}
          progressComponent={<BeatLoader color="#30A2FF" />}
          data={this.state.categories}
          pagination
          className="transaction-table"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.voucherCategoryReducer.categories,
    user: state.userReducer.user
  };
};

export default connect (mapStateToProps, { getCategories, getProfile }) (VoucherCategoryTable);
