import React from 'react'
import HeaderBar from '../components/partials/Header/HeaderBar';
import SideBar from '../components/partials/SideBar';
import BackButton from '../components/utils/BackButton';

export default function PageLayout({children}) {
  return (
    <>
      <HeaderBar />
      <SideBar />

      <main className="main-content">
        <section className="container-fluid">
          <>
            <BackButton />
          </>
          {children}
        </section>
      </main>
    </>
  );
}
