import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import account from "./account";
import transactions from './transactions';
import stores from './stores';
import report from './report';
import voucherCategory from './voucherCategory';
import voucher from './voucher';
import permissions from './permissions';
import invoiceProfile from "./invoiceProfile";
import member from "./member";
import claim from "./claim";
import promotion from "./promotion";
import product from "./product";
import reward from "./reward";
import field from "./field";

const rootReducer = combineReducers({
  authReducer: auth,
  userReducer: user,
  accountReducer: account,
  transactionsReducer: transactions,
  storesReducer: stores,
  reportReducer: report,
  voucherCategoryReducer: voucherCategory,
  voucherReducer: voucher,
  permissionsReducer: permissions,
  invoiceProfileReducer: invoiceProfile,
  memberReducer: member,
  claimReducer: claim,
  promotionReducer: promotion,
  productReducer: product,
  rewardReducer: reward,
  fieldReducer: field
});

export default rootReducer;
