import React, { Component } from "react";
import DataTable from "react-data-table-component";
import TransactionTypeWidget from "../TransactionTypeWidget";
import TransactionModal from "../../transaction/modals/TransactionModal";

class TransactionTable extends Component {
  render() {
  const columns = [
    {
      name: "Store name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Store code",
      selector: "store_code",
      sortable: true,
    },
    {
      name: "Crowns",
      selector: "amount",
      sortable: true,
    },
    {
      name: "Type",
      selector: "transaction_type",
      cell: (row) => (
        <TransactionTypeWidget
          type={row.transaction_type === "credit" ? "earn" : "redeem"}
        />
      ),
      sortable: true,
    },
    {
      name: "Order reference",
      selector: "order_reference",
      sortable: true,
    },
    {
      name: "Order value",
      selector: "gross_order_total",
      cell: (row) => "ZAR" + row.gross_order_total,
      sortable: true,
    },
    {
      name: "Order date",
      selector: "order_date",
      sortable: true,
    },
    {
      name: "",
      cell: (row) => <> <TransactionModal id={row.id}/></>,
    },
  ];
    return (
      <>
        <DataTable
          columns={columns}
          data={this.props.transactions}
          className="transaction-table"
          pagination
        />
      </>
    );
  }
}

export default TransactionTable;
