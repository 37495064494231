import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function VoucherStatus({
  status_id,
  row,
  created_at,
  order_date,
  code,
  updated_at,
  store,
  order_reference,
}) {
  const [show, setShow] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    
      <button
        onClick={handleShow}
        className={
          status_id === 2 || status_id === 3
            ? "badge badge-pill mb-1 mr-2 badge-danger cursor-pointer outline-0 border-0   text-center"
            : "badge badge-pill mb-1 mr-2 badge-info cursor-pointer outline-0 border-0  text-center"
        }
      >
        {status_id === 1 ? " Created " : " Redeemed "} on { status_id === 1 ? created_at : order_date}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <div class="form-group">
              <label for="exampleInputEmail1">Voucher Number</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                defaultValue={code}
                readonly
                disabled
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Voucher Status</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                defaultValue={status_id === 1 ? "Created" : "Redeemed"}
                readonly
                disabled
              />
            </div>
            <>
              {status_id === 1 ? (
                <> </>
              ) : (
                <>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Date Redeemed</label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      defaultValue={updated_at}
                      readonly
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">
                      Store Redeemed at (Store code )
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      defaultValue={store}
                      readonly
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Order Reference</label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      defaultValue={order_reference}
                      readonly
                    />
                  </div>{" "}
                </>
              )}
            </>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
