import React, { Component } from 'react';
import SideBar from '../../../partials/SideBar';
import HeaderBar from '../../../partials/Header/HeaderBar';
import { getStores } from '../../../../store/actions/stores';
import {storeProfile } from '../../../../store/actions/invoiceProfile';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import UserSelect from '../../widgets/user/UserSelect';
import MultiStoreSelector from '../../widgets/stores/MultiStoreSelector';
import {formatStoresForSelect} from '../../../../helpers/stores';
import RegionSelector from '../../widgets/RegionSelector';
import {Redirect} from 'react-router-dom';

class AdminAddInvoiceProfile extends Component {

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      account_id: '',
      entity_name: '',
      address: '',
      region: '',
      postal_code: '',
      include_vat: false,
      vat_number: '',
      bank: '',
      branch_code: '',
      account_number: '',
      user: null,
      loading: false,
      stores: [],
      system_stores: [],
      user_id: null
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  goBack(){
    this.props.history.goBack();
  }

  componentDidMount () {
    this.setState({
      loading: true,
      account_id: new URLSearchParams(this.props.location.search).get('return'),
    });
    this.props.getStores()
        .then(() => {
        this.setState({
            loading: false,
            system_stores: this.props.stores ?? []
        });
    });
  }

  saveProfile = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    let data = {
      entity_name: this.state.entity_name,
      address: this.state.address,
      region: this.state.region,
      postal_code: this.state.postal_code,
      include_vat: !this.state.include_vat || this.state.include_vat == 'false'? false: true,
      vat_number: this.state.vat_number,
      bank: this.state.bank,
      branch_code: this.state.branch_code,
      account_number: this.state.account_number,
      stores: this.state.stores,
      user_id: this.state.user_id
    };
    await this.props.storeProfile(data);
    if (this.props.status) {
      this.setState({
        redirect: true
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  handleRegionChange = (value) => {
    this.setState({region: value});
  }

  setSelectedStores = (stores) => {
    this.setState({
      stores: stores
    });
  }

  handleSelectedUserChange = (user) => {
    this.setState({user: user, user_id: user._uid});
  }

  render() {
    if (this.state.redirect) {
      return (<Redirect to={'/admin/invoice/profiles/' + this.state.account_id} />)
    }
    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-right">
                  <a
                    href={"/admin/invoice/profiles/" + this.state.account_id}
                    className="btn btn-link"
                  >
                    Back
                  </a>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h3>Add Invoice Profile</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>User</label>

                   
                        <UserSelect
                          selectedUser={this.state.user}
                          handleSelectedUserChange={
                            this.handleSelectedUserChange
                          }
                        />
                  

                      {this.props.errors && (
                        <span className="text-danger">
                          {this.props.errors.user_id}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Entity name</label>
                      <input
                        type="text"
                        name="entity_name"
                        disabled={this.state.loading}
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.entity_name}
                      />
                      {this.props.errors && (
                        <span className="text-danger">
                          {this.props.errors.entity_name}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        name="address"
                        disabled={this.state.loading}
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.address}
                      />
                      {this.props.errors && (
                        <span className="text-danger">
                          {this.props.errors.address}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label>Region</label>
                      <RegionSelector
                        selectedValue={this.state.region}
                        handleChange={this.handleRegionChange}
                      />
                      {this.props.errors && (
                        <span className="text-danger">
                          {this.props.errors.region}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label>Postal code</label>
                      <input
                        type="text"
                        name="postal_code"
                        disabled={this.state.loading}
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.postal_code}
                      />
                      {this.props.errors && (
                        <span className="text-danger">
                          {this.props.errors.postal_code}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        type="checkbox"
                        name="include_vat"
                        disabled={this.state.loading}
                        onChange={this.handleChange}
                        value={this.state.include_vat}
                      />
                      {this.props.errors && (
                        <span className="text-danger">
                          {this.props.errors.include_vat}
                        </span>
                      )}
                      <label className="ml-2">Include vat</label>
                    </div>

                    <div className="form-group">
                      <label>VAT number</label>
                      <input
                        type="text"
                        name="vat_number"
                        disabled={this.state.loading}
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.vat_number}
                      />
                      {this.props.errors && (
                        <span className="text-danger">
                          {this.props.errors.vat_number}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label>Bank</label>
                      <input
                        type="text"
                        name="bank"
                        disabled={this.state.loading}
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.bank}
                      />
                      {this.props.errors && (
                        <span className="text-danger">
                          {this.props.errors.bank}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label>Branch code</label>
                      <input
                        type="text"
                        name="branch_code"
                        disabled={this.state.loading}
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.branch_code}
                      />
                      {this.props.errors && (
                        <span className="text-danger">
                          {this.props.errors.branch_code}
                        </span>
                      )}
                    </div>

                    <div className="form-group">
                      <label>Account number</label>
                      <input
                        type="text"
                        name="account_number"
                        disabled={this.state.loading}
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.account_number}
                      />
                      {this.props.errors && (
                        <span className="text-danger">
                          {this.props.errors.account_number}
                        </span>
                      )}
                    </div>

                    {this.state.system_stores.length > 0 && (
                      <MultiStoreSelector
                        stores={formatStoresForSelect(this.state.system_stores)}
                        handleSetSelectedStores={this.setSelectedStores}
                        selectedStores={this.state.stores}
                      />
                    )}
                    {this.props.errors && (
                      <p>
                        <span className="text-danger">
                          {this.props.errors.stores}
                        </span>
                      </p>
                    )}

                    <button
                      type="button"
                      disabled={this.state.loading}
                      onClick={this.saveProfile}
                      className="btn btn-primary"
                    >
                      {this.state.loading && <Spinner animation="grow" />}
                      <i className="fa fa-save"> </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stores: state.storesReducer.stores,
    status: state.invoiceProfileReducer.status,
    errors: state.invoiceProfileReducer.errors
  };
};

export default connect (mapStateToProps, { getStores, storeProfile }) (AdminAddInvoiceProfile);
