import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { updateMember } from '../../../../store/actions/user';

import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';


class MemberStatusModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      is_sure: false,
      reason : '',
    };
  }

  handleShow = () => {
   if (this.props?.user?.is_blacklist === 1) {
     toast.error(
       `Can not complete this action, ${this.props.user.first_name} is blacklisted`
     );
   } else {
     this.setState({
       show: true,
     });
   }
  }
  // show text field is answer is yes
  handleOptionSelect = () => {
    this.setState({
      is_sure: true
    });
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  updateActiveStatus = () => {

    //  check if reason is more than 25 charactors
    if (this.state.reason.length < 25) {
     return toast.error("Reason must be more than 25 charactors");
  
    }

    if(!this.state.reason) return toast.error(`Please enter a reason for  ${this.props.user.is_active ? "blocking" : "unblocking"} this member`);
    let is_active = this.props.user.is_active === 1 ? 0 : 1;

    this.setState({
      loading: true
    });
    const data = {
      is_active: is_active,
      // is_vip: this.props.user.is_vip,
      reason_blocked: this.state.reason,
     
    };
    this.props.updateMember(this.props.user.cell_number, data)
      .then(() => {
        if (this.props.status) {

        toast.success(
          ` ${this.props.user.first_name} has been ${
            is_active === 1 ? "unblocked" : "blocked"
          } successfully`
        );
          setTimeout(() => {
            window.location.reload();
          },2000)
          
        
        }
        else {
          toast.error(`Could not ${is_active === 1 ? "unblock" : "block"} ${this.props.user.first_name} at this time please try again later`);
          this.setState({
            loading: false
          })

        }
      }).catch((err) => {
        
        toast.error("Something went wrong, please try again later");
        this.setState({
          loading: false
        });

      }
    )
  }

  render () {
    return (
      <>
        {this.props.user.is_active === 1 && (
          <button className="btn btn-success button" onClick={this.handleShow}>
            <i className="fa fa-lock-open"> </i>
            Active {this.props.admin}
          </button>
        )}
        {this.props.user.is_active === 0 && (
          <button
            className="btn btn-danger button d-flex align-items-center "
            onClick={this.handleShow}
          >
            <i className="fa fa-lock"></i>Blocked
          </button>
        )}

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.is_sure ? (
              <div>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Reason for{" "}
                      {this.props.user.is_active ? "blocking" : "unblocking"}{" "}
                      this member{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.reason}
                      onChange={(e) => {
                        this.setState({
                          reason: e.target.value,
                        });
                      }}
                      placeholder={`Enter reason for ${
                        this.props.user.is_active ? "blocking" : "unblocking"
                      } this member  `}
                    />
                    {this.state.reason.length < 25 ? (
                      <>
                        <small className="text-danger">
                          Reason must be more than 25 characters
                        </small>
                      </>
                    ) : (
                      <>
                        <small className="text-success">valid reason</small>
                      </>
                    )}
                  </Form.Group>

                  <Button
                    disabled={this.state.loading || this.state.reason.length < 25 }
                    variant="primary"
                    onClick={this.updateActiveStatus}
                  >
                    {this.state.loading && <Spinner animation="grow" />}
                    {this.props.user.is_active ? "Block" : "Unblock"}
                  </Button>
                </Form>
              </div>
            ) : (
              <>
                <p>
                  Are sure you want to{" "}
                  {this.props.user.is_active ? "block" : "unblock"} this member?
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!this.state.is_sure && (
              <Button variant="primary" onClick={this.handleOptionSelect}>
                Yes
              </Button>
            )}
            <Button
              disabled={this.state.loading}
              variant="secondary"
              onClick={this.handleClose}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    status: state.userReducer.status
  };
};

export default connect (mapStateToProps, { updateMember }) (MemberStatusModal);