import React from 'react';
import HeaderBar from '../../partials/Header/HeaderBar';
import SideBar from '../../partials/SideBar';
import DataTable from 'react-data-table-component';
import { getVouchers } from '../../../store/actions/promotion';
import { connect } from 'react-redux';

class PromotionVoucherList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      vouchers: []
    };
  }

  componentDidMount() {
    const {match: {params}} = this.props;
    this.props.getVouchers(params.id)
      .then(() => {
        this.setState({
          vouchers: this.props.vouchers
        })
      })
  }

  render () {
    let columns = [
      {
        name: 'Code',
        selector: 'code',
        sortable: true
      },
      {
        name: 'Free Pie',
        selector: 'is_free_pie',
        sortable: true,
        cell: row =>  <>
                        {
                          row.is_free_pie === 1 && (
                            <>
                              <span className='badge badge-danger'>Yes</span>
                            </>
                          )
                        }
                        {
                          row.is_free_pie === 0 && (
                            <>
                              <span className='badge badge-info'>No</span>
                            </>
                          )
                        }
                      </>
      },
      {
        name: 'Discount amount',
        selector: 'discount_amount',
        sortable: true
      },
      {
        name: 'Discount Percent',
        selector: 'discount_percent',
        cell: row => <span>{row.discount_percent} %</span>,
        sortable: true
      },
      {
        name: 'Expiration date',
        selector: 'expires_at',
        sortable: true
      },
      {
        name: 'Status',
        selector: row => row.status_id,
        cell: row =>  <>
                        <span
                          className={(row.status_id === 2) ? 'badge badge-pill mb-1 badge-success text-center': 'badge badge-pill mb-1 badge-info text-center'}>
                            {(row.status_id === 1) ? ' Created ' : ' Redeemed '} on {row.created_at}
                        </span>
                      </>,
        sortable: true
      }];

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className='main-content'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h3>Promotion vouchers</h3>
                  </div>
                  <div className='card-body'>
                    <div className='alert alert-warning'>
                      This is a list of generated vouchers. When a user wins a voucher, the system automatically generates a voucher
                    </div>
                    <DataTable
                      columns={columns}
                      data={this.state.vouchers}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    vouchers: state.promotionReducer.vouchers
  }
};

export default connect(mapStateToProps, {getVouchers}) (PromotionVoucherList);
