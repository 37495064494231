import React, { Component } from 'react';
import './App.css';
import './shimmer.css';
import { BrowserRouter, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import Login from "./components/pages/Login";
import ForgotPassword from "./components/pages/ForgotPassword";
import Profile from "./components/pages/user/Profile";
import WebRoute from "./instances/WebRoute";
import AuthRoute from "./instances/AuthRoute";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StoreList from './components/pages/stores/StoreList';
import AddStore from './components/pages/stores/AddStore';
import EditStore from './components/pages/stores/EditStore';
import MembersList from './components/pages/user/member/MembersList';
import AdminList from './components/pages/user/admin/AdminList';
import ClientList from './components/pages/user/client/ClientList';
import ClientAccount from './components/pages/user/client/ClientAccount';
import MemberTransaction from './components/pages/transaction/MemberTransactions';
import ClientAdminDashboard from './components/pages/dashboard/ClientAdminDashboard';
import VoucherCategoryList from './components/pages/voucher/VoucherCategoryList';
import AddVoucherCategory from './components/pages/voucher/AddVoucherCategory';
import EditVoucherCategory from './components/pages/voucher/EditVoucherCategory';
import voucherList from './components/pages/voucher/VoucherList';
import EditVoucher from './components/pages/voucher/EditVoucher';
import UserList from './components/pages/user/UserList';
import AddUser from './components/pages/user/AddUser';
import EditUser from './components/pages/user/EditUser';
import InvoiceProfileList from './components/pages/invoice/InvoiceProfileList';
import AddInvoiceProfile from './components/pages/invoice/AddInvoiceProfile';
import EditInvoiceProfile from './components/pages/invoice/EditInvoiceProfile';
import InvoiceProfiles from './components/pages/invoice/admin/InvoiceProfiles';
import AdminAddInvoiceProfile from './components/pages/invoice/admin/AdminAddInvoiceProfile';
import AdminEditInvoiceProfile from './components/pages/invoice/admin/AdminEditInvoiceProfile';
import ClaimsList from './components/pages/invoice/claims/ClaimsList';
import AddClaim from './components/pages/invoice/claims/AddClaim';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ShowStore from './components/pages/stores/ShowStore';
import PromotionList from './components/pages/promotion/PromotionList';
import AddPromotion from './components/pages/promotion/AddPromotion';
import EditPromotion from './components/pages/promotion/EditPromotion';
import SurveyList from './components/pages/promotion/SurveyList';
import PromotionTransaction from './components/pages/promotion/PromotionTransaction';
import PromotionProductList from './components/pages/promotion/PromotionProductList';
import PromotionRewardList from './components/pages/promotion/PromotionRewardList';
import PromotionFieldList from './components/pages/promotion/PromotionFieldList';
import PromotionVoucherList from './components/pages/promotion/PromotionVoucherList';
import Showstoremembers from './components/pages/stores/Showstoremembers';
import UnblockedMembersList from './components/pages/user/admin/UnblockedMembersList';
import Reporting from './components/pages/user/admin/Reporting';
import WinnerDraws from './components/pages/invoice/admin/draws';
import DailyDraws from './components/pages/invoice/admin/draws/daily';
import WeeklyDraws from './components/pages/invoice/admin/draws/three-weeks';
import GrandPrizeDraws from './components/pages/invoice/admin/draws/grand-prize';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <AuthRoute path="/" exact={true} component={Dashboard} />
          <AuthRoute path="/profile" exact={true} component={Profile} />
          <AuthRoute path="/reporting" exact={true} component={Reporting} />
          <AuthRoute path="/stores" exact={true} component={StoreList} />
          <AuthRoute path="/stores/create" exact={true} component={AddStore} />
          <AuthRoute
            path="/stores/show/:uid"
            exact={true}
            component={ShowStore}
          />
          <AuthRoute
            path="/stores/storemembers/:status/:uid/"
            exact={true}
            component={Showstoremembers}
          />
          <AuthRoute
            path="/stores/create/:client_id"
            exact={true}
            component={AddStore}
          />
          <AuthRoute
            path="/stores/edit/:id"
            exact={true}
            component={EditStore}
          />
          <AuthRoute
            path="/stores/edit/:id/:client_id"
            exact={true}
            component={EditStore}
          />
          <AuthRoute
            path="/members/:account_id?"
            exact={true}
            component={MembersList}
          />
          <AuthRoute
            path="/transactions/:id"
            exact={true}
            component={MemberTransaction}
          />
          <AuthRoute
            path="/admin/transactions/:cell_number/:account_id"
            exact={true}
            component={MemberTransaction}
          />
          <AuthRoute path="/admin" exact={true} component={AdminList} />
          <AuthRoute path="/admin/draws" exact={true} component={WinnerDraws} />
          <AuthRoute
            path="/admin/draws/daily"
            exact={true}
            component={DailyDraws}
          />
          <AuthRoute
            path="/admin/draws/three-weeks"
            exact={true}
            component={WeeklyDraws}
          />
          <AuthRoute
            path="/admin/draws/grand-prize"
            exact={true}
            component={GrandPrizeDraws}
          />
          <AuthRoute
            path="/client/account/:id"
            exact={true}
            component={ClientAccount}
          />
          <AuthRoute
            path="/client/store/:client_id"
            exact={true}
            component={StoreList}
          />
          <AuthRoute
            path="/client/dashboard/:user_id"
            exact={true}
            component={ClientAdminDashboard}
          />
          <AuthRoute
            path="/admin/voucher/categories/:user_id"
            exact={true}
            component={VoucherCategoryList}
          />
          <AuthRoute
            path="/admin/voucher/categories/add/:user_id"
            exact={true}
            component={AddVoucherCategory}
          />
          <AuthRoute
            path="/voucher/categories/edit/:category_id/:user_id"
            exact={true}
            component={EditVoucherCategory}
          />
          <AuthRoute
            path="/voucher/categories"
            exact={true}
            component={VoucherCategoryList}
          />
          <AuthRoute
            path="/voucher/categories/add"
            exact={true}
            component={AddVoucherCategory}
          />
          <AuthRoute
            path="/voucher/categories/edit/:category_id"
            exact={true}
            component={EditVoucherCategory}
          />
          <AuthRoute
            path="/vouchers/:category_id"
            exact={true}
            component={voucherList}
          />
          <AuthRoute
            path="/vouchers/edit/:voucher_id"
            exact={true}
            component={EditVoucher}
          />
          <AuthRoute
            path="/user/list/:user_id"
            exact={true}
            component={UserList}
          />
          <AuthRoute
            path="/user/add/:user_id"
            exact={true}
            component={AddUser}
          />
          <AuthRoute
            path="/user/edit/:user_id"
            exact={true}
            component={EditUser}
          />
          <AuthRoute
            path="/user/admin/unblocked-members"
            exact={true}
            component={UnblockedMembersList}
          />
          <AuthRoute
            path="/invoice/profiles"
            exact={true}
            component={InvoiceProfileList}
          />
          <AuthRoute
            path="/invoice/profiles/add"
            exact={true}
            component={AddInvoiceProfile}
          />
          <AuthRoute
            path="/invoice/profiles/edit/:id"
            exact={true}
            component={EditInvoiceProfile}
          />
          <AuthRoute
            path="/admin/invoice/profiles/:id?"
            exact={true}
            component={InvoiceProfiles}
          />
          <AuthRoute
            path="/admin/invoice/add/profiles/"
            exact={true}
            component={AdminAddInvoiceProfile}
          />
          <AuthRoute
            path="/admin/invoice/profiles/edit/:id"
            exact={true}
            component={AdminEditInvoiceProfile}
          />
          <AuthRoute path="/claims" exact={true} component={ClaimsList} />
          <AuthRoute path="/claims/add" exact={true} component={AddClaim} />
          <AuthRoute
            path="/promotions"
            exact={true}
            component={PromotionList}
          />
          <AuthRoute
            path="/promotions/add"
            exact={true}
            component={AddPromotion}
          />
          <AuthRoute
            path="/promotions/edit/:id"
            exact={true}
            component={EditPromotion}
          />
          <AuthRoute
            path="/promotions/surveys/:id"
            exact={true}
            component={SurveyList}
          />
          <AuthRoute
            path="/promotions/transactions/:id"
            exact={true}
            component={PromotionTransaction}
          />
          <AuthRoute
            path="/promotions/products/:id"
            exact={true}
            component={PromotionProductList}
          />
          <AuthRoute
            path="/promotions/rewards/:id"
            exact={true}
            component={PromotionRewardList}
          />
          <AuthRoute
            path="/promotions/fields/:id"
            exact={true}
            component={PromotionFieldList}
          />
          <AuthRoute
            path="/promotions/vouchers/:id"
            exact={true}
            component={PromotionVoucherList}
          />
          <WebRoute path="/login" exact={true} component={Login} />
          <WebRoute
            path="/forgot-password"
            exact={true}
            component={ForgotPassword}
          />
        </Switch>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

export default App;
