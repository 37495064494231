import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { storeField } from '../../../../store/actions/field';
import { connect } from 'react-redux';
import Select from 'react-select';

class AddField extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      name: '',
      question: '',
      variable: '',
      is_required: false
    };
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  handleShow = () => {
    this.setState({
      show: true
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  store = () => {
    this.setState({loading: true});
    this.props.storeField({
      name: this.state.name,
      question: this.state.question,
      promotion_id: this.props.promotion_id,
      variable: this.state.variable,
      is_required: this.state.is_required
    })
    .then(() => {
      this.setState({loading: false});
      if (this.props.status) {
        window.location.reload();
      }
    })
  }

  handleRequiredChange = (option) => {
    this.setState({
      is_required: option.value
    });
  }

  render () {
    const required_selection = [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ];

    return (
      <>
        <button className='btn btn-primary' type='button' onClick={this.handleShow}><i className='fa fa-plus'></i></button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Survey Field</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group'>
              <label>Name (To be displayed as report header)</label>
              <input type='text' name='name' className='form-control' onChange={this.handleChange}  />
              {
                this.props.errors && (
                  <span className='text-danger'>{ this.props.errors.name ?? ''}</span>
                )
              }
            </div>
            <div className='form-group'>
              <label>Question (question to be asked to subscriber)</label>
              <input type='text' name='question' className='form-control' onChange={this.handleChange} />
              {
                this.props.errors && (
                  <span className='text-danger'>{this.props.errors.question ?? ''}</span>
                )
              }
            </div>
            <div className='form-group'>
              <label>Variable name</label>
              <input type='text' name='variable' className='form-control' onChange={this.handleChange} />
              {
                this.props.errors && (
                  <span className='text-danger'>{this.props.errors.variable ?? ''}</span>
                )
              }
            </div>
            <div className='form-group'>
              <label>Required</label>
              <Select
                options={required_selection}
                onChange={this.handleRequiredChange}
              />
              {
                this.props.errors && (
                  <span className='text-danger'>{this.props.errors.is_required ?? ''}</span>
                )
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={this.state.loading} variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button disabled={this.state.loading} variant="primary" onClick={this.store}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.fieldReducer.status,
    errors: state.fieldReducer.errors
  }
}

export default connect (mapStateToProps, {storeField}) (AddField);
