import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  updateLoyalMember,
  updateMember,
} from "../../../../../store/actions/user";
import { toast } from "react-toastify";
import Loader from "../../../../utils/Loader";

export default function EditMember({ id, name, last_name, cell_number }) {
  const [firstName, setFirstName] = useState(name ?? "");
  const [lastName, setLastName] = useState(last_name ?? "");
  const [phone, setPhone] = useState(cell_number ?? "");

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onUpdateMember = async (e) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      first_name: firstName,
      last_name: lastName,
      cell_number: phone,
    };

    updateLoyalMember(phone, data)
      .then((res) => {
        if (res) {
          toast.success("Member updated successfully");

          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }
            , 2000);
          
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Something went wrong, could not update member at this time"
        );
        setLoading(false);
      });
  };

  return (
    <div className="">
      <span className="btn btn-link " onClick={handleShow}>
        <i className="fa fa-edit"> </i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>{`Update ${firstName}'s details`}</Modal.Header>
        <Modal.Body>
          <form onSubmit={onUpdateMember}>
            <div className="form-group">
              <label htmlFor="name">First name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                aria-describedby="emailHelp"
                placeholder=" First name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastname">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lastname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Cell Number</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="phone number"
              />
            </div>

            {loading ? (
              <Loader />
            ) : (
              <button
                disabled={loading}
                type="submit"
                onClick={onUpdateMember}
                className="btn btn-primary"
              >
                Update
              </button>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
