import React from 'react';
import HeaderBar from '../../partials/Header/HeaderBar';
import SideBar from '../../partials/SideBar';
import { connect } from 'react-redux';
import { getProducts } from '../../../store/actions/product';
import DataTable from 'react-data-table-component';
import DeleteProduct from './Modals/DeleteProduct';
import AddProduct from './Modals/AddProduct';

class PromotionProductList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      products: [],
      promotion_id: null
    };
  }

  componentDidMount() {
    const {match: {params}} = this.props;
    this.setState({
      promotion_id: params.id
    });
    this.props.getProducts(params.id)
      .then(() => {
        this.setState({
          products: this.props.products
        });
      });
  }

  render () {

    const columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true
      },
      {
        name: 'Code',
        selector: 'code',
        sortable: true
      },
      {
        name: 'Date',
        selector: 'created_at',
        sortable: true
      },
      {
        name: 'Actions',
        cell: row => 
              <>
                <DeleteProduct product={row} />
              </>
      }
    ];

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h3>Promotion products</h3>
                  </div>
                  <div className='card-header'>
                    {
                      this.state.promotion_id !== null && (
                        <>
                          <AddProduct promotion_id={this.state.promotion_id} />
                        </>
                      )
                    }
                  </div>
                  <div className='card-body'>
                    <DataTable
                      columns={columns}
                      data={this.state.products}
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.productReducer.products
  };
};

export default connect(mapStateToProps, { getProducts}) (PromotionProductList);
