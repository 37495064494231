import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { updateMember } from '../../../../store/actions/user';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';


class MemberStatusModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      is_sure: false,
      blacklist_reason : '',
    };
  }

  handleShow = () => {
    this.setState({
      show: true
    });
  }
  // show text field is answer is yes
  handleOptionSelect = () => {
    this.setState({
      is_sure: true
    });
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  updateActiveStatus = () => {

  
    let is_blacklist = this.props.user?.is_blacklist === 0 || this.props.user?.is_blacklist === null ? 1 : 0
    
    
  
    this.setState({
      loading: true
    });
    const data = {
      is_blacklist: is_blacklist,
      
      blacklist_reason: this.state.blacklist_reason,
     
    };
    this.props.updateMember(this.props.user.cell_number, data)
      .then(() => {
        if (this.props.status) {

        toast.success(
          ` ${this.props.user.first_name} has been ${
            is_blacklist === 0 || this.props.user?.is_blacklist === null ? `removed from blacklist` : ` blacklisted`
          } `
        );
          setTimeout(() => {
            window.location.reload();
          },2000)
          
        
        }
        else {
          toast.error(
            `Could not ${is_blacklist === 0 || this.props.user?.is_blacklist === null ? `add ` : "remove"} ${
              this.props.user.first_name
            } ${
              is_blacklist === 0 || this.props.user?.is_blacklist === null ? `to the ` : "from the"
            } blacklist at this time, please try again later`
          );
          this.setState({
            loading: false
          })

        }
      }).catch((err) => {
        
        toast.error("Something went wrong, please try again later");
        this.setState({
          loading: false
        });

      }
    )
  }

  render () {
    return (
      <>
        {this.props.user?.is_blacklist === 1 && (
          <button className="btn btn-dark button" onClick={this.handleShow}>
            
          Blacklisted
          </button>
        )}
        {this.props.user?.is_blacklist === 0 || this.props.user?.is_blacklist === null  ? (
          <button
            className="btn btn-light d-flex align-items-center button "
            onClick={this.handleShow}
          >
         Not Blacklisted
     
          </button>
        ) : <> </>}

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.is_sure ? (
              
              <>
                
                 <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Reason for{" "}
                      {this.props.user.is_blacklist === 0 || this.props.user?.is_blacklist === null ? "adding" : "removing"} this
                      member{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.blacklist_reason}
                      onChange={(e) => {
                        this.setState({
                          blacklist_reason: e.target.value,
                        });
                      }}
                      placeholder={`Enter reason for ${
                        this.props.user.is_blacklist === 0 || this.props.user?.is_blacklist === null ? "adding" : "removing"
                      } ${this.props.user.first_name} ${
                        this.props.user.is_blacklist === 0 || this.props.user?.is_blacklist === null ? "to" : "from"
                      } the Black list`}
                    />
                    {this.state.blacklist_reason.length < 25 ? (
                      <>
                        <small className="text-danger">
                          Reason must be more than 25 characters
                        </small>
                      </>
                    ) : (
                      <>
                        <small className="text-success">valid reason</small>
                      </>
                    )}
                  </Form.Group>
                   < Button
                   disabled = {
                     this.state.loading || this.state.blacklist_reason.length < 25
                   }
                   variant = "primary"
                   onClick = {
                       this.updateActiveStatus
                     } >
                     {
                       this.state.loading && < Spinner animation = "grow" / >
                     } {
                       this.props.user?.is_blacklist === 0 || this.props.user?.is_blacklist === null ?
                         "Add to blacklist" :
                         "remove from blacklist"
                     } 
                     </Button>
                   
                </Form>
                


              
              </>
            ) : (
              <>
                <p>
                  Are sure you want to{" "}
                  {this.props.user.is_blacklist === 0 || this.props.user?.is_blacklist === null  ? "Blacklist" : "unblacklist"} this member?
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!this.state.is_sure && (
              <Button variant="primary" onClick={this.handleOptionSelect}>
                Yes
              </Button>
            )}
            <Button
              disabled={this.state.loading}
              variant="secondary"
              onClick={this.handleClose}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    status: state.userReducer.status
  };
};

export default connect (mapStateToProps, { updateMember }) (MemberStatusModal);