import http from "../../instances/http";
import { toast } from "react-toastify";

export const getPromotions = () => {
  return (dispatch) => {
    return http
      .get("promotion?api_token=" + sessionStorage.getItem("access_token"))
      .then((response) => {
        dispatch({
          type: "SET_PROMOTIONS",
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error("Could not fetch promotions");
      });
  };
};

export const getPromotion = (promotion_id) => {
  return (dispatch) => {
    return http
      .get(
        "promotion/" +
          promotion_id +
          "?api_token=" +
          sessionStorage.getItem("access_token")
      )
      .then((response) => {
        dispatch({
          type: "SET_PROMOTION",
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error("Could not fetch promotion");
      });
  };
};

export const storePromotion = (data) => {
  return (dispatch) => {
    return http
      .post(
        "promotion?api_token=" + sessionStorage.getItem("access_token"),
        data
      )
      .then((response) => {
        dispatch({
          type: "SAVE_PROMOTION",
          payload: response.data,
        });
      })
      .catch((error) => {
        toast.error("Could not save promotion");
        dispatch({
          type: "SAVE_PROMOTION",
          payload: error.data,
        });
      });
  };
};

export const updatePromotion = (id, data) => {
  return (dispatch) => {
    return http
      .put(
        "promotion/" +
          id +
          "?api_token=" +
          sessionStorage.getItem("access_token"),
        data
      )
      .then((response) => {
        dispatch({
          type: "SAVE_PROMOTION",
          payload: response.data,
        });
      })
      .catch((error) => {
        toast.error("Could not save promotion");
        dispatch({
          type: "SAVE_PROMOTION",
          payload: error.data,
        });
      });
  };
};

export const deletePromotion = (id) => {
  return (dispatch) => {
    return http
      .delete(
        "promotion/" +
          id +
          "?api_token=" +
          sessionStorage.getItem("access_token")
      )
      .then((response) => {
        dispatch({
          type: "SAVE_PROMOTION",
          payload: response.data,
        });
      })
      .catch((error) => {
        toast.error("Could not save promotion");
        dispatch({
          type: "SAVE_PROMOTION",
          payload: error.data,
        });
      });
  };
};

export const getSurveys = (promotion_id) => {
  return (dispatch) => {
    return http
      .get(
        "survey/promotion/" +
          promotion_id +
          "/all?api_token=" +
          sessionStorage.getItem("access_token")
      )
      .then((response) => {
        dispatch({
          type: "SET_SURVEYS",
          payload: response.data.data,
        });
      })
      .catch((error) => {
        toast.error("Could not fetch survey data");
        console.log(error.data);
      });
  };
};

export const getTransactions = (promotion_id) => {
  return (dispatch) => {
    return http
      .get(
        "promotion/transactions/" +
          promotion_id +
          "/all?api_token=" +
          sessionStorage.getItem("access_token")
      )
      .then((response) => {
        dispatch({
          type: "SET_TRANSACTIONS",
          payload: response.data.data,
        });
      })
      .catch((error) => {
        toast.error("Could not fetch transaction data");
        console.log(error.data);
      });
  };
};

export const getVouchers = (promotion_id) => {
  return (dispatch) => {
    return http
      .get(
        "promotion/vouchers/" +
          promotion_id +
          "/all?api_token=" +
          sessionStorage.getItem("access_token")
      )
      .then((response) => {
        dispatch({
          type: "SET_PROMOTION_VOUCHERS",
          payload: response.data.data,
        });
      })
      .catch((error) => {
        toast.error("Could not fetch vouchers");
        console.log(error.data);
      });
  };
};

export const getPromotionEntries = async () => {
  try {
    const request = await http
      .get(
        "promotion/entries?api_token=" + sessionStorage.getItem("access_token")
      )
      .then((response) => {
        return response.data.data;
      })

      .catch((error) => {
        console.log(error.response.data);
      });

    return request;
  } catch (error) {
    console.log(error);
  }
};

// get winners

export const getWinners = async () => {
  try {
    const request = await http
      .get(
        "promotion/winners?api_token=" + sessionStorage.getItem("access_token")
      )
      .then((response) => {
        return response.data.data;
      })

      .catch((error) => {
        console.log(error.response.data);
      });

    return request;
  } catch (error) {
    console.log(error);
  }
};

export const saveWinners = async (data) => {
  try {
    const request = await http
      .post(
        "promotion/winners?api_token=" + sessionStorage.getItem("access_token"),
        data
      )
      .catch((error) => {
        console.log(error.response.data);
      });
    return request;
  } catch (error) {
    console.log(error);
  }
};

export const saveBackUpWinners = async (data) => {
  try {
    const request = await http
      .post(
        "promotion/backup_winners?api_token=" +
          sessionStorage.getItem("access_token"),
        data
      )
      .catch((error) => {
        console.log(error.response.data);
      });
    return request;
  } catch (error) {
    console.log(error);
  }
};
