const initialState = {
  permissions: [],
  status: false,
  message: '',
  roles: []
};

export default function permissions(state = initialState, action) {
  switch(action.type) {
    case "SET_PERMISSIONS":
      return {
        permissions: action.payload.data.permissions ?? [],
        roles: action.payload.data.roles ?? [],
        status: action.payload.success ?? false
      };
    default :
      return state;
  };
};
