import React from 'react';
import HeaderBar from '../../partials/Header/HeaderBar';
import SideBar from '../../partials/SideBar';
import TransactionTable from '../widgets/transaction/TransactionTable';
import { connect } from 'react-redux';
import { getTransactions } from '../../../store/actions/promotion';
import { Spinner } from 'react-bootstrap';

class PromotionTransaction extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      loading: false
    };
  }

  componentDidMount() {
    const { match: {params}} = this.props;
    this.setState({
      loading: true
    });
    this.props.getTransactions(params.id)
      .then(() => {
        this.setState({
          loading: false,
          transactions: this.props.transactions
        });
      });
  }

  render () {
    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <a href='/promotions'> Back to promotions </a>
                  </div>
                  <div className='card-header'>
                    <h3>Promotion survey data</h3>
                  </div>
                  <div className='card-body'>
                    {
                      this.state.loading && (
                        <Spinner animation='grow' size='sm' />
                      )
                    }
                    {
                      this.state.transactions.length > 0 && (
                        <TransactionTable transactions={this.state.transactions} />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transactions: state.promotionReducer.transactions
  }
};

export default connect (mapStateToProps, { getTransactions }) (PromotionTransaction);
