import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { updateMember } from '../../../../store/actions/user';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';



class MemberStatusModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      is_sure: false,
      reason: '',
      vip_reason : '',
     
    };
  }

  handleShow = () => {
     if (this.props?.user?.is_blacklist === 1) {
       toast.error(
         `Can not complete this action, ${this.props.user.first_name} is blacklisted`
       );
     }
     else {
        this.setState({
          show: true,
        });
    }

    
   
  
  }
  // show text field is answer is yes
  handleOptionSelect = () => {
    this.setState({
      is_sure: true
    });
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  updateActiveStatus = () => {

  
    let is_vip = this.props.user.is_vip === 1 ? 0 : 1
  
  

      
  
    this.setState({
      loading: true
    });
    const data = {
      is_vip: is_vip,
      // is_active: this.props.user.is_active === 0 ? 1 : 1,
      // reason_blocked: this.state.reason,
      vip_reason: this.state.vip_reason,
    };
    this.props.updateMember(this.props.user.cell_number, data)
      .then(() => {
        if (this.props.status) {

        toast.success(
          ` ${this.props.user.first_name} has been ${
            is_vip === 0
              ? `removed from the vip list`
              : ` added to the vip list`
          } `
        );
          setTimeout(() => {
            window.location.reload();
          },2000)
          
        
        }
        else {
          toast.error(
            `Could not ${is_vip === 1 ? `add ` : "remove"} ${
              this.props.user.first_name
            } ${
              is_vip === 1 ? `to the ` : "from the"
            } vip list at this time, please try again later`
          );
          this.setState({
            loading: false
          })

        }
      }).catch((err) => {
        
        toast.error("Something went wrong, please try again later");
        this.setState({
          loading: false
        });

      }
    )
  }

  render() {
   
    return (
      <>
        {this.props.user.is_vip === 1 && (
          <button
         
            className="btn btn-warning "
            onClick={this.handleShow}
          >
            <i className="fa fa-star"> </i>
            VIP
          </button>
        )}
        {this.props.user.is_vip === 0 && (
          <>
           
              <button
            
                className="btn btn-secondary d-flex align-items-center  "
                onClick={this.handleShow}
              >
                Non Vip
              </button>
          
          </>
        )}

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.is_sure ? (
              <>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Reason for{" "}
                      {this.props.user.is_vip ? "adding" : "removing"} this
                      member{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.vip_reason}
                      onChange={(e) => {
                        this.setState({
                          vip_reason: e.target.value,
                        });
                      }}
                      placeholder={`Enter reason for ${
                        this.props.user.is_vip === 0 ? "adding" : "removing"
                      } ${this.props.user.first_name} ${
                        this.props.user.is_vip === 0 ? "to" : "from"
                      } the VIP list`}
                    />
                    {this.state.vip_reason.length < 25 ? (
                      <>
                        <small className="text-danger">
                          Reason must be more than 25 characters
                        </small>
                      </>
                    ) : (
                      <>
                        <small className="text-success">valid reason</small>
                      </>
                    )}
                  </Form.Group>
                  <Button
                    disabled={
                      this.state.loading || this.state.vip_reason.length < 25
                    }
                    variant="primary"
                    onClick={this.updateActiveStatus}
                  >
                    {this.state.loading && <Spinner animation="grow" />}
                    {this.props.user.is_vip === 0
                      ? "Add to VIP"
                      : "remove from VIP"}
                  </Button>
                </Form>
                <div></div>
              </>
            ) : (
              <>
                <p>
                  Are sure you want to{" "}
                  {this.props.user.is_vip === 0 ? "add" : "remove"} this member?
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!this.state.is_sure && (
              <Button variant="primary" onClick={this.handleOptionSelect}>
                Yes
              </Button>
            )}
            <Button
              disabled={this.state.loading}
              variant="secondary"
              onClick={this.handleClose}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    status: state.userReducer.status
  };
};

export default connect (mapStateToProps, { updateMember }) (MemberStatusModal);