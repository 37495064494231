import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import TransactionTypeWidget from "../../widgets/TransactionTypeWidget";
import { connect } from 'react-redux';
import { getTransaction } from "../../../../store/actions/transactions";
import Spinner from "react-bootstrap/Spinner";

class TransactionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      transaction: null
    };
  }

  handleShow = async () => {
    this.setState({
      show: true
    });
    await this.fetchTransaction();
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  fetchTransaction = async () => {
    this.setState({
      loading: true
    });
    await this.props.getTransaction(this.props.id);
    this.setState({
      transaction: this.props.transaction,
      loading: false
    });
  }

  render() {
    let items = null;
    if (this.state.transaction !== null) {
      items = this.state.transaction.line_items && this.state.transaction.line_items.map((value, index) => {
        return (
            <tr
                key={index}>
              <td>{value.code}</td>
              <td>{value.description}</td>
              <td>{value.quantity}</td>
              <td>{value.price}</td>
            </tr>
        )
      });
    }

    let voucher_items = null;
    if (this.state?.transaction !== null) {
      if(this.state.transaction.vouchers.length > 0) {
        voucher_items =
          this.state?.transaction?.vouchers && this.state?.transaction?.vouchers.map(
            (value, index) => {
              return (
                <tr key={index}>
                  <td>{value.code}</td>
                  <td>{"R " + value.discounted_amount}</td>
                </tr>
              );
            }
          );
      }
    }
    return (
      <>
        <button
          className='btn btn-link text-primary'
          onClick={this.handleShow}
          disabled={this.state.loading}
        >
          <i
            className='fa fa-eye'> </i>
        </button>
        <Modal onHide={this.handleClose} show={this.state.show}>
          <Modal.Header>
            Transaction data
          </Modal.Header>
          <Modal.Body>
            {
              this.state.loading && (
                  <Spinner animation='grow' size='sm' />
                )
            }
            {
              this.state.transaction !== null && (
                  <>
                    <h5>Member details</h5>
                    <p><strong>Member:</strong> {this.state.transaction.user.cell_number}</p>
                    <p><strong>Name:</strong> {(this.state.transaction.user.first_name ?? '') + ' ' + (this.state.transaction.user.last_name ?? '')}</p>
                    <hr/>
                    <h5>Transaction details</h5>
                    <p><strong>Date:</strong> {this.state.transaction.order_date}</p>
                    <p><strong>Type:</strong> <TransactionTypeWidget type={this.state.transaction.transaction_type} /></p>
                    <p><strong>Status:</strong> {this.state.transaction.transaction_status}</p>
                    <p><strong>Points:</strong> {this.state.transaction.amount} Crowns</p>
                    <p><strong>Value:</strong> {'R ' +this.state.transaction.currency_amount}</p>
                    <p><strong>Comments:</strong></p>
                    <p>{this.state.transaction.comments}</p>

                    {
                      this.state.transaction.redeem_transaction !== null && (
                          <>
                            <div className='alert alert-warning'>
                              <p><strong>Redeemed crowns: </strong>{this.state.transaction.redeem_transaction.amount} Crowns</p>
                              <p><strong>Redeemed amount: </strong>{ 'R ' +this.state.transaction.redeem_transaction.currency_amount} </p>
                            </div>
                          </>
                        )
                    }

                    {
                      this.state.transaction.vouchers.length > 0 && (
                            <table width='100%' className='table table-hover'>
                              <thead>
                                <tr>
                                  <th>Code</th>
                                  <th>Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  voucher_items
                                }
                              </tbody>
                            </table>
                        )
                    }

                    {
                        this.state.transaction.line_items && (
                            <>
                              <hr/>
                              <h5>Order details</h5>
                              {
                                <table width='100%' className='table table-hover'>
                                  <thead>
                                  <tr>
                                    <th>Code</th>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {
                                    items
                                  }
                                  </tbody>
                                </table>
                              }
                              <div className='alert alert-info'>
                                <p><strong>Order total: {'R ' + this.state.transaction.order_total}</strong></p>
                                <p><strong>Gross total: {'R ' + this.state.transaction.gross_order_total}</strong></p>
                              </div>
                            </>
                        )
                    }
                  </>
                )
            }

          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.handleClose} className='btn btn-danger'>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transactionsReducer.transaction
  };
};

export default connect(mapStateToProps, {getTransaction}) (TransactionModal);
