const initialState = {
  profiles: [],
  status: false,
  profile: null,
  errors: null
};

export default function invoiceProfile (state = initialState, action) {
  switch (action.type) {
    case "SET_PROFILES":
      return {
        status: action.payload.success ?? false,
        profiles: action.payload.data ?? []
      };
    case "SET_PROFILE":
      return {
        status: action.payload.success ?? false,
        profile: action.payload.data ?? []
      };
    case "STORE_PROFILE":
      return {
        status: action.payload.success ?? false,
        errors: action.payload.errors ?? null
      };
    default: 
      return state;
  }
}