import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import { getCategory, updateCategory } from '../../../store/actions/voucherCategory';
import { Redirect } from 'react-router-dom';
import HeaderBar from '../../partials/Header/HeaderBar';
import SideBar from '../../partials/SideBar';

class EditVoucherCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      id: null,
      name: '',
      description: '',
      user_id: null,
      redirect: false
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  loading() {
    this.setState({
      loading: ! this.state.loading
    });
  }

  componentDidMount () {
    this.fetchCategory();
  }

  fetchCategory = async () => {
    const { match : {params}} = this.props;
    this.setState({
        user_id: params.user_id ?? null
    });
    this.loading();
    await this.props.getCategory(params.category_id);
    this.setState(
      {
        id: this.props.category.id,
        name: this.props.category.name,
        description: this.props.category.description
      }
    );
    this.loading();
  }

  update = async() => {
    this.loading();
    await this.props.updateCategory(this.state.id, this.state);
    this.loading();
    if (this.props.status) {
      this.setState({
        redirect: true
      });
    }
  }

  render () {
    let backUrl = this.state.user_id ? '/admin/voucher/categories/' + this.state.user_id : '/voucher/categories';
    if (this.state.redirect) {
      return <Redirect to={backUrl} />;
    }
    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-12'>
                <a href={ backUrl } className='btn btn-link'>Back</a>
                <div className='card'>
                  <div className='card-header'>
                    <h3>Edit voucher category</h3>
                  </div>
                  <div className='card-body'>
                    <div className='form-group'>
                      <label>Name</label>
                      <input type='text' disabled={this.state.loading} name='name' className='form-control' value={this.state.name} onChange={this.handleChange} />
                      {
                        this.props.errors.name && (
                          <span className='text-danger'>{this.props.errors.name}</span>
                        )
                      }
                    </div>
                    <div className='form-group'>
                      <label>Description</label>
                      <textarea name='description' disabled={this.state.loading} onChange={this.handleChange} className='form-control' value={this.state.description} />
                      {
                        this.props.errors.description && (
                          <span className='text-danger'>{this.props.errors.description}</span>
                        )
                      }
                    </div>
                    <button className='btn btn-primary' onClick={this.update} disabled={this.state.loading}>
                      {
                        this.state.loading && (
                          <Spinner animation='grow' size='sm' />
                        )
                      }
                      <i className='fa fa-save'> </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    category: state.voucherCategoryReducer.category,
    status: state.voucherCategoryReducer.status,
    errors: state.voucherCategoryReducer.errors,
    message: state.voucherCategoryReducer.message
  }
}

export default connect (mapStateToProps, { getCategory, updateCategory }) (EditVoucherCategory);