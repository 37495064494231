import React from 'react';
import HeaderBar from '../../partials/Header/HeaderBar';
import Sidebar from '../../partials/SideBar';
import { getFields } from '../../../store/actions/field';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import AddField from './Modals/AddField';
import DeleteField from './Modals/DeleteField';

class PromotionFieldList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      promotion_id: ''
    };
  }

  componentDidMount() {
    const {match: {params}} = this.props;
    this.setState({
      promotion_id: params.id
    });
    this.props.getFields(params.id)
      .then(() => {
        this.setState({
          fields: this.props.fields
        });
      });
  }

  render () {

    const json_doc = this.state.fields.map((field, index) => 
        <tr key={index}>
          <td>"{field.variable}" : "",</td>
        </tr>
    );

    const columns = [
      {
        name: "Name",
        selector: 'name',
        sortable: true
      },
      {
        name: "Question",
        selector: 'question',
        sortable: true
      },
      {
        name: "Variable name",
        selector: 'variable',
        sortable: true
      },
      {
        name: "Required",
        selector: 'is_required',
        sortable: true,
        cell: row => row.is_required ? 'Yes' : 'No'
      },
      {
        name: "Actions",
        cell: row => 
                      <>
                        <DeleteField field={row} />
                      </>
      }
    ];

    return (
      <>
        <HeaderBar />
        <Sidebar />
        <div className='main-content'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='card'>
                  <div className='card-header'>
                    <h3>Promotion survey fields</h3>
                  </div>
                  <div className='card-header'>
                    <AddField promotion_id={this.state.promotion_id} />
                  </div>
                  <div className='card-body'>
                    <DataTable
                      columns={columns}
                      data={this.state.fields}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='card'>
                  <div className='card-header'>
                    <h3>Promotion survey API details</h3>
                  </div>
                  <div className='card-body'>
                    <p>End point: <span className='alert alert-info'>{process.env.REACT_APP_API_URL + 'survey/{redeem_transaction_id}?api_token={apiToken}'}</span></p>
                    <p>Method: POST</p>
                    <p className='alert alert-warning'>
                      [redeem_transaction_id] is received in the sms for the promotion. It should be used in order to send survey data to the url above.
                    </p>
                    <h5>JSON BODY</h5>
                    <table>
                      <tbody>
                        <tr>
                          <td>{'{'}</td>
                        </tr>
                        <tr>
                          <td>{'"cell_number" : "",'}</td>
                        </tr>
                        <tr>
                          <td>{'"_meta" : {'}</td>
                        </tr>
                        {json_doc}
                        <tr>
                          <td>{'}}'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fields: state.fieldReducer.fields
  };
};

export default connect(mapStateToProps, {getFields}) (PromotionFieldList);
