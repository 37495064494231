import * as XLSX from "xlsx";

export const downLoadExcel = (data, filename = "") => {
  try {
    const ws = XLSX.utils.json_to_sheet(data);
   

    const wb = XLSX.utils.book_new();
  

    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, `${filename}.xlsx`);
  } catch (error) {
    console.log(error);
  }
};
