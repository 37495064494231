import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { formatDate } from "../../../../helpers/date";
import PageLayout from "../../../../layouts/PageLayout";
import { getReports } from "../../../../store/actions/reporting";

import DateRangePickerWidget from "../../widgets/DateRangePicker";

export default function Reporting() {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date(),
  });

  const { log } = console;

  const [reportType, setReportType] = useState("");
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);

  const generateReport = () => {
    const body = {
      from: formatDate(dateRange.startDate + " 00:00:00"),
      to: formatDate(dateRange.endDate + " 23:59:59"),
      report_type: reportType,
    };

    setLoading(true);
    getReports(body)
      .then((data) => {
        if (data.length === 0) {
          toast.warning("No records found for the selected date range");
          setReportData(data);
          setLoading(false);
        } else {
          //  log(data);
          setReportData(data);
          setLoading(false);
          toast.success(
            "Report generated successfully and have emailed it to you"
          );
        }
      })
      .catch((err) => {
        log(err);
        setLoading(false);
        toast.error(
          "Could not generate report at this time, please try again later"
        );
      });
  };

  const handleDateChange = (ranges) => {
    setDateRange({
      startDate: ranges.start_date,
      endDate: ranges.end_date,
    });
  };

  const options = [
    {
      title: "Top 100 Members",
      value: "top_100_members",
    },
    // {
    //   title: "Active Members",
    //   value: "active_members",
    // },
    {
      title: "Blocked Members",
      value: "blocked_members",
    },
    {
      title: "VIP Members",
      value: "vip_members",
    },
    {
      title: "loyalty redemption",
      value: "loyalty_redemption",
    },
    {
      title: "Double the deal report",
      value: "double_the_deal_report",
    },
    {
      title: "Double the burger deal",
      value: "double_the_burger_deal",
    },
  ];

  let type =
    reportType === "top_100_members"
      ? "Top 100 Members"
      : reportType === "active_members"
      ? "Active Members"
      : reportType === "blocked_members"
      ? "Blocked Members"
      : reportType === "vip_members"
      ? "VIP Members"
      : reportType === "loyalty_redemption"
      ? "Loyalty Redemption"
      : reportType === "double_the_deal_report"
      ? "Double the deal report"
      : reportType === "double_the_burger_deal"
      ? "Double the burger deal"
      : "";

  const top_100_members_data =
    reportData &&
    reportData?.map((item) => {
      return {
        name: item.name,
        cell_number: item.cell_number,
        store_registered_at: item.store_registered_at,
        date_registered: item.date_registered,
        current_status: item.active_status,
        vip_status: item.vip_status,
        number_of_transaction: item.number_of_transaction,
        amount_spent: `R${item.amount_spent}`,
        crowns_earned: `${item.crowns_earned} Crowns`,
        crowns_earned_value: ` R${item.crowns_earned_value}`,
        crowns_redeemed: `${item.crowns_redeemed} Crowns`,
        crowns_redeemed_value: `R${item.crowns_redeemed_value}`,
      };
    });

  const blocked_members_data =
    reportData &&
    reportData?.map((item) => {
      return {
        name: item.name,
        cell_number: item.cell_number,
        last_store_transacted_at: item.last_store_transacted_at,
        date_user_registered_for_rr: item.date_user_registered_for_rr,
        date_of_users_last_transaction: item.date_of_users_last_transaction,
        current_crown_balance: `${item.balance} Crowns`,
        transaction_count: item.total_amount,
        total_amount_spent: `R${item.total_amount_spent}`,

        total_crowns_redeemed: `${item.total_crowns_redeemed} Crowns`,
        total_crowns_redeemed_value: `R${item.total_crowns_redeemed_value}`,
      };
    });

  const active_members_data =
    reportData &&
    reportData?.map((item) => {
      return {
        name: item.name,
        cell_number: item.cell_number,
        last_store_transacted_at: item.last_store_transacted_at,
        date_user_registered_for_rr: item.date_user_registered_for_rr,
        date_of_users_last_transaction: item.date_of_users_last_transaction,
        current_crown_balance: `${item.balance} Crowns`,
        transaction_count: item.total_amount,
        total_amount_spent: `R${item.total_amount_spent}`,

        total_crowns_redeemed: `${item.total_crowns_redeemed} Crowns`,
        total_crowns_redeemed_value: `R${item.total_crowns_redeemed_value}`,
      };
    });

  const vip_members_data =
    reportData &&
    reportData?.map((item) => {
      return {
        name: item.name,
        cell_number: item.cell_number,
        last_store_transacted_at: item.store_last_transacted_at,
        date_registered_rr: item.date_registered_rr,
        last_transaction_date: item.date_of_their_last_transcation,
        current_crown_balance: `${item.current_crowns_balance} Crowns`,
      };
    });
  
 

  const redemption_data =
    reportData &&
    reportData?.map((item) => {
      return {
        store_code: item.store_code,
        store_name: item.store,
        crowns_redemption: `${item.crowns_redemption} Crowns`,
        crowns_rand_value_incl_vat: `R${item.crowns_rand_value_incl_vat}`,
        crowns_rand_value_excl_vat: `R${item.crowns_rand_value_excl_vat}`,
        free_pie_redemption: `${item.free_pie_redemption}`,
        free_pie_rand_value_incl_vat: `R${item.free_pie_value_incl_vat}`,
        free_pie_rand_value_excl_vat: `R${item.free_pie_value_excl_vat}`,
        total_crowns_plus_loyalty_large_pies_excl_vat: `R${item.total_crowns_plus_loyalty_large_pies_excl_vat}`,
        vat_transaction: `R${item.vat_transaction}`,
        total_incl_transaction: `R${item.total_incl_transaction}`,
        other_voucher_redeemed: `${item.other_voucher_redeemed}`,
        other_voucher_value_incl_vat: `R${item.other_voucher_value_incl_vat}`,
        other_voucher_value_excl_vat: `R${item.other_voucher_value_excl_vat}`,
        total_loyalty_plus_other_vouchers_excl_vat: `R${item.total_loyalty_plus_other_vouchers_excl_vat}`,
        vat_voucher: `R${item.vat_voucher}`,
        total_incl_voucher: `R${item.total_incl_voucher}`,
      };
    });

  const burger_deals_data =
    reportData &&
    reportData?.map((item) => {
      return {
        store_code: item.store_code,
        store_name: item.store_name,
        total_registrations: item.total_registrations,
        total_registrations_for_period: item.total_registrations_for_period,
        total_double_deals: item.total_double_deals,
        total_double_deals_active_users: item.total_double_deals_active_users,
      };
    });

  // colums

  const top_100_members_columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Cell Number",
      selector: "cell_number",
      sortable: true,
    },
    {
      name: "Store Registered At",
      selector: "store_registered_at",
      sortable: true,
    },
    {
      name: "Date Registered",
      selector: "date_registered",
      sortable: true,
    },
    {
      name: "Current Status",
      selector: "current_status",
      sortable: true,
    },
    {
      name: "VIP Status",
      selector: "vip_status",
      sortable: true,
    },
    {
      name: "Number of Transactions",
      selector: "number_of_transaction",
      sortable: true,
    },
    {
      name: "Amount Spent",
      selector: "amount_spent",
      sortable: true,
    },
    {
      name: "Crowns Earned",
      selector: "crowns_earned",
      sortable: true,
    },
    {
      name: "Crowns Earned Value",
      selector: "crowns_earned_value",
      sortable: true,
    },
    {
      name: "Crowns Redeemed",
      selector: "crowns_redeemed",
      sortable: true,
    },
    {
      name: "Crowns Redeemed Value",
      selector: "crowns_redeemed_value",
      sortable: true,
    },
  ];

  const blocked_members_columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Cell Number",
      selector: "cell_number",
      sortable: true,
    },
    {
      name: "Last Store Transacted At",
      selector: "last_store_transacted_at",
      sortable: true,
    },
    {
      name: "Date User Registered For RR",
      selector: "date_user_registered_for_rr",
      sortable: true,
    },
    {
      name: "Date of Users Last Transaction",
      selector: "date_of_users_last_transaction",
      sortable: true,
    },
    {
      name: "Current Crown Balance",
      selector: "current_crown_balance",
      sortable: true,
    },
    {
      name: "Transaction Count",
      selector: "transaction_count",
      sortable: true,
    },
    {
      name: "Total Amount Spent",
      selector: "total_amount_spent",
      sortable: true,
    },
    {
      name: "Total Crowns Redeemed",
      selector: "total_crowns_redeemed",
      sortable: true,
    },
    {
      name: "Total Crowns Redeemed Value",
      selector: "total_crowns_redeemed_value",
      sortable: true,
    },
  ];

  const active_members_columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Cell Number",
      selector: "cell_number",
      sortable: true,
    },
    {
      name: "Last Store Transacted At",
      selector: "last_store_transacted_at",
      sortable: true,
    },
    {
      name: "Date User Registered For RR",
      selector: "date_user_registered_for_rr",
      sortable: true,
    },
    {
      name: "Date of Users Last Transaction",
      selector: "date_of_users_last_transaction",
      sortable: true,
    },
    {
      name: "Current Crown Balance",
      selector: "current_crown_balance",
      sortable: true,
    },
    {
      name: "Transaction Count",
      selector: "transaction_count",
      sortable: true,
    },
    {
      name: "Total Amount Spent",
      selector: "total_amount_spent",
      sortable: true,
    },
    {
      name: "Total Crowns Redeemed",
      selector: "total_crowns_redeemed",
      sortable: true,
    },
    {
      name: "Total Crowns Redeemed Value",
      selector: "total_crowns_redeemed_value",
      sortable: true,
    },
  ];

  const vip_members_columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Cell Number",
      selector: "cell_number",
      sortable: true,
    },
    {
      name: "Last Store Transacted At",
      selector: "store_last_transacted_at",
      sortable: true,
    },
    {
      name: "Date member Registered For RR",
      selector: "date_registered_rr",
      sortable: true,
    },
    {
      name: "Date of members's Last Transaction",
      selector: "last_transaction_date",
      sortable: true,
    },
    {
      name: "Current Crown Balance",
      selector: "current_crown_balance",
      sortable: true,
    },
  ];

  const redemption_data_columns = [
    {
      name: "Store Code",
      selector: "store_code",
      sortable: true,
    },
    {
      name: "Store Name",
      selector: "store_name",
      sortable: true,
    },
    {
      name: "Crowns Redeemed",
      selector: "crowns_redemption",
      sortable: true,
    },
    {
      name: "Crowns Redeemed Rand Value (Incl VAT)",
      selector: "crowns_rand_value_incl_vat",
      sortable: true,
    },
    {
      name: "Crowns Redeemed Rand Value (Excl VAT)",
      selector: "crowns_rand_value_excl_vat",
      sortable: true,
    },
    {
      name: "Free pie redeemed",
      selector: "free_pie_redemption",
      sortable: true,
    },
    {
      name: "Free pie Rand  value (Incl VAT)",
      selector: "free_pie_rand_value_incl_vat",
      sortable: true,
    },
    {
      name: "Free pie Rand value (Excl VAT)",
      selector: "free_pie_rand_value_excl_vat",
      sortable: true,
    },
    {
      name: "Total crowns + LRG Piess (Excl Vat)",
      selector: "total_crowns_plus_loyalty_large_pies_excl_vat",
      sortable: true,
    },
    {
      name: "VAT",
      selector: "vat_transaction",
      sortable: true,
    },
    {
      name: "Total Incl",
      selector: "total_incl_transaction",
      sortable: true,
    },
    {
      name: "Other Vouchers Redeemed",
      selector: "other_vouchers_redeemed",
      sortable: true,
    },
    {
      name: "Total Loyalty + Other Vouchers (Excl Vat)",
      selector: "total_loyalty_plus_other_vouchers_excl_vat",
      sortable: true,
    },
    {
      name: "Voucher VAT",
      selector: "vat_other_vouchers",
      sortable: true,
    },
    {
      name: "Total Incl",
      selector: "total_incl_other_vouchers",
      sortable: true,
    },
  ];

  const burger_deals_columns = [
    {
      name: "Store Code",
      selector: "store_code",
      sortable: true,
    },
    {
      name: "Store Name",
      selector: "store_name",
      sortable: true,
    },
    {
      name: "Total registrations",
      selector: "total_registrations",
      sortable: true,
    },
    {
      name: "Total Registrations for Period",
      selector: "total_registrations_for_period",
      sortable: true,
    },
    {
      name: "Total Registrations for Period (Excl VAT)",
      selector: "total_registrations_for_period_excl_vat",
      sortable: true,
    },
  ];

  return (
    <PageLayout>
      <div className="row p-10">
        <h5>Admin reporting</h5>

        <div className="card">
          <div className="card-body">
            <select
              value={reportType}
              onChange={(e) => {
                setReportType(e.target.value);

                if (reportData) {
                  setReportData([]);
                }
              }}
              className="report__selection"
              name=""
              id=""
            >
              <option value="">Select report type</option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.title}
                </option>
              ))}
            </select>

            <div className="actions__container">
              <>
                {reportType && (
                  <div>
                    <h6>Date period</h6>
                    <DateRangePickerWidget
                      start_date={dateRange.startDate + " 00:00:00"}
                      end_date={dateRange.endDate + " 00:00:00"}
                      handleUpdateDates={handleDateChange}
                    />
                  </div>
                )}
              </>
            </div>
            <div className="pb-20">
              {reportType && (
                <button
                  disabled={loading}
                  onClick={generateReport}
                  className=" btn btn-primary"
                >
                  {loading ? "Generating report" : "Generate Report"}
                </button>
              )}
            </div>

            <div className="m-10 p-10">
              <h6 className=" p-10">{type && `${type} Report Preview`}</h6>

              <DataTable
                columns={
                  type === "Top 100 Members"
                    ? top_100_members_columns
                    : type === "Blocked Members"
                    ? blocked_members_columns
                    : type === "Active Members"
                    ? active_members_columns
                    : type === "VIP Members"
                    ? vip_members_columns
                    : type === "Loyalty Redemption"
                    ? redemption_data_columns
                    : type === "Double the burger deal"
                    ? burger_deals_columns
                    : []
                }
                data={
                  type === "Top 100 Members"
                    ? top_100_members_data
                    : type === "Blocked Members"
                    ? blocked_members_data
                    : type === "Active Members"
                    ? active_members_data
                    : type === "VIP Members"
                    ? vip_members_data
                    : type === "Loyalty Redemption"
                    ? redemption_data
                    : type === "Double the burger deal"
                    ? burger_deals_data
                    : []
                }
                responsive={true}
                noDataComponent="No data to display selected a report type"
                pagination
                progressPending={loading}
                className="transaction-table"
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
