import React from 'react';
import HeaderBar from '../../partials/Header/HeaderBar';
import SideBar from '../../partials/SideBar';
import { getPromotions } from '../../../store/actions/promotion';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import DeletePromotion from './Modals/DeletePromotion';

class PromotionList extends React.Component {

  constructor (props) {
    super (props);
    this.state = {
      promotions: []
    };
  }

  componentDidMount () {
    this.props.getPromotions()
      .then(() => {
        this.setState({
          promotions: this.props.promotions
        });
      });
  }

  render() {

    const columns = [
      {
        name: 'Name',
        selector: 'name',
        cell: row => 
                    <>
                      <a href={'/promotions/edit/' + row.id} className='text-info btn btn-link'>{row.name}</a>
                    </>,
        sortable: true,
        grow: 2
      },
      {
        name: 'Type',
        selector: 'type_name',
        cell: row => 
                      <>
                        { row.type_name } { row.type === 'order' ? '(Min order value R' + row.min_order_value + ')' : ''}
                      </>,
        sortable: true,
        grow: 2
      },
      {
        name: 'Start',
        selector: 'starts_at',
        sortable: true
      },
      {
        name: 'End',
        selector: 'ends_at',
        sortable: true
      },
      {
        name: 'Status',
        selector: 'is_active',
        cell: row => 
                    <>
                      {
                        row.is_active === 1 && (
                          <i className='fa fa-check text-success'></i>
                        )
                      }
                      {
                        row.is_active === 0 && (
                          <i className='fa fa-times text-danger'></i>
                        )
                      }
                    </>,
        center: true
      },
      {
        name: 'Actions',
        cell: row => 
                    <div className='block-data-items'>
                      {
                        row.has_products && (
                          <>
                            <p><a href={"/promotions/products/" + row.id}><i className='fa fa-angle-right'></i> Manage products</a></p>
                          </>
                        )
                      }
                      {
                        row.has_vouchers && (
                          <>
                            <p><a href={"/promotions/rewards/" + row.id}><i className='fa fa-angle-right'></i> Manage rewards</a></p>
                            <p><a href={"/promotions/fields/" + row.id} ><i className='fa fa-angle-right'></i> Manage questionnaire fields</a></p>
                            <p><a href={"/promotions/surveys/" + row.id}><i className='fa fa-angle-right'></i> Survey report</a></p>
                            <p><a href={"/promotions/vouchers/" + row.id}><i className='fa fa-angle-right'></i> Vouchers</a></p>
                          </>
                        )
                      }
                      <p><a href={"/promotions/transactions/" + row.id }><i className='fa fa-angle-right'></i> Transactions</a></p>
                      <DeletePromotion promotion={row} />
                    </div>,
        grow: 2
      }
    ];

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h3>Promotions</h3>
                  </div>
                  <div className='card-header'>
                    <a className='btn btn-primary' href='/promotions/add'>
                      <i className='fa fa-plus'></i>
                    </a>
                  </div>
                  <div className='card-body'>
                    {
                      this.state.promotions.length > 0 && (
                        <>
                          <DataTable
                            columns={columns}
                            data={this.state.promotions}
                            pagination
                          />
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    promotions: state.promotionReducer.promotions
  }
};

export default connect (mapStateToProps, { getPromotions }) (PromotionList);
