import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { getManagers } from '../../../../store/actions/user';
import { formatUsersForSelect } from '../../../../helpers/user';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';

class UserSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      users: [],
      user: null,
      user_id: '',
      saveEnabled: false
    };
  }

  handleShow = () => {
    this.setState({
      loading: true,
       show: true,

    });
    this.props.getManagers()
      .then(() => {
        this.setState({
         
          loading: false,
          users: this.props.users
        });
      });
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  handleChange = (option) => {
    this.setState({
      user: option.user
    });
  }

  saveUser = () => {
    this.props.handleSelectedUserChange(this.state.user);
    this.handleClose();
  }

  render () {
    return (
      <>
        <p>
          {this.props.selectedUser && (
            <button
              className="btn btn-link user-select"
              onClick={this.handleShow}
              disabled={this.loading}
            >
              <i className="ik ik-user"> </i>
              {this.props.selectedUser.name}
            </button>
          )}
          {this.props.selectedUser === null && (
            <button
              className="btn btn-link user-select btn-danger"
              onClick={this.handleShow}
              disabled={this.loading}
            >
              <i className="ik ik-user"> </i>
              No user selected
            </button>
          )}
        </p>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select a user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              {this.state.users.length ? (
                <>
                  <label>Select user</label>
                  <Select
                    options={formatUsersForSelect(this.state.users)}
                    onChange={this.handleChange}
                  />
                </>
              ) : (
                <>
                  <div>
                      <Spinner animation="grow" size="sm" />
                      <p>Getting users please wait...</p>
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.loading}
              variant="secondary"
              onClick={this.handleClose}
            >
              Close
            </Button>
            <Button
              disabled={this.state.loading || this.state.user === null}
              variant="primary"
              onClick={this.saveUser}
            >
              {this.state.loading && <Spinner animation="grow" size="sm" />}
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.userReducer.users
  };
};

export default connect (mapStateToProps, { getManagers }) (UserSelect);