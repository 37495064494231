import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProfile } from '../../store/actions/user';
import {isAdmin, isClient, isFranchiseManager, isFranchiseOwner, isHeadOffice, isManager} from '../../helpers/user';
import { can } from '../../helpers/permission';
import {getAccount} from '../../store/actions/account';
import ShimmerSidebar from '../partials/ShimmerSidebar';

class SideBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userOpen: true,
      isLoading: false,
      roles: [],
      email : '',
      permissions: [],
      isAdmin: false,
      isClient: false,
      isManager: false,
      isFranchiseOwner: false,
      isFranchiseManager: false,
      isHeadOffice: false,
      account: null,
      active: 'hide',
      
    };
  }

  componentDidMount() {
    this.fetchRole();
  }

  fetchRole = () => {
    this.setState({ isLoading: true });
    this.props.getProfile()
      .then(() => {
        this.setState({
          roles: this.props.user.roles,
          email : this.props.user.email,
          permissions: this.props.user.permissions,
          isAdmin: isAdmin(this.props.user.roles),
          isClient: isClient(this.props.user.roles),
          isManager: isManager(this.props.user.roles),
          isFranchiseOwner: isFranchiseOwner(this.props.user.roles),
          isFranchiseManager: isFranchiseManager(this.props.user.roles),
          isHeadOffice: isHeadOffice(this.props.user.roles)
        });

          this.props.getAccount()
            .then(() => {
              this.setState({
                account: this.props.account
              });
            });

            this.setState({ isLoading: false });
            
      });
  }

  hideSidebar = () => {
    
    const sidebar = document.querySelectorAll(".app-sidebar");

    let list = sidebar[0].classList;
    list.toggle("hide");
    
    if(list.contains('show')){
      list.remove('show');
     
    }

  
    
    
  }



  render() {

    let manageInvoiceProfile = null;
    if (this.state.isAdmin || this.state.isHeadOffice) {
      manageInvoiceProfile = <>
        {
          (this.state.account !== null) && (
                <div className="nav-item">
                  <a href={'/admin/invoice/profiles/' + this.state.account.id}><i className='ik ik-list'> </i>Invoice profiles</a>
                </div>
            )
        }
      </>
    }

    return (
      <>
        {this.state.isLoading ? (
          <div className="app-sidebar colored">
            <ShimmerSidebar />
          </div>
        ) : (
          <>
            {" "}
            {this.state.roles.length && (
              <>
                <div className={`${this.state.active} app-sidebar colored`}>
                  <div className="sidebar-header">
                    <a className="header-brand" href="/">
                      <div className="logo-img">
                        <img
                          src="/assets/img/vetro-media.png"
                          className="header-brand-img"
                          alt="Vetro media"
                        />
                      </div>
                    </a>
                    <button
                      onClick={() => this.hideSidebar()}
                      id="sidebarClose"
                      className="nav-close"
                    >
                      <i className="ik ik-x"> </i>
                    </button>
                  </div>

                  <div className="sidebar-content">
                    <div className="nav-container">
                      <nav
                        id="main-menu-navigation"
                        className="navigation-main"
                      >
                        <div className="nav-item active">
                          <a href="/">
                            <i className="ik ik-bar-chart-2"> </i>
                            <span>Dashboard</span>
                          </a>
                        </div>
                        {this.state.isAdmin && (
                          <>
                            <div className="nav-item">
                              <a href="/admin">
                                <i className="ik ik-user"> </i>Admins
                              </a>
                            </div>
                            <div className="nav-item">
                              <a href="/admin/draws/daily">
                                <i className="ik ik-list"> </i>Winner Draws
                              </a>
                            </div>
                            <div className="nav-item">
                              <a href="/client">
                                <i className="ik ik-user"> </i>Clients
                              </a>
                            </div>
                            <div className="nav-item">
                              <a href="/promotions">
                                <i className="ik ik-star"> </i>Promotions
                              </a>
                            </div>
                            <div className="nav-item">
                              <a href="/voucher/categories">
                                <i className="ik ik-list"> </i>Manage vouchers
                              </a>
                            </div>
                          </>
                        )}
                        {this.state.isClient && (
                          <>
                            <div className="nav-item">
                              <a href="/voucher/categories">
                                <i className="ik ik-list"> </i>Manage Vouchers
                              </a>
                            </div>
                            <div className="nav-item">
                              <a href={"/user/list/" + this.props.user.id}>
                                <i className="ik ik-users"> </i>
                                Manage users
                              </a>
                            </div>
                            <div className="nav-item">
                              <a href="/promotions">
                                <i className="ik ik-star"> </i>Promotions
                              </a>
                            </div>
                          </>
                        )}

                        {manageInvoiceProfile}
                        {this.state.isManager | this.state.isAdmin && (
                          <>
                            {this.state.permissions.length > 0 &&
                              can("view users", this.state.permissions) && (
                                <div className="nav-item">
                                  <a href={"/user/list/" + this.props.user.id}>
                                    <i className="ik ik-users"> </i>Manage users
                                  </a>
                                </div>
                              )}
                          </>
                        )}
                        <div className="nav-item">
                          <a href="/stores">
                            <i className="ik ik-shopping-cart"> </i>Stores
                          </a>
                        </div>
                        <div className="nav-item">
                          <a href="/members">
                            <i className="ik ik-users"> </i>Members
                          </a>
                        </div>
                        <div className="nav-item">
                          <a href="/claims">
                            <i className="ik ik-folder"> </i>Claims
                          </a>
                        </div>
                        {this.state.email.includes("vetro.co.za") && (
                          <div className="nav-item">
                            <a href="/reporting">
                              <i className="ik ik-folder"> </i>
                              Reporting
                            </a>
                          </div>
                        )}
                      </nav>
                    </div>
                  </div>
                </div>
              </>
            )}{" "}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    account: state.accountReducer.account
  };
};

export default connect (mapStateToProps, { getProfile, getAccount }) (SideBar);
