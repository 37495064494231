import React from 'react';
import Select from 'react-select';

class RegionSelector extends React.Component {

  handleChange = (option) => {
    this.props.handleChange(option.value);
  }
  render () {
    const regions = [
      'Gauteng', 'Limpopo', 'Free State', 'Eastern Cape', 'Western Cape',
      'North West', 'Northern Cape', 'KwaZulu-Natal', 'Mpumalanga'
    ];

    const options = [];
    regions.forEach(region => {
      options.push({value: region, label: region});
    });

    return (
      <>
        <Select
          options={options}
          value={options.filter(option => option.value === this.props.selectedValue)}
          onChange={this.handleChange}
        />
      </>
    );
  }
}

export default RegionSelector;