import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAccount, updateAccount } from '../../../store/actions/account';
import { getProfile } from '../../../store/actions/user';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import AccountLogo from '../widgets/AccountLogo';
import Select from 'react-select';
import config from '../../../Config';
import { can } from '../../../helpers/permission';
import { isClient } from '../../../helpers/user';
import { RadioGroup, RadioButton } from 'react-radio-buttons';


class ClientAccountForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      registration_points: '',
      percentage_per_order: '',
      point_in_rands: '',
      balance_message: '',
      loading: false,
      currency: '',
      isClient: false,
      canUpdateLoyalty: false,
      expiration_months: null,
      invoice_period_start_day: 0,
      invoice_period_end_day: 0,
      offers_free_pie: '0',
      free_pie_cost: 0,
      free_pie_spend_amount: 0,
      claim_email: ''
    }
  }

   componentDidMount() {
    this.fetchAccount();
  }

  handleExpirationMonthChange = (input) => {
    this.setState({
      expiration_months: input.value
    });
  }

  fetchAccount = () => {

    this.setState({
      loading: true
    });

    this.props.getAccount()
    .then(() => {
      this.setState({
        name: this.props.account.name ?? '',
        registration_points: this.props.account.registration_points,
        percentage_per_order: this.props.account.percentage_per_order,
        point_in_rands: this.props.account.point_in_rands,
        balance_message: this.props.account.balance_message ?? '',
        currency: this.props.account.currency,
        expiration_months: this.props.account.expiration_months,
        invoice_period_start_day: this.props.account.invoice_period_start_day,
        invoice_period_end_day: this.props.account.invoice_period_end_day,
        offers_free_pie: this.props.account.offers_free_pie.toString(),
        free_pie_cost: this.props.account.free_pie_cost,
        free_pie_spend_amount: this.props.account.free_pie_spend_amount,
        claim_email: this.props.account.claim_email
      });
      this.props.getProfile()
        .then(() => {
          this.setState({
            isClient: isClient(this.props.user.roles) ?? false,
            canUpdateLoyalty: can('update loyalty', this.props.user.permissions) ?? false,
            loading: false
          });
        });
    });

  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  _updateAccount = (e) => {
    e.preventDefault();
    let data = {
      name: this.state.name,
      registration_points: this.state.registration_points,
      percentage_per_order: this.state.percentage_per_order,
      point_in_rands: this.state.point_in_rands,
      balance_message: this.state.balance_message,
      currency: this.state.currency,
      expiration_months: this.state.expiration_months,
      invoice_period_start_day: this.state.invoice_period_start_day,
      invoice_period_end_day: this.state.invoice_period_end_day,
      offers_free_pie: this.state.offers_free_pie.toString(),
      free_pie_cost: this.state.free_pie_cost,
      free_pie_spend_amount: this.state.free_pie_spend_amount,
      claim_email: this.state.claim_email
    };
    this.setState({
      loading: true
    });
    this.props.updateAccount(data, this.props.user.id)
      .then(() => {
        if (this.props.status) {
           this.fetchAccount();
          toast.success(this.props.message);
        } else {
          toast.error(this.props.message);
        }
        this.setState({
          loading: false
        });
      });
  }

  reloadAccount = () => {
    this.fetchAccount();
  }

  handleFieldChange = async(input) => {
    let balance_message = this.state.balance_message;
    balance_message = balance_message + input.value;
    await this.setState({
      balance_message: balance_message
    });
  }

  handleCurrencyChange = (input) => {
    this.setState({
      currency: input.value
    });
  }

  handleShowPieChange = (value) => {
    this.setState({
      offers_free_pie: value
    });
  }

  render () {
    let options = [
      {
        value: '[first_name]',
        label: 'First name'
      },
      {
        value: '[cell_number]',
        label: 'Cell number'
      },
      {
        value: '[crown_balance]',
        label: 'Crown balance'
      },
      {
        value: '[currency_balance]',
        label: 'Currency balance',
      },
      {
        value: '[action_type]',
        label: 'Action (Redeem or Earn)'
      }
    ];
    let currencies = config.currencies;
    let expiry_months = config.expiry_months;
    return (
      <div className="card">
        <div className="card-header">
          <h3>Loyalty account details</h3>
        </div>
        <div className="card-body">
          {this.state.isClient && this.props.account && (
            <AccountLogo
              logo={this.props.account.logo}
              reloadAccount={this.reloadAccount}
            />
          )}
          <br />
          {this.state.isClient && (
            <div className="form-group">
              <label>
                <strong>API token</strong>
              </label>
              <p>{sessionStorage.getItem("access_token")}</p>
            </div>
          )}

          <div className="form-group">
            <label>
              <strong>Loyalty currency</strong>
            </label>
            <Select
              options={currencies}
              onChange={this.handleCurrencyChange}
              value={currencies.filter(
                (option) => option.value === this.state.currency
              )}
            />
          </div>
          {this.state.expiration_months && (
            <div className="form-group">
              <label>
                <strong>Points expires after </strong>
              </label>
              <Select
                options={expiry_months}
                onChange={this.handleExpirationMonthChange}
                value={expiry_months.filter(
                  (option) => option.value === this.state.expiration_months
                )}
              />
            </div>
          )}

          <div className="form-group">
            <label>Loyalty name</label>
            <input
              type="text"
              name="name"
              disabled={this.state.loading}
              onChange={this.handleChange}
              className="form-control"
              value={this.state.name}
            />
            {this.props.errors.name && (
              <span className="text-danger">{this.props.errors.name}</span>
            )}
          </div>
          <div className="form-group">
            <label>Amount of points given on registration</label>
            <input
              type="number"
              step="0.01"
              name="registration_points"
              disabled={this.state.loading}
              onChange={this.handleChange}
              className="form-control"
              value={this.state.registration_points}
            />
            {this.props.errors.registration_points && (
              <span className="text-danger">
                {this.props.errors.registration_points}
              </span>
            )}
          </div>

          <div className="form-group">
            <label>Invoice period start day</label>
            <input
              type="number"
              step="0.01"
              name="invoice_period_start_day"
              disabled={this.state.loading}
              onChange={this.handleChange}
              className="form-control"
              value={this.state.invoice_period_start_day}
            />
            {this.props.errors.invoice_period_start_day && (
              <span className="text-danger">
                {this.props.errors.invoice_period_start_day}
              </span>
            )}
          </div>

          <div className="form-group">
            <label>Invoice period end day</label>
            <input
              type="number"
              step="0.01"
              name="invoice_period_end_day"
              disabled={this.state.loading}
              onChange={this.handleChange}
              className="form-control"
              value={this.state.invoice_period_end_day}
            />
            {this.props.errors.invoice_period_end_day && (
              <span className="text-danger">
                {this.props.errors.invoice_period_end_day}
              </span>
            )}
          </div>

          <p>
            <label>Offers free pie</label>
          </p>
          <div className="row">
            <div className="col-md-4">
              <RadioGroup
                value={this.state.offers_free_pie.toString()}
                onChange={this.handleShowPieChange}
                vertical={"true"}
              >
                <RadioButton value={"1"}>Yes</RadioButton>
                <RadioButton value={"0"}>No</RadioButton>
              </RadioGroup>
            </div>
          </div>
          <p>&nbsp;</p>

          {this.state.offers_free_pie === "1" && (
            <>
              <div className="form-group">
                <label>Free pie cost</label>
                <input
                  type="number"
                  step="0.01"
                  name="free_pie_cost"
                  disabled={this.state.loading}
                  onChange={this.handleChange}
                  className="form-control"
                  value={this.state.free_pie_cost}
                />
                {this.props.errors.free_pie_cost && (
                  <span className="text-danger">
                    {this.props.errors.free_pie_cost}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Free pie spend amount</label>
                <input
                  type="number"
                  step="0.01"
                  name="free_pie_spend_amount"
                  disabled={this.state.loading}
                  onChange={this.handleChange}
                  className="form-control"
                  value={this.state.free_pie_spend_amount}
                />
                {this.props.errors.free_pie_spend_amount && (
                  <span className="text-danger">
                    {this.props.errors.free_pie_spend_amount}
                  </span>
                )}
              </div>
            </>
          )}

          <div className="form-group">
            <label>Order percentage (%)</label>
            <input
              type="number"
              step="0.01"
              name="percentage_per_order"
              disabled={this.state.loading}
              onChange={this.handleChange}
              className="form-control"
              value={this.state.percentage_per_order}
            />
            {this.props.errors.percentage_per_order && (
              <span className="text-danger">
                {this.props.errors.percentage_per_order}
              </span>
            )}
          </div>
          <div className="form-group">
            <label>Point value in RAND (1 Rand = ? points)</label>
            <input
              type="number"
              step="0.01"
              name="point_in_rands"
              disabled={this.state.loading}
              onChange={this.handleChange}
              className="form-control"
              value={this.state.point_in_rands}
            />
            {this.props.errors.point_in_rands && (
              <span className="text-danger">
                {this.props.errors.point_in_rands}
              </span>
            )}
          </div>
          <div className="form-group">
            <label>Select balance message field</label>
            <Select options={options} onChange={this.handleFieldChange} />
          </div>
          <div className="form-group">
            <label>Balance message</label>
            <textarea
              className="form-control"
              name="balance_message"
              value={this.state.balance_message}
              onChange={this.handleChange}
            />
            {this.props.errors.balance_message && (
              <span className="text-danger">
                {this.props.errors.balance_message}
              </span>
            )}
          </div>
          <div className="form-group">
            <label>Claim email</label>
            <input
              type="text"
              name="claim_email"
              className="form-control"
              value={this.state.claim_email}
              onChange={this.handleChange}
            />
            {this.props.errors.claim_email && (
              <span className="text-danger">
                {this.props.errors.claim_email}
              </span>
            )}
          </div>
          {this.state.canUpdateLoyalty && (
            <button
              className="btn btn-primary"
              disabled={this.state.loading}
              onClick={this._updateAccount}
            >
              <i className="fa fa-save"> </i>
              {this.state.loading && <Spinner animation="grow" size="sm" />}
              Update
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.accountReducer.account,
    status: state.accountReducer.status,
    errors: state.accountReducer.errors,
    message: state.accountReducer.message,
    user: state.userReducer.user
  }
}

export default connect (mapStateToProps, { getAccount, updateAccount, getProfile }) (ClientAccountForm);
