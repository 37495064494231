import React, { Component } from "react";
import CounterWigdet from "./CounterWidget";

class ClientCounterWidgets extends Component {
  render() {
    return (
      <>
        {this.props.admin === true && (
          <>
            <div className="row">
              <div className="col-md-4">
                <CounterWigdet
                  icon="store"
                  title="Registered New Stores"
                  color="blue"
                  top_value={this.props.report.registered_stores}
                  bottom_value=""
                  label=" Active stores"
                />
              </div>

              <div className="col-md-4">
                <CounterWigdet
                  icon="store"
                  title="No voucher stores"
                  color="yellow"
                  top_value={this.props.report.stores_no_voucher}
                  bottom_value=""
                  label=" Stores with no vouchers"
                />
              </div>

              <div className="col-md-4">
                <CounterWigdet
                  icon="store"
                  title="No loyalty stores"
                  color="red"
                  top_value={this.props.report.stores_no_loyalty}
                  bottom_value=""
                  label=" Stores with no loyalty"
                />
              </div>
              <div className="col-md-4">
                <CounterWigdet
                  icon="money-bill"
                  title="Redemption amount"
                  color="yellow"
                  top_value={parseFloat(this.props.report.total_redemption_amount).toLocaleString("en-US")
                  .replace(",", " ")}
                  bottom_value={"(ZAR " + parseFloat(this.props.report.total_redemption_amount/10).toLocaleString("en-US")
                  .replace(",", " ") +")"}
                  label=" Redemption amount"
                />
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-md-4">
            <CounterWigdet
              icon="store"
              title="Active stores"
              color="green"
              top_value={this.props.report.active_stores}
              bottom_value=""
              label=" Stores with transaction based on selected date range"
            />
          </div>
          <div className="col-md-4">
            <CounterWigdet
              icon="users"
              title="Members"
              color="green"
              top_value={this.props.report.total_members}
              bottom_value=""
              label=" Total registered members"
            />
          </div>
          <div className="col-md-4">
            <CounterWigdet
              icon="users"
              title="Blocked members"
              color="red"
              top_value={this.props.report.blocked_members}
              bottom_value=""
              label=" Total blocked members"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <CounterWigdet
              icon="shopping-cart"
              title="Total orders"
              color="blue"
              top_value={this.props.report.total_orders}
              bottom_value=""
              label=" Total orders"
            />
          </div>
          <div className="col-md-4">
            <CounterWigdet
              icon="shopping-cart"
              title="Total order value"
              color="yellow"
              top_value={
                "ZAR " +
                parseFloat(this.props.report.total_order_value)
                  .toLocaleString("en-US")
                  .replace(",", " ")
              }
              bottom_value=""
              label=" Total order value"
            />
          </div>
          <div className="col-md-4">
            <CounterWigdet
              icon="shopping-cart"
              title="Average order value"
              color="red"
              top_value={
                "ZAR " +
                parseFloat(this.props.report.avg_order_value).toFixed(2)
              }
              bottom_value=""
              label=" Average order value"
            />
          </div>
          <div className="col-md-4">
            <CounterWigdet
              icon="money-bill"
              title="Redemption amount"
              color="yellow"
              top_value={parseFloat(this.props.report.total_redemption_amount).toLocaleString("en-US")
              .replace(",", " ")}
              bottom_value={"(ZAR " + parseFloat(this.props.report.total_redemption_amount/10).toLocaleString("en-US")
              .replace(",", " ") +")"}
              label=" Redemption amount"
            />
          </div>
        </div>
      </>
    );
  }
}

export default ClientCounterWidgets;
