import React from "react";
import SideBar from "../../partials/SideBar";
import HeaderBar from "../../partials/Header/HeaderBar";
import {
  getStats,
  getGraphs,
  getStoreStats,
} from "../../../store/actions/stores";
import { connect } from "react-redux";
import { formatDate, fetchMonths } from "../../../helpers/date";
import DateRangePickerWidget from "../widgets/DateRangePicker";
import { Line } from "react-chartjs-2";
import Select from "react-select";
import CounterWigdet from "../dashboard/widgets/CounterWidget";

import { downLoadExcel } from "../../../helpers/export";
import BackButton from "../../utils/BackButton";

class ShowStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: null,
      graphs: null,
      roles: "",
      store_id: "",
      store_report: [],
      loading: false,
      start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      end_date: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    };
  }

  componentWillMount() {
    this.setState({
      loading: true,
    });
    const {
      match: { params },
    } = this.props;
    this.setState({
      store_id: params.uid,
    });

    // get logged in user roles
    let roles = sessionStorage.getItem("roles");
    this.setState({
      roles: roles,
    });

    this.props
      .getStats(
        params.uid,
        [formatDate(this.state.start_date) + " 00:00:00", formatDate(this.state.end_date) + " 23:59:59"],
        true
      )
      .then(() => {
        this.setState({
          stats: this.props.stats,
        });
        this.generateReport();

        this.setState({
          graphs:
            this.state.stats?.store.code == "Mobi" ||
            this.state.stats?.store.code == "USSD"
              ? {
                  labels: this.props.graphs.labels,
                  datasets: [this.props.graphs.datasets.pop()],
                }
              : this.props.graphs,
          loading: false,
        });
      });
  }

  handleSelect = (ranges) => {
    this.setState({
      start_date: ranges.start_date,
      end_date: ranges.end_date,
    });
    this.props
      .getStats(this.state.store_id, [
        formatDate(ranges.start_date) + " 00:00:00",
        formatDate(ranges.end_date) + " 23:59:59",
      ])
      .then(() => {
        this.setState({
          stats: this.props.stats,
        });
      });
  };

  setMonth = (option) => {
    this.setState({
      month: option.value,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  loadGraph = () => {
    this.props
      .getGraphs(this.state.store_id, this.state.month, this.state.year)
      .then(() => {
        this.setState({
          graphs:
            this.state.stats?.store.code == "Mobi" ||
            this.state.stats?.store.code == "USSD"
              ? {
                  labels: this.props.graphs.labels,
                  datasets: [this.props.graphs.datasets.pop()],
                }
              : this.props.graphs,
        });
      });
  };

  generateReport = () => {
    this.setState({
      loading: true,
    });
    getStoreStats(this.state.store_id)
      .then((res) => {
        this.setState({
          store_report: res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const months = fetchMonths();

    const redirect = (status) => {
      let link =
        this.state.roles.includes("Admin") ||
        this.state.roles?.includes("Franchise Owner")
          ? `/stores/storemembers/${status}/${this.state.store_id}`
          : "";
      return link;
    };

    const memberStats =
      this.state.store_report &&
      this.state.store_report?.map((item) => {
        return {
          "Date registered": item.created_at,
          name: item.name,
          "cell number": item?.cell_number,
          status: item?.is_active === 1 ? "active" : "block",
          vip: item?.is_vip === 1 ? "VIP" : "Non Vip",
        };
      });

    const csvData = [
      {
        "Registered members": this.state.stats ? this.state.stats.members : "",
        "Blocked members": this.state.stats
          ? this.state.stats.blocked_members
          : "",
        "VIP Members": this.state.stats ? this.state.stats.vip_members : "",
        "Total orders": this.state.stats ? this.state.stats.orders : "",
        "Order value": `R ${
          this.state.stats ? this.state.stats.order_value : ""
        }`,
        "Average order value": `R ${
          this.state.stats ? this.state.stats.avg_order_value : ""
        }`,
        "Total Crowns Balance": this.state.stats
          ? this.state.stats.total_point_balance
          : "",
        "Total Crowns earned": this.state.stats
          ? this.state.stats.total_point_earned
          : "",
        "Total Crowns redeemed": this.state.stats
          ? this.state.stats.total_point_redeemed
          : "",
        "Start date": formatDate(this.state.start_date),
        "End date": formatDate(this.state.end_date),
      },
    ];

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <BackButton />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  {this.state.loading ? (
                    <div className="p-3">Please wait...</div>
                  ) : (
                    <>
                      <div className="card-header justify-content-between">
                        <div className="align-items-center">
                          <h3>
                            {this.state.stats
                              ? this.state.stats.store.name
                              : ""}
                            's Report
                          </h3>
                        </div>

                        <div class="align-items-center d-flex">
                          <button
                            class="btn btn-primary date-range-picker-button"
                            onClick={() =>
                              downLoadExcel(
                                csvData,
                                this.state.stats?.store.name
                              )
                            }
                          >
                            <i className="fa fa-download"></i>Download store
                            stats
                          </button>

                          {memberStats ? (
                            <button
                              class="btn btn-primary ml-10 date-range-picker-button"
                              onClick={() =>
                                downLoadExcel(
                                  memberStats,
                                  `${this.state.stats?.store.name} members`
                                )
                              }
                            >
                              <i className="fa fa-download"></i>Download store
                              member stats
                            </button>
                          ) : (
                            <> </>
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mb-4">
                          <h1 className="pt-10 pb-10 text-center">
                            {this.state.stats
                              ? this.state.stats.store.name
                              : ""}{" "}
                            Members stats
                          </h1>
                          <p>{`# ${
                            this.state.stats ? this.state.stats.store.code : ""
                          }`}</p>

                          <h6>
                            {this.state.stats
                              ? this.state.stats.store.address
                              : ""}
                          </h6>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <CounterWigdet
                              icon="users"
                              title="Registered Members"
                              color="green"
                              top_value={
                                this.state.stats ? this.state.stats.members : ""
                              }
                              bottom_value=""
                            />
                          </div>

                          {/* {this.state?.stats?.active_members ? ( */}
                          <div className="col-md-4">
                            <CounterWigdet
                              icon="users"
                              title="Not blocked members"
                              color="green"
                              link={redirect("active")}
                              top_value={
                                this.state.stats
                                  ? this.state.stats?.active_members
                                  : 0
                              }
                              bottom_value=""
                            />
                          </div>

                          <div className="col-md-4">
                            <CounterWigdet
                              icon="users"
                              title="VIP Members"
                              color="yellow"
                              link={redirect("vip")}
                              top_value={
                                this.state.stats
                                  ? this.state?.stats?.vip_members
                                  : ""
                              }
                              bottom_value=""
                            />
                          </div>
                          <div className="col-md-4">
                            <CounterWigdet
                              icon="users"
                              title="Blocked Members"
                              link={redirect("blocked")}
                              color="red"
                              top_value={
                                this.state.stats
                                  ? this.state.stats.blocked_members
                                  : ""
                              }
                              bottom_value=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 text-center">
                        <DateRangePickerWidget
                          start_date={this.state.start_date}
                          end_date={this.state.end_date}
                          handleUpdateDates={this.handleSelect}
                        />
                      </div>
                      <div className="card-body">
                        <div className="mb-4">
                          <h1 className="pt-10 pb-10 text-center">
                            {this.state.stats
                              ? this.state.stats.store.name
                              : ""}{" "}
                            Transactions stats
                          </h1>
                        </div>

                        <div className="row">
                          {this.state.stats?.store.code == "Mobi" ||
                          this.state.stats?.store.code == "USSD" ? (
                            <></>
                          ) : (
                            <>
                              <div className="col-md-4">
                                <CounterWigdet
                                  icon="shopping-cart"
                                  title="Total Orders"
                                  color="blue"
                                  top_value={
                                    this.state.stats
                                      ? this.state.stats.orders
                                      : ""
                                  }
                                  bottom_value=""
                                />
                              </div>
                              <div className="col-md-4">
                                <CounterWigdet
                                  icon="shopping-cart"
                                  title="Order value"
                                  color="yellow"
                                  top_value={`R ${
                                    this.state.stats
                                      ? this.state.stats.order_value
                                      : ""
                                  }`}
                                  bottom_value=""
                                />
                              </div>
                              <div className="col-md-4">
                                <CounterWigdet
                                  icon="shopping-cart"
                                  title="Average order value"
                                  color="green"
                                  top_value={`R ${
                                    this.state.stats
                                      ? this.state.stats.avg_order_value
                                      : ""
                                  }`}
                                  bottom_value=""
                                />
                              </div>
                              <div className="col-md-4">
                                <CounterWigdet
                                  icon="crown"
                                  title="Total Point Balance"
                                  color="red"
                                  top_value={`${
                                    this.state.stats
                                      ? this.state.stats.total_point_balance
                                      : ""
                                  } Crowns`}
                                  bottom_value=""
                                />
                              </div>
                              <div className="col-md-4">
                                <CounterWigdet
                                  icon="crown"
                                  title="Total point earned"
                                  color="yellow"
                                  top_value={`${
                                    this.state.stats
                                      ? this.state.stats.total_point_earned
                                      : ""
                                  } Crowns`}
                                  bottom_value=""
                                />
                              </div>
                              <div className="col-md-4">
                                <CounterWigdet
                                  icon="crown"
                                  title="Total point redeemed"
                                  color="green"
                                  top_value={`${
                                    this.state.stats
                                      ? this.state.stats.total_point_redeemed
                                      : ""
                                  } Crowns`}
                                  bottom_value=""
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row invoice-head">
                          <div class="form-group col-md-4">
                            <label>Select month</label>
                            <Select onChange={this.setMonth} options={months} />
                          </div>
                          <div className="form-group col-md-4">
                            <label>Select year</label>
                            <input
                              name="year"
                              type="number"
                              className="form-control"
                              placeholder="Eg: 2020"
                              value={this.state.year}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <div className="form-group col-md-2">
                            <p>&nbsp;</p>
                            <button
                              className="btn btn-primary"
                              onClick={this.loadGraph}
                            >
                              Load
                            </button>
                          </div>
                        </div>
                        {this.state.graphs !== null && (
                          <Line data={this.state.graphs} />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stats: state.storesReducer.stats,
    graphs: state.storesReducer.graphs,
  };
};

export default connect(mapStateToProps, { getStats, getGraphs })(ShowStore);
