import React from 'react'

import PageLayout from '../../../../../layouts/PageLayout';

export default function WinnerDraws() {

  const drawCards = [
    {
      title: "Daily Draws",
      description: "Draw winners daily",
      link: "/admin/draws/daily",
      image: "/assets/img/rewards-logo.webp"

    },
    // {
    //   title: "3 Weeks Draws",
    //   description: "Draw 1 winner every 3 Weeks",
    //   link: "/admin/draws/three-weeks",
    //   image: "/assets/img/rewards-logo.webp"

    // },

    // {
    //   title: "Grand Prize Draws",
    //   description: "Draw the grand prize winner",
    //   link: "/admin/draws/grand-prize",
    //   image: "/assets/img/rewards-logo.webp"



    // }
  ]
  return (
    <>
      <PageLayout>
        <section className="pt-40">
          <div className="container">
            <div className="row">
              {drawCards?.map(({ title, description, link, image }) => (  <div className="col-sm">
                <div key = {title} className="card" style={{ width: "18rem" }}>
                  <img
                    className="card-img-top"
                    src={image}
                    alt={title}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{description}</p>
                 

                    <a href={link} className="btn btn-primary">
                    {title}
                    </a>
                  </div>
                </div>
              </div>
              
              ))}

           
            </div>
          </div>
        </section>
      </PageLayout>
    </>
  );
}
