import http  from '../../instances/http';

export const getTransactions = (client_id = null, limit = null, date_range = []) => {
  return dispatch => {
    let token = sessionStorage.getItem('access_token');
    let url = 'loyalty/transaction/all?api_token=' + token;
    if (client_id) {
      url = 'loyalty/transaction/all/' + client_id + '?api_token=' + token;
    }
    if (limit !== null) {
      url = url + '&limit=' + limit;
    }
    return http.post(url, {date_range: date_range})
      .then(response => {
        dispatch({
          type: "SET_TRANSACTIONS",
          payload: response.data
        });
      })
      .catch(error => {
     
        dispatch({
          type: "SET_TRANSACTIONS",
          payload: error?.data
        });
      });
  }
};

export const getMemberTransactions = (cell_number, client_id = null) => {
  return dispatch => {
    let url = 'loyalty/transaction/member/' + cell_number + '?api_token=' + sessionStorage.getItem('access_token');
    if (client_id) {
      url = url + '&account_id=' + client_id;
    }
    return http.get(url)
    .then(response => {
      dispatch({
        type: "SET_TRANSACTIONS",
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: "SET_TRANSACTIONS",
        payload: error.data
      });
    });
  };
} ;

export const getTransaction = (id) => {
  return dispatch => {
    return http.get('loyalty/transaction/get/' + id + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_TRANSACTION",
          payload: response.data.data
        });
      })
        .catch(error => {
          dispatch({
            type: "SET_TRANSACTION",
            payload: error.data
          });
          
        })
  }
}
