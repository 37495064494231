const initialState = {
  promotions: [],
  status: false,
  errors: null,
  promotion: null,
  survey_data: null,
  transactions: [],
  vouchers: []
}

export default function promotion (state = initialState, action) {
  switch (action.type) {
    case "SET_PROMOTIONS":
      return {
        ...state,
        promotions: action.payload
      };
    
    case "SET_PROMOTION_VOUCHERS":
      return {
        ...state,
        vouchers: action.payload
      };

    case "SET_PROMOTION":
      return {
        ...state,
        promotion: action.payload
      };
    case "SAVE_PROMOTION":
      return {
        ...state,
        status: action.payload.success ?? false,
        errors: action.payload.errors
      };
    case "SET_SURVEYS":
      return {
        ...state,
        survey_data: action.payload
      };
    case "SET_TRANSACTIONS":
        return {
          ...state,
          transactions: action.payload
        };
    default:
      return state;
  }
}
