import React, { useState } from "react";
import PageLayout from "../../../../../layouts/PageLayout";
import PrizeWinnerPicker from "../../../../partials/Header/Widgets/PrizeWinnerPicker";
import DataTable from "react-data-table-component";
import {
  useQuery,

  useQueryClient,
  QueryClient,
  QueryClientProvider,
  useMutation,
} from "react-query";
import { getPromotionEntries, getWinners } from "../../../../../store/actions/promotion";
import { BeatLoader } from "react-spinners";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment/moment";
import BackupWinnnersModal from "../../claims/partials/BackupWinnnersModal";

const queryClient = new QueryClient();

export default function DailyDraws() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <DailyDrawsPage />
      </QueryClientProvider>
    </>
  );
}

function DailyDrawsPage() {
  const [search, setSearch] = useState("");
  const [winnerSearch, setWinnerSearch] = useState("");

  // const queryClient = useQueryClient();

  const query = useQuery("entries", () => getPromotionEntries());
  const winner_query = useQuery("winners", () => getWinners());
  // Mutations
  // const mutation = useMutation( () => getWinners(), {
  //   onSuccess: () => {
  //     // Invalidate and refetch
  //     queryClient.invalidateQueries("winners");
  //   },
  // });

  // check if data is loading

  const entries = query?.data?.map((entry) => {
    const {
      id,
      item_code,
      last_name,
      first_name,
      order_date,
      order_reference,
      // gender,
      // dob,
      cell_number,
      store_id,
    } = entry;

    return {
      id,
      meal_item_code: item_code,
      name: `${first_name} ${last_name}`, // `${first_name} ${last_name}
      surname: last_name,
      first_name,
      order_date: moment(order_date).format("LL"),
      order_reference,
      store_id,
      cell_number,
    };
  });

  const winners = winner_query?.data
    ?.filter((item) => item.prize_type === "daily")?.sort((a, b) => b.created_at - a.created_at)
   
    ?.map((winner) => {
      const {
        id,
        cell_number,
        last_name,
        first_name,
        prize_type,
        created_at,
        backup_winners,
        updated_at,
      } = winner;

      return {
        id,
        name: `${first_name} ${last_name}`,
        surname: last_name,
        prize_type,
        created_at: moment(created_at).format("LL"),
        backup_winners,
        updated_at: moment(updated_at).format("LL"),
        cell_number,
      };
    });

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },

    {
      name: "Cell Number",
      selector: "cell_number",
      sortable: true,
    },
    {
      name: "Order Reference",
      selector: "order_reference",
      sortable: true,
    },
    {
      name: "Order Date",
      selector: "order_date",
      sortable: true,
      width: "350px",
      align: "center",
    },
    {
      name: "Meal Item Code",
      selector: "meal_item_code",
      sortable: true,
      width: "200px",
      align: "center",
    },
    {
      name: "Store ID",
      selector: "store_id",
      sortable: true,
    },
    //   {
    //     name: "Entries",
    //     selector: "entries",
    //     sortable: true,
    //   },
  ];

  const winner_columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Prize Type",
      selector: "prize_type",
      sortable: true,
    },
    {
      name: "Cell Number",
      selector: "cell_number",
      sortable: true,
    },
    {
      name: "Created At",
      selector: "created_at",
      sortable: true,
      width: "300px",
      align: "center",
    },

    {
      name: "Updated At",
      selector: "updated_at",
      sortable: true,
    },
    {
      name: "Backup Winners",
      cell: (row) => <BackupWinnnersModal data = {row.backup_winners} date = {row?.created_at} />,
      sortable: true,

      align: "center",
    },
  ];

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleWinnerSearch = (e) => {
    setWinnerSearch(e.target.value);
  };

  return (
    <PageLayout>
      <section className="w-100 ">
        <Tabs
          defaultActiveKey="home"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          {/* <Tab eventKey="home" title="Entries">
            <div className="py-5">
              <h1>Entries</h1>
              <div class="align-items-center mb-20 ">
                <input
                  type="text"
                  name="search"
                  placeholder="Search entries"
                  className="p-2 form-control w-50"
                  onChange={handleSearch}
                />
              </div>
              <DataTable
                progressPending={query.isLoading}
                progressComponent={<BeatLoader color="#30A2FF" />}
                columns={columns}
                title="Daily Draw Entries"
                data={entries?.filter((entry) => {
                  if (search === "") {
                    return entry;
                  } else {
                    return Object.values(entry)
                      .join("")
                      .toLowerCase()
                      .includes(search.toLowerCase());
                  }
                })}
                pagination
                highlightOnHover
                responsive
              />
            </div>
          </Tab> */}
          <Tab eventKey="winners" title="Winners">
            <div className="py-5">
              <h1>Winners</h1>
              <div className="align-items-center mb-20 ">
                <input
                  type="text"
                  name="search"
                  placeholder="Search winners"
                  className="p-2 form-control w-50"
                  onChange={handleWinnerSearch}
                />
              </div>
              <DataTable
                progressPending={query.isLoading}
                progressComponent={<BeatLoader color="#30A2FF" />}
                columns={winner_columns}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30 ,40 , 45]}

                title="Daily Draw Winners"
                data={winners?.filter((entry) => {
                  if (winnerSearch === "") {
                    return entry;
                  } else {
                    return Object.values(entry)
                      .join("")
                      .toLowerCase()
                      .includes(winnerSearch.toLowerCase());
                  }
                })}
                pagination
                highlightOnHover
                responsive
              />
            </div>
          </Tab>
          <Tab eventKey="pick-winner" title="Pick a Winner">
            <h1>Winner Picker</h1>
            {/* <WinnerPicker participants={entries} /> */}

            <div>
              {entries?.length > 0 && (
                <PrizeWinnerPicker
                  winners={winners}
                  prize_type="daily"
                  participants={entries ?? []}
                />
              )}
            </div>
          </Tab>
        </Tabs>
      </section>
    </PageLayout>
  );
}
