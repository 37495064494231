import React, { Component } from 'react';
import Select from 'react-select';

class MultiStoreSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      stores: [],
      selectedStores: [],
      selectedFullStores: []
    };
  }

  componentDidMount() {
    this.setState({
      stores: this.props.stores,
      selectedStores: this.props.selectedStores ?? [],
      selectedFullStores: this.props.selectedStores ? 
      this.handleSelectedFullStores(this.props.selectedStores) : []
    });
  }

  handleShow = () => {
    this.setState({
      show: true
    });
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  handleOnSelect = (options) => {
    let selectedStores = this.state.selectedStores;
    if (options !== null) {
      if (options.length < selectedStores.length) {
        selectedStores = [];
      }
      options.forEach(option => {
        if (!selectedStores.includes(option.value)) {
          selectedStores.push(option.value);
        }
      });
    } else {
      selectedStores = [];
    }
    this.setState({
      selectedStores: selectedStores,
      selectedFullStores: options
    });
    this.props.handleSetSelectedStores(selectedStores);
  }

  handleSelectedFullStores = (selectedIds = []) => {
    let selectedFullStores = [];
    if (selectedIds.length > 0) {
      this.props.stores.forEach(store => {
        if (selectedIds.includes(store.value)) {
          selectedFullStores.push(store);
        }
      });
    }

    return selectedFullStores;
  }

  render () {

    return (
      <>
        <div className='form-group'>
          <label>{this.state.selectedStores.length} Stores selected</label>
          {
              this.state.stores.length > 0 && (
                <Select 
                  options={this.state.stores}
                  isMulti={true}
                  onChange={this.handleOnSelect}
                  value={this.state.selectedFullStores}
                />
              )
            }
        </div>
      </>
    )
  }
}

export default MultiStoreSelector;