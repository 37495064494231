export const isAdmin = (roles = []) => {
  return roles.includes('Admin');
};

export const isClient = (roles = []) => {
  return roles.includes('Client');
};

export const isManager = (roles = []) => {
  return roles.includes('Manager');
};

export const isFranchiseOwner = (roles = []) => {
  return roles.includes('Franchise Owner');
};

export const isFranchiseManager = (roles = []) => {
  return roles.includes('Franchise Manager');
};

export const isHeadOffice = (roles = []) => {
  return roles.includes('Head Office');
}

export const formatUsersForSelect = (users = []) => {
  let formatted_users = [];
  if (users.length > 0) {
    for (let i = 0; i < users.length; i++) {
      formatted_users.push({value: users[i]._uid, label: users[i].name + ' - ' + users[i].email, user: users[i]});
    }
  }
  return formatted_users;
};
