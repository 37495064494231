import http from "../../instances/http";

export const login = (data) => {
  return dispatch => {
    return http.post('auth/login', data, {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    })
      .then(response => {
        dispatch({
          type: "SET_LOGGED_IN_USER",
          payload: response.data
        });
        if(response.data.success === true) {
          sessionStorage.setItem('access_token', response.data.data.token);
          sessionStorage.setItem('roles', JSON.stringify(response.data.data.roles));
        }
      })
      .catch(error => {
        console.log(error.data);
        dispatch({
          type: "SET_LOGGED_IN_USER",
          payload: error.data
        });
      });
  };
};

export const otpLogin = (data) => {
  
  try {
    let url = `auth/login`

     return http
       .post(url, data)
       .then((response) => {
         return response?.data;
       })
       .catch((error) => {
         console.log(error);
       });
    
  }
  catch (err) {
    console.error(err)
  }


}

export const resetPassword = (email) => {
  return dispatch => {
    return http.post('auth/password/reset', { email: email })
      .then(response => {
        dispatch({
          type: "RESET_PASSWORD",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "RESET_PASSWORD",
          payload: error.data
        });
      });
  };
};