import http from '../../instances/http';
import { toast } from 'react-toastify';

export const getRewards = (promotion_id) => {
  return dispatch => {
    return http.get('reward/' + promotion_id + '/all?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_REWARDS",
          payload: response.data.data
        });
      })
      .catch(error => {
        console.error(error.data);
        toast.error('Could not fetch rewards.');
      });
  }
}

export const storeReward = (data) => {
  return dispatch => {
    return http.post('reward?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "SAVE_REWARD",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "SET_REWARD_ERRORS",
          payload: error.data
        });
        toast.error('Could not save reward');
      });
  }
}

export const updateReward = (id, data) => {
  return dispatch => {
    return http.put('reward/' + id + '?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "SAVE_REWARD",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "SET_REWARD_ERRORS",
          payload: error.data
        });
      })
  }
}

export const deleteReward = (id) => {
  return dispatch => {
    return http.delete('reward/' + id + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SAVE_REWARD",
          payload: response.data
        });
      })
      .catch (error => {
        toast.error('Could not delete the reward');
        dispatch({
          type: "SET_REWARD_ERRORS",
          payload: error.data
        });
      });
  }
}
