import http from "../../instances/http";
const {log} = console;


export const getReports = (body) => {

       try {
         let url = `loyalty/get/consolidated/reports?api_token=${sessionStorage.getItem(
           "access_token"
         )}`;

         return http
           .post(url, body)
           .then((response) => {
             return response?.data;
           })
           .catch((error) => {
             log(error);
           });
       } catch (error) {
         log(error);
       }

       
            
}