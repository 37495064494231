import http from "../../instances/http";

export const getMemberStats = (member_id, date_range) => {
  return (dispatch) => {
    return http
      .post(
        "loyalty/member/stats/all?api_token=" +
          sessionStorage.getItem("access_token"),
        { user_id: member_id, date_range: date_range }
      )
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: "SET_MEMBER",
            payload: response?.data?.data ? response?.data?.data : [],
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERROR",
          payload: error?.data,
        });
      });
  };
};
export const getMemberCardStats = (member_id, date_range) => {
  return (dispatch) => {
    return http
      .post(
        "loyalty/member/stats/all?api_token=" +
          sessionStorage.getItem("access_token"),
        { user_id: member_id, date_range: date_range }
      )
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: "SET_MEMBER",
            payload: response?.data?.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "SET_ERROR",
          payload: error?.data,
        });
      });
  };
};

export const getMembersReporter = (data) => {
  const url = `loyalty/member/getExcelReport?api_token=${sessionStorage.getItem(
    "access_token"
  )}`;

  try {
    return http
      .post(url, data)
      .then((res) => {
        return res.data.data.filter((item) => item !== null);
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getBlockedMembers = () => {
  const url = `loyalty/member/blockedMembers?api_token=${sessionStorage.getItem(
    "access_token"
  )}`;

  try {
    return http
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
};
