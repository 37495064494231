/**
 * PrizeWinnerPicker component that allows picking a winner from a list of participants and saving the winner and backup winners to the store.
 * @param {Object} props - The component props.
 * @param {Array} props.participants - The list of participants to pick a winner from.
 * @param {string} props.prize_type - The type of prize to be won.
 * @param {Array} props.winners - The list of previous winners.
 * @returns {JSX.Element} - The PrizeWinnerPicker component.
 */

import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  saveBackUpWinners,
  saveWinners,
} from "../../../../store/actions/promotion";
import { toast } from "react-toastify";

const INTERVAL = 50;

const NamePicker = ({ names, interval, isPicking, onStopPicking, winner }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    if (isPicking) {
      const i = setInterval(() => {
        setName(names[Math.floor(Math.random() * names.length)]);
      }, interval);
      return () => clearInterval(i);
    } else {
      onStopPicking(name); // Call the onStopPicking function with the current name

      //     backup winners
    }
  }, [interval, isPicking, names, onStopPicking, name]);

  return (
    <div>
      <h1 className="names">
        {isPicking ? name : winner !== "" ? `${winner?.name} ` : "?"}
      </h1>
    </div>
  );
};

export default function PrizeWinnerPicker({
  participants,
  prize_type,
  winners,
}) {
  const [isPicking, setIsPicking] = useState(false);
  const [winner, setWinner] = useState("");
  const [backupWinners, setBackupWinners] = useState([]);
  const [drawDate, setDrawDate] = useState("");
  const [loading, setLoading] = useState(false);

  const last_entry_date = moment(
    participants[participants.length - 1].order_date
  ).format("YYYY-MM-DD");

  const names = participants?.map((participant) => `${participant?.name}`);

  const handleStopPicking = (currentName) => {
    setIsPicking(false);

    // get the current participant
    const currentParticipant = participants?.find(
      (participant) => `${participant?.name}` === currentName
    );

    if (currentParticipant) {
      // set the winner
      setWinner(currentParticipant);
      setDrawDate(new Date().toISOString());
    }
  };

  // if there are no winners, set the draw date to today

  const lastWinner = winners?.length
    ? winners[winners?.length - 1].created_at
    : last_entry_date;

  const today = moment(new Date()).format("YYYY-MM-DD");

  const lastWinnerDate = moment(lastWinner).format("YYYY-MM-DD");

  console.log(lastWinnerDate);

  const nextDrawDate = moment(lastWinnerDate)
    .add(1, "days")
    .format("YYYY-MM-DD");

  const three_weeks_data = moment(lastWinnerDate)
    .add(3, "weeks")
    .format("YYYY-MM-DD");

  const isToday = today === lastWinnerDate;

  const isThreeWeeks = today === three_weeks_data;

  // set the grand prize date to any date after 2023-12-10

  // get any date after 2023-12-10
  const grand_prize_date = moment("2023-12-09")
    .add(1, "days")
    .format("YYYY-MM-DD");

  const isGrandPrize = today === grand_prize_date;

  // generate backup winners
  const generateBackupWinners = () => {
    const backupWinners = [];
    for (let i = 0; i < 5; i++) {
      const randomWinner =
        participants[Math.floor(Math.random() * participants.length)];
      backupWinners.push(randomWinner);

      // remove the winner id from the participants array
      participants.splice(
        participants.findIndex(
          (participant) => participant.id === randomWinner.id
        ),
        1
      );
    }

    setBackupWinners(backupWinners);
  };

  const saveDrawWinners = async () => {
    setLoading(true);
    const winner_data = {
      cell_number: winner?.cell_number,
      first_name: winner?.first_name,
      last_name: winner?.surname ?? "",
      prize_type: prize_type,
    };

    const backup_winners_data = backupWinners?.map((winner) => {
      return {
        cell_number: winner?.cell_number,
        first_name: winner?.first_name,
        last_name: winner?.surname,
        prize_type: prize_type,
      };
    });

    await saveWinners(winner_data)
      .then((res) => {
        if (res?.data) {
          toast.success("Winner saved successfully");
          toast.warning("Saving backup winners");
          setLoading(false);

          saveBackUpWinners({
            backup_winners: backup_winners_data,
          })
            .then((res) => {
              if (res?.data) {
                toast.success("Backup winners saved successfully");
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error saving winner , try again later");
      });
  };

  return (
    <div
      className="d-flex flex-column  justify-content-evenly 
     p-4 align-items-center w-100 h-100"
    >
      <div className="">
        {/* <p> p :{moment(lastWinnerDate).format("LL")}</p> */}
        <NamePicker
          names={names}
          interval={INTERVAL}
          isPicking={isPicking}
          onStopPicking={handleStopPicking}
          winner={winner}
        />

        {/* {prize_type === "daily" && (
          <>
            {isToday && (
              <>
                <p className="mt-10">
                  Last draw date: {moment(lastWinnerDate).format("LL")}
                </p>
                <p className="mt-10">
                  Next draw date: {moment(nextDrawDate).format("LL")}
                </p>
              </>
            )}
          </>
        )} */}
        {prize_type === "weekly" && (
          <>
            {!isThreeWeeks && (
              <>
                {winners?.length ? (
                  <p className="mt-10">
                    Last draw date: {moment(lastWinnerDate).format("LL")}
                  </p>
                ) : null}

                <p className="mt-10">
                  Next draw date: {moment(three_weeks_data).format("LL")}
                </p>
              </>
            )}
          </>
        )}
        {prize_type === "grand-prize" && (
          <>
            <p className="mt-10">
              Next draw date: {moment(grand_prize_date).format("LL")}
            </p>
            {/* {!isThreeWeeks && (
              <>
                {winners?.length ? (
                  <p className="mt-10">
                    Last draw date: {moment(lastWinnerDate).format("LL")}
                  </p>
                ) : null}

                <p className="mt-10">
                  Next draw date: {moment(three_weeks_data).format("LL")}
                </p>
              </>
            )} */}
          </>
        )}

        <>
          {prize_type === "daily" && (
            <button
              disabled={winner }
              className={`btn  w-100 ${
                winner ? "btn-secondary" : "btn-primary"
              }`}
              onClick={() => setIsPicking(!isPicking)}
            >
              {isPicking ? "Stop " : "Draw winner"}
            </button>
          )}
          {prize_type === "weekly" && (
            <button
              disabled={winner}
              className={`btn  w-100 ${
                winner ? "btn-secondary" : "btn-primary"
              }`}
              onClick={() => setIsPicking(!isPicking)}
            >
              {isPicking ? "Stop " : "Draw winner"}
            </button>
          )}

          {prize_type === "grand-prize" && (
            <button
              disabled={winner || !isGrandPrize ? true : false}
              className={`btn  w-100 ${
                winner || !isGrandPrize ? "btn-secondary" : "btn-primary"
              }`}
              onClick={() => setIsPicking(!isPicking)}
            >
              {isPicking ? "Stop " : "Draw winner"}
            </button>
          )}

          {winner && (
            <button
              // disabled={backupWinners ? true : false}
              className={`btn mt-10  w-100 ${
                backupWinners ? "btn-secondary" : "btn-primary"
              }`}
              onClick={generateBackupWinners}
            >
              Generate backup winners
            </button>
          )}
        </>
      </div>
      {winner && (
        <div>
          <h1 className="">Draw information</h1>
          <p className="">
            Draw date:{" "}
            <span className="fw-bold"> {moment(drawDate).format("LL")}</span>
          </p>
          <p className="">
            Winner: <span className="fw-bold"> {winner?.name}</span>
          </p>
          <p className="">
            Backup winners:{" "}
            <span className="fw-bold">
              {backupWinners.map((winner) => (
                <p>{winner?.name}</p>
              ))}
            </span>
          </p>

          {backupWinners.length > 0 ? (
            <>
              <button
                onClick={saveDrawWinners}
                className="btn btn-primary w-100"
              >
                {loading ? "Saving..." : "Save "}
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}
