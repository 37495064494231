export const promotion_types = [
  {
    label: "Order",
    value: 'order'
  },
  {
    label: "Double point",
    value: 'double'
  },
  {
    label: "Triple point",
    value: 'triple'
  }
];

export const reward_types = [
  {
    label: "Crown",
    value: "crown"
  },
  {
    label: "Voucher",
    value: "voucher"
  }
];

export const expires_after = [
  {
    label: '3 Months',
    value: '3 Months',
  },
  {
    label: '6 Months',
    value: '6 Months',
  },
  {
    label: '12 Months',
    value: '12 Months',
  }
];
