import http from '../../instances/http';

export const fetchProfiles = () => {
  return dispatch => {
    return http.get('invoice-profile?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_PROFILES",
          payload: response.data
        });
      })
      .catch(error => {
        console.log(error.data);
        dispatch({
          type: "SET_PROFILES",
          payload: error.data
        });
      });
  }
}

export const fetchProfile = (id) => {
  return dispatch => {
    return http.get('invoice-profile/' + id + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_PROFILE",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "SET_PROFILE",
          payload: error.data
        });
      });
  }
}

export const storeProfile = (data) => {
  return dispatch => {
    return http.post('invoice-profile?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "STORE_PROFILE",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "STORE_PROFILE",
          payload: error.data
        });
      });
  }
}

export const updateProfile = (id, data) => {
  return dispatch => {
    return http.put('invoice-profile/' + id + '?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "STORE_PROFILE",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "STORE_PROFILE",
          payload: error.data
        });
      });
  }
}

export const deleteProfile = (id) => {
  return dispatch => {
    return http.delete('invoice-profile/' + id + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "STORE_PROFILE",
          payload: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: "STORE_PROFILE",
          payload: error.data
        });
      });
  }
}


export const getAccountProfiles = (id) => {
  return dispatch => {
    return http.get('invoice-profile/loyalty/account/' + id + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_PROFILES",
          payload: response.data
        });
      })
      .catch(error => {
        console.log(error.data);
        dispatch({
          type: "SET_PROFILES",
          payload: error.data
        });
      });
  }
}

export const getAllProfiles = () => {
  return (dispatch) => {
    return http
      .get(
        "invoice-profile/all?api_token=" +
          sessionStorage.getItem("access_token")
      )
      .then((response) => {
        dispatch({
          type: "SET_PROFILES",
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.data);
        dispatch({
          type: "SET_PROFILES",
          payload: error.data,
        });
      });
  };
};
