import React from "react";

export default function MenuIcon() {
  const sidebar = document.querySelectorAll(".app-sidebar");

  // show sidebar
  const showSidebar = () => {
    const list = sidebar[0].classList;
    list.toggle("show");

    if (list.contains("hide")) {
      list.remove("hide");
    }
  };

  return (
    <div className="menu__icon" onClick={showSidebar}>
      <i className="ik ik-list"></i>
    </div>
  );
}
