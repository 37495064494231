import React from 'react'

export default function MembersShimmer() {
  return (
    <div className="shimmer__container">
      <div>
        <div className="heading_line shine" />
      </div>
      <div className="search shine">
        <div className="search__input shine" />
        <div className="search__button shine" />
      </div>

      <div className="results__group shine"></div>
    </div>
  );
}
