import React from "react";
import HeaderBar from "../../../partials/Header/HeaderBar";
import SideBar from "../../../partials/SideBar";
import { getProfile } from "../../../../store/actions/user";
import { can } from "../../../../helpers/permission";
import { connect } from "react-redux";
import { getClaims } from "../../../../store/actions/claim";
import DataTable from "react-data-table-component";
import { isAdmin } from "../../../../helpers/user";
import { toast } from "react-toastify";
import ClaimPreviewModal from "./partials/ClaimPreviewModal";

class ClaimsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      canSubmitClaim: false,
      is_disabled: false,
      claims: [],
      isAdmin: false,
      loading: false
    };
  }

  componentDidMount() {
    this.props.getProfile().then(() => {
      this.setState({
        user: this.props.user,
        canSubmitClaim: can("Submit claim", this.props.user.permissions),
        isAdmin: isAdmin(this.props.user.roles),
      });
      this.fetchClaims();
    });
  }

  fetchClaims = () => {
    this.setState({
      loading: true
    });
    this.props.getClaims().then(() => {
      this.setState({
        claims: this.props.claims,
        loading: false
      });
    });
    
  };

  render() {
    const columns = [
      {
        name: "#",
        selector: "id",
        sortable: true,
      },
      {
        name: "Sent on",
        selector: "date",
        sortable: true,
      },
      {
        name: "User",
        selector: (row) => row.invoice_profile.user.name,
        cell: (row) => row.invoice_profile.user.name,
        sortable: true,
      },
      {
        name: "Invoice profile",
        selector: (row) => row.invoice_profile.entity_name,
        cell: (row) => row.invoice_profile.entity_name,
        sortable: true,
      },
      {
        name: "Invoice number",
        selector: (row) => row.invoice_number,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        cell: (row) => (
          <span
            className={row.status === "sent" ? "text-success" : "text-danger"}
          >
            {row.status.toUpperCase()}
          </span>
        ),
        sortable: true,
      },
      {
        name: "Month",
        selector: "month",
        sortable: true,
      },
      {
        name: "Year",
        selector: "year",
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <>
            <ClaimPreviewModal
              claimId={row.id}
              />
          </>
        ),
      },
    ];


  
 





  

    
    
    
   

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Claims</h3>
                  </div>
                  <div className="card-header">
                    {this.state.canSubmitClaim && !this.state.isAdmin && (
                      <>
                        {!this.state.is_disabled ? (
                          <a href="/claims/add" className="btn btn-primary">
                            Submit a claim
                          </a>
                        ) : (
                          <button
                              type="button"
                              onClick={() =>  toast.warning("Claiming period starts from the first of July")}
                           
                            className="btn btn-primary"
                          >
                            Submit a claim
                          </button>
                        )}
                      </>
                    )}
                  </div>
                  <div className="card-body">
                    {
                      this.state.loading ? <><p>Please wait ...</p></> : <></>
                    }
                    <DataTable
                      columns={columns}
                      data={this.state.claims}
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    claims: state.claimReducer.claims,
  };
};

export default connect(mapStateToProps, { getProfile, getClaims })(ClaimsList);
