import moment from 'moment';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { BeatLoader } from 'react-spinners';

export default function BackupWinnnersModal({data,date}) {

  const [isOpened, setIsOpened] = useState(false)
  const [winnerSearch, setWinnerSearch] = useState("");
    const handleWinnerSearch = (e) => {
      setWinnerSearch(e.target.value);
    };

  const backup_winners = data
    ?.filter((item) => item.prize_type === "daily")
    ?.sort((a, b) => b.created_at - a.created_at)

    ?.map((winner) => {
      const {
        id,
        cell_number,
        last_name,
        first_name,
        prize_type,
        created_at,
        
        updated_at,
      } = winner;

      return {
        id,
        name: `${first_name} ${last_name}`,
        surname: last_name,
        prize_type,
        created_at: moment(created_at).format("LLL"),
        
        updated_at: moment(updated_at).format("LLL"),
        cell_number,
      };
    });
  
  const winner_columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Prize Type",
      selector: "prize_type",
      sortable: true,
    },
    {
      name: "Cell Number",
      selector: "cell_number",
      sortable: true,
    },
    {
      name: "Created At",
      selector: "created_at",
      sortable: true,
      width: "300px",
      align: "center",
    },

    {
      name: "Updated At",
      selector: "updated_at",
      sortable: true,
    },
   
  ];
  return (
    <div>
      <span
        role="button"
        className="text-center btn"
        onClick={() => setIsOpened(true)}
      >
        <i class="fa fa-users" aria-hidden="true"></i>
      </span>

      <Modal
        show={isOpened}
        onHide={() => setIsOpened(false)}
        size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{`${date} draw back up winners`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="py-5">
            <h1>Winners</h1>
            <div className="align-items-center mb-20 ">
              <input
                type="text"
                name="search"
                placeholder="Search winners"
                className="p-2 form-control w-50"
                onChange={handleWinnerSearch}
              />
            </div>
            <DataTable
              // progressPending={query.isLoading}
              progressComponent={<BeatLoader color="#30A2FF" />}
              columns={winner_columns}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 45]}
              title="Daily Draw Winners"
              data={backup_winners?.filter((entry) => {
                if (winnerSearch === "") {
                  return entry;
                } else {
                  return Object.values(entry)
                    .join("")
                    .toLowerCase()
                    .includes(winnerSearch.toLowerCase());
                }
              })}
              pagination
              highlightOnHover
              responsive
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
