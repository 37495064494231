import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { DateRangePicker } from 'react-date-range';
import {formatDate} from '../../../helpers/date';

class DateRangePickerWidget extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      start_date: new Date(),
      end_date: new Date()
    };
  }

  componentDidMount() {
    this.setState({
      start_date: this.props.start_date ,
      end_date: this.props.end_date
    });
  }

  handleShow = () => {
    this.setState({
      show: true
    });
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  updateDates = (ranges) => {
    this.setState({
      start_date: ranges.selection.startDate,
      end_date: ranges.selection.endDate
    });
  }

  setDateRanges = () => {
    this.props.handleUpdateDates(this.state);
    this.handleClose();
  }

  render () {
    const selectionRange = {
      startDate: this.state.start_date,
      endDate: this.state.end_date,
      key: 'selection',
    }
    return (
      <>
        <button className='btn btn-primary date-range-picker-button' onClick={this.handleShow}>
              <i className='fa fa-calendar'></i>
              {
                this.state.start_date !== null && (
                  <>
                    {formatDate(this.state.start_date) + ' - ' + formatDate(this.state.end_date)}
                  </>
                )
              }
        </button>
        <Modal 
          show={this.state.show} 
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title ?? 'Select date range to filter' }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={this.updateDates}
              showMonthAndYearPickers={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={this.state.loading} variant="secondary" onClick={this.handleClose}>
              No
            </Button>
            <Button disabled={this.state.loading} variant="primary" onClick={this.setDateRanges}>
              {
                this.state.loading && (
                  <Spinner animation='grow' />
                )
              }
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default DateRangePickerWidget;
