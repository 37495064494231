import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { storeReward } from '../../../../store/actions/reward';
import { connect } from 'react-redux';
import { reward_types, expires_after } from '../../../../helpers/promotions';
import Select from 'react-select';

class AddReward extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      name: '',
      type: '',
      value: 0.00,
      point_value: 0.00,
      voucher_expires_at: expires_after[0].value,
      monthly_total: 0.00,
      grand_total: 0.00
    };
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  handleShow = () => {
    this.setState({
      show: true
    });
  }

  handleTypeChange = (option) => {
    this.setState({
      type: option.value
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  store = () => {
    this.setState({loading: true});
    this.props.storeReward({
      name: this.state.name,
      type: this.state.type,
      point_value: this.state.point_value,
      value: this.state.value,
      monthly_total: this.state.monthly_total,
      grand_total: this.state.grand_total,
      promotion_id: this.props.promotion_id,
      voucher_expires_at: this.state.voucher_expires_at
    })
    .then(() => {
      this.setState({loading: false});
      if (this.props.status) {
        window.location.reload();
      }
    })
  }

  handleExpiresChange = (option) => {
    this.setState({
      voucher_expires_at: option.value
    });
  }

  render () {
    return (
      <>
        <button className='btn btn-primary' type='button' onClick={this.handleShow}><i className='fa fa-plus'></i></button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add reward</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group'>
              <label>Name</label>
              <input type='text' name='name' className='form-control' onChange={this.handleChange} />
              {
                this.props.errors && (
                  <span className='text-danger'>{ this.props.errors.name ?? ''}</span>
                )
              }
            </div>
            <div className='form-group'>
              <label>Type</label>
              <Select
                options={reward_types}
                onChange={this.handleTypeChange}
              />
              {
                this.props.errors && (
                  <span className='text-danger'>{this.props.errors.type ?? ''}</span>
                )
              }
            </div>
            {
              this.state.type === 'crown' && (
                <>
                  <div className='form-group'>
                    <label>Point value (in crown)</label>
                    <input type='number' name='point_value' className='form-control' onChange={this.handleChange} value={this.state.point_value} />
                    {
                      this.props.errors && (
                        <span className='text-danger'>{this.props.errors.point_value ?? ''}</span>
                      )
                    }
                  </div>
                </>
              )
            }
            {
              this.state.type === 'voucher' && (
                <>
                  <div className='form-group'>
                    <label>Voucher value (in Rand)</label>
                    <input type='number' name='value' className='form-control' onChange={this.handleChange} value={this.state.value} />
                    {
                      this.props.errors && (
                        <span className='text-danger'>{this.props.errors.value ?? ''}</span>
                      )
                    }
                  </div>
                  <div className='form-group'>
                    <label>Expires after</label>
                    <Select 
                      options={expires_after}
                      onChange={this.handleExpiresChange}
                      value={expires_after.filter(option => option.value === this.state.voucher_expires_at)}
                    />
                    {
                      this.props.errors && (
                        <span className='text-danger'>{this.props.errors.voucher_expires_at ?? ''}</span>
                      )
                    }
                  </div>
                </>
              )
            }
            <div className='form-group'>
              <label>Monthly total to be given (in Rand)</label>
              <input type='number' name='monthly_total' className='form-control' onChange={this.handleChange} value={this.state.monthly_total} />
              {
                this.props.errors && (
                  <span className='text-danger'>{this.props.errors.monthly_total ?? ''}</span>
                )
              }
            </div>
            <div className='form-group'>
              <label>Grand total to be given for the duration of the campaign(in Rand)</label>
              <input type='number' name='grand_total' className='form-control' onChange={this.handleChange} value={this.state.grand_total} />
              {
                this.props.errors && (
                  <span className='text-danger'>{this.props.errors.grand_total ?? ''}</span>
                )
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={this.state.loading} variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button disabled={this.state.loading} variant="primary" onClick={this.store}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.rewardReducer.status,
    errors: state.rewardReducer.errors
  }
}

export default connect (mapStateToProps, {storeReward}) (AddReward);
