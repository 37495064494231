const initialState = {
  products: [],
  status: false,
  errors: null
};

export default function product (state = initialState, action) {
  switch (action.type) {
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload
      };
    case "SAVE_PRODUCT":
      return {
        ...state,
        status: action.payload.success ?? false
      };
    case "SET_ERRORS":
      return {
        ...state,
        errors: action.payload.errors
      };
    default:
      return state;
  }
}
