import React from 'react';
import HeaderBar from '../../partials/Header/HeaderBar';
import SideBar from '../../partials/SideBar';
import DataTable from 'react-data-table-component';
import { getSurveys } from '../../../store/actions/promotion';
import { connect } from 'react-redux';

class SurveyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: null
    }
  }

  componentDidMount() {
    const { match: {params}} = this.props;
    this.props.getSurveys(params.id)
      .then(() => {
        this.setState({
          survey: this.props.survey
        });
      })
  }

  render () {
    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <a href='/promotions'> Back to promotions </a>
                  </div>
                  <div className='card-header'>
                    <h3>Promotion survey data</h3>
                  </div>
                  <div className='card-body'>
                    {
                      this.state.survey !== null && (
                        <DataTable
                          columns={this.state.survey.columns}
                          data={this.state.survey.data}
                          pagination
                        />
                      )
                    }
                    {
                      this.state.survey === null && (
                        <div className='text-center'>
                          <p>There is data to display</p>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    survey: state.promotionReducer.survey_data
  };
};

export default connect(mapStateToProps, { getSurveys }) (SurveyList);
