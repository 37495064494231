import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { formatDate } from '../../../../helpers/date';
import { downLoadExcel } from '../../../../helpers/export';
import { getAdminUnblockedMembers, getUnblockedMembers } from '../../../../store/actions/stores';
import HeaderBar from '../../../partials/Header/HeaderBar';
import SideBar from '../../../partials/SideBar';
import BackButton from '../../../utils/BackButton';
import { Search } from '../../../utils/search';
import MembersShimmer from '../../../utils/shimmers/MembersShimmer';
import DateRangePickerWidget from '../../widgets/DateRangePicker';
import DownLoadTransactionsReport from '../modals/DownLoadTransactions';
import ReasonModal from '../modals/ReasonModal';

function UnblockedMembersList() {
  const { log } = console;
  const [loading, setLoading] = useState(true);

  const [members, setMembers] = useState([]);
  const [searchText, setSearchText] = useState("");

  // get date range from the first day of the month to today
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date(),
  });

 
   

  const onSearch = (e) => {
    setSearchText(e.target.value);

    const result = Search(members?.unblocked, e.target.value).then((results) => {
      setMembers(results);
      // log(results);
      // log(members);
    }) 

    return result
    

  }

    const handleDateChange = (ranges) => {
      setDateRange({
        startDate: ranges.start_date,
        endDate: ranges.end_date,
      });

      const body = {
        from: formatDate(ranges.start_date) + " 00:00:00",
        to: formatDate(ranges.end_date) + " 23:59:59",
      };


      setTimeout(() => {
         setLoading(true);
         getAdminUnblockedMembers(body)
           .then((data) => {
            //  log(data);
             setMembers(data);
             setLoading(false);
           })
           .catch((err) => {
             log(err);
             setLoading(false);
           });
      },1000)


    };
  
  const downLoadReport = () => {

    const data = members?.map((member) => {
      return {
        "Customer Cell Number": member?.cell_number,
        "Customer Name": member?.name,

        "Date Blocked": member?.date_blocked,
        "Date Unblocked": member?.date_unblocked,
        "Actioned By": member?.blocked_by,
        "Unblocked by": member?.blocked_by,
        "Added To VIP": member?.add_to_vip,
        "Comments ": member?.reason,
      };
    }
    );

 
    downLoadExcel(data, "Unblocked Members");


  }


  useEffect(() => { 

 
   const body = {
     from: formatDate(dateRange.startDate),
     to: formatDate(dateRange.endDate),
   };

    const getMembers = () => {
      setLoading(true);
      getAdminUnblockedMembers(body)
        .then((data) => {
        
          setMembers(data);
          setLoading(false);
        })
        .catch((err) => {
          log(err);
          setLoading(false);
        });
    };

    getMembers();

    return () => {
      setMembers([]);
    }
  }, [dateRange.endDate, dateRange.startDate, log]);





  const membersData = members && members?.map((member) => {
    return {
      id: member.member_id,
      "Date blocked": member.date_blocked,
      "Date Unblocked": member.date_unblocked,
      first_name: member.name,
      "Cell Number": member.cell_number,
      "unblocked by": member.blocked_by,
      "vip": member.add_to_vip,
      reason: member.reason,
    };
  });

        


  const columns = [
    {
      name: "Date blocked",
      selector: "Date blocked",
      sortable: true,
    },
    {
      name: "Date Unblocked",
      selector: "Date Unblocked",
      sortable: true,
    },
    {
      name: "Full name",
      selector: "first_name",
      sortable: true,
    },
    {
      name: "Cell Number",
      selector: "Cell Number",
      sortable: true,
    },
    {
      name: "Unblocked by",
      selector: "unblocked by",
      sortable: true,
    },
    {
      name: "added to VIP",
      selector: "vip",
      sortable: true,
    },
    {
      name: "Reason",
      cell: (row) => (
        <>
          <ReasonModal reason={row.reason} />
        </>
      ),
    },
    {
      name: "Transaction Report",
      selector: "download",
      cell: (row) => (
        <div className="flex items-center">
          <DownLoadTransactionsReport user={row} store_id={""} />
        </div>
      ),
    },
  ];



  


 
  return (
    <>
      <HeaderBar />
      <SideBar />

      <main className="main-content">
        {loading ? (
          <MembersShimmer />
        ) : (
          <div className="container-fluid">
            <>
              <BackButton />
            </>

            <div className="row">
              <div className="card-header">
                <h5>
                  Unblocked Members report from{" "}
                  {formatDate(dateRange.startDate)} to{" "}
                  {formatDate(dateRange.endDate)}
                </h5>
              </div>
              <div className="card">
                <div className="card-body">
                  {/* <div className="row ">
                    <div className="form-group col-md-4">
                      <label>&nbsp;</label>
                      <input
                        value={searchText}
                          type="search"
                          onChange={onSearch}
                         
                       
                        className="form-control"
                        placeholder="Search members"
                      />
                    </div>
                  </div> */}

                  <div className="actions__container">
                    <div>
                      <h6>Select period</h6>
                      <DateRangePickerWidget
                        start_date={dateRange.startDate + " 00:00:00"}
                        end_date={dateRange.endDate + " 23:59:00"}
                        handleUpdateDates={handleDateChange}
                      />
                    </div>

                    <button
                      onClick={downLoadReport}
                      className="btn btn-primary"
                    >
                      Full Report
                    </button>
                  </div>
                  {membersData && (
                    <DataTable
                      columns={columns}
                      data={membersData}
                      responsive={true}
                      noDataComponent="No members found in the date range"
                      pagination
                      progressPending={loading}
                      className="transaction-table"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
}

export default UnblockedMembersList;