import React from 'react';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { deletePromotion } from '../../../../store/actions/promotion';
import { connect } from 'react-redux';

class DeletePromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false
    }
  }

  handleShow = () => {
    this.setState({
      show: true
    });
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  destroy = () => {
    this.setState({
      loading: true
    });
    this.props.deletePromotion(this.props.promotion.id)
      .then(() => {
        this.setState({ loading: false });
        if (this.props.status) {
          window.location.reload();
        }
      });
  }

  render() {
    return (
      <>
        <button type='button' onClick={this.handleShow} className={'btn btn-link text-danger'}><i className='fa fa-times'></i> Delete promotion </button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this promotion? this action cannot be undone.</Modal.Body>
          <Modal.Footer>
            <Button disabled={this.state.loading} variant="secondary" onClick={this.handleClose}>
              No
            </Button>
            <Button disabled={this.state.loading} variant="primary" onClick={this.destroy}>
              {
                this.state.loading && (
                  <Spinner animation='grow' />
                )
              }
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.promotionReducer.status
  };
};

export default connect(mapStateToProps, { deletePromotion }) (DeletePromotion);
