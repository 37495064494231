import React from "react";

export default function BackButton() {
  return (
    <div className = "pb-10">
      <button
        className="btn btn-primary"
        onClick={() => {
          window.history.back();
        }}
      >
        <i className="fa fa-arrow-left"> </i>
      </button>
    </div>
  );
}
