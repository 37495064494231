import React from 'react';
import HeaderBar from '../../partials/Header/HeaderBar';
import SideBar from '../../partials/SideBar';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { getRewards } from '../../../store/actions/reward';
import AddReward from './Modals/AddReward';
import DeleteReward from './Modals/DeleteReward';
import EditReward from './Modals/EditReward';

class PromotionRewardList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rewards: [],
      loading: false,
      promotion_id: null
    };
  }

  componentDidMount() {
    const {match: {params}} = this.props;
    this.setState({
      promotion_id: params.id
    });
    this.props.getRewards(params.id)
      .then(() => {
        this.setState({
          rewards: this.props.rewards
        });
      });
  }

  render () {
    const columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true
      },
      {
        name: 'Type',
        selector: 'type_name',
        sortable: true
      },
      {
        name: 'Value Given (in Rand)',
        selector: 'value',
        sortable: true,
        cell: row =>  <>
                        {row.type === 'crown' ? 'N/A' : 'R' + row.value}
                      </>
      },
      {
        name: 'Point value (in Crown)',
        selector: 'point_value',
        sortable: true,
        cell: row =>  <>
                        {row.type === 'voucher' ? 'N/A' : row.point_value + ' crowns'}
                      </>
      },
      {
        name: 'Monthly total',
        selector: 'monthly_total',
        sortable: true,
        cell: row => 'R ' + row.monthly_total
      },
      {
        name: 'Grand total (duration of campaign)',
        selector: 'grand_total',
        sortable: true,
        cell: row => 'R ' + row.grand_total
      },
      {
        name: 'Date',
        selector: 'created_at',
        sortable: true
      },
      {
        name: 'Actions',
        cell: row => 
                    <>
                      {
                        this.state.promotion_id !== null && (
                          <>
                            <EditReward reward={row} promotion_id={this.state.promotion_id} />
                          </>
                        )
                      }
                      <DeleteReward reward={row} />
                    </>
      }
    ];

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className='main-content'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h3>Promotion rewards</h3>
                  </div>
                  <div className='card-header'>
                    {
                      this.state.promotion_id !== null && (
                        <>
                          <AddReward promotion_id={this.state.promotion_id} />
                        </>
                      )
                    }
                  </div>
                  <div className='card-body'>
                    <DataTable
                      columns={columns}
                      data={this.state.rewards}
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rewards: state.rewardReducer.rewards
  };
};

export default connect (mapStateToProps, { getRewards }) (PromotionRewardList);
