import http from "../../instances/http";

export const getStores = (token) => {
  return async (dispatch) => {
    let url = "store/all?api_token=";
    try {
      const response = await http.get(
        url + "tbTPwCFQk0iQk3RgwPDIAUtpwXkeS08W" ||
          sessionStorage.getItem("access_token")
      );
      dispatch({
        type: "SET_STORES",
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: "SET_STORES",
        payload: error.data,
      });
    }
  };
};

export const getStore = (id) => {
  return (dispatch) => {
    return http
      .get(
        "store/" + id + "?api_token=" + sessionStorage.getItem("access_token")
      )
      .then((response) => {
        dispatch({
          type: "SET_STORE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SET_STORE",
          payload: error.data,
        });
      });
  };
};

export const saveStore = (data, client_id = null) => {
  return (dispatch) => {
    let url = "store?api_token=";
    if (client_id) {
      url = "store?user_id=" + client_id + "&api_token=";
    }
    return http
      .post(url + sessionStorage.getItem("access_token"), data)
      .then((response) => {
        dispatch({
          type: "SAVE_STORE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_STORE",
          payload: error.data,
        });
      });
  };
};

export const updateStore = (id, data, client_id = null) => {
  return (dispatch) => {
    let url = "store/" + id + "?api_token=";
    if (client_id) {
      url = "store/" + id + "?user_id=" + client_id + "&api_token=";
    }
    return http
      .put(url + sessionStorage.getItem("access_token"), data)
      .then((response) => {
        dispatch({
          type: "UPDATE_STORE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_STORE",
          payload: error.data,
        });
      });
  };
};

export const deleteStore = (id) => {
  return (dispatch) => {
    return http
      .delete(
        "store/" + id + "?api_token=" + sessionStorage.getItem("access_token")
      )
      .then((response) => {
        dispatch({
          type: "DESTROY_STORE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DESTROY_STORE",
          payload: error.data,
        });
      });
  };
};

export const importStore = (data, client_id = null) => {
  return (dispacth) => {
    let formData = new FormData();
    formData.append("file", data.file);
    if (client_id) {
      formData.append("user_id", client_id);
    }
    return http
      .post(
        "store/import/upload?api_token=" +
          sessionStorage.getItem("access_token"),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        dispacth({
          type: "IMPORT_STORE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispacth({
          type: "IMPORT_STORE",
          payload: error.data,
        });
      });
  };
};

export const getStats = (store_id, date_range, graphs = false) => {
  return (dispatch) => {
    return http
      .post(
        "store/stats/all?api_token=" + sessionStorage.getItem("access_token"),
        { store_id: store_id, date_range: date_range }
      )
      .then((response) => {
        dispatch({
          type: "SET_STATS",
          payload: response.data.data.stats,
        });
        if (graphs) {
          dispatch({
            type: "SET_GRAPHS",
            payload: response.data.data.graphs,
          });
        }
      });
  };
};

export const getGraphs = (store_id, month, year) => {
  return (dispatch) => {
    return http
      .post(
        "store/graphs/all?api_token=" + sessionStorage.getItem("access_token"),
        { store_id: store_id, month: month, year: year }
      )
      .then((response) => {
        dispatch({
          type: "SET_GRAPHS",
          payload: response.data.data,
        });
      });
  };
};

export const getStoreStats = (id) => {
  try {
    let url = `/loyalty/member/storeReport/${id}?api_token=${sessionStorage.getItem(
      "access_token"
    )}`;

    return http
      .get(url)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getStoreMembers = (id, status) => {
  try {
    let url = `/loyalty/storeMembersData/${id}?param=${status}&api_token=${sessionStorage.getItem(
      "access_token"
    )}`;

    return http
      .get(url)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};
export const getStoreMembersTransaction = (body) => {
  try {
    let url = `loyalty/store/user/transaction?&api_token=${sessionStorage.getItem(
      "access_token"
    )}`;

    return http
      .post(url, body)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getUnblockedMembers = (id) => {
  try {
    let url = `/loyalty/storeMembersData/900?param=unblocked&api_token=${sessionStorage.getItem(
      "access_token"
    )}`;

    return http
      .get(url)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};
export const getAdminUnblockedMembers = (body) => {
  try {
    let url = `/loyalty/get/unblocked/members?api_token=${sessionStorage.getItem(
      "access_token"
    )}`;

    return http
      .post(url, body)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};
