import React from 'react';
import HeaderBar from '../../partials/Header/HeaderBar';
import SideBar from '../../partials/SideBar';
import DataTable from 'react-data-table-component';
import {connect} from 'react-redux';
import {fetchProfiles} from '../../../store/actions/invoiceProfile';
import DeleteStore from './modals/DeleteStore';

class InvoiceProfileList extends React.Component {
  constructor(props) {
    super (props);
    this.state = {
      profiles: [],
      loading: false,
      hasUser: false
    }
  }

  componentDidMount () {
    this.setState({
      loading: true
    });
    this.props.fetchProfiles()
      .then(() => {
        this.setState({
          loading: false,
          profiles: this.props.profiles
        });
      });
  }

  render () {
    const columns = [
      {
        name: 'Entity name',
        selector: 'entity_name',
        cell: row => <a href={'/invoice/profiles/edit/' + row.id} className="text-primary">{row.entity_name}</a>,
        sortable: true
      },
      {
        name: 'Address',
        selector: 'address',
        sortable: true
      },
      {
        name: 'Region',
        selector: 'region',
        sortable: true
      },
      {
        name: 'Postal code',
        selector: 'postal_code',
        sortable: true
      },
      {
        name: 'VAT number',
        selector: 'vat_number',
        sortable: true
      },
      {
        name: 'Bank',
        selector: 'bank',
        sortable: true
      },
      {
        name: 'Stores',
        selector: row => row.stores.length,
        sortable: true
      },
      {
        name: 'Actions',
        cell: row => <DeleteStore id={row.id} />
      }
    ];

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h3>Invoice profiles</h3>
                  </div>
                  <div className='card-body'>
                    <a 
                      href='/invoice/profiles/add'
                      className='btn btn-primary'>
                      <i className='fa fa-plus'> </i>
                    </a>
                    <DataTable
                      columns={columns}
                      data={this.state.profiles}
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profiles: state.invoiceProfileReducer.profiles
  };
};

export default connect (mapStateToProps, {fetchProfiles}) (InvoiceProfileList);
