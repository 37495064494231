import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import {connect} from 'react-redux';
import {submitClaim} from '../../../../../store/actions/claim';
import {toast} from 'react-toastify';

class InvoicePreviewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      claim: null,
      subtotal: 0.00
    };
  }

  componentDidMount() {
    this.calculateTotal(this.props.profile.stats.stores_data)
  }

  calculateTotal = (charges) => {
    let sum = charges.reduce(function(prev, current) {
      let amount = parseFloat(current.total_line_item_value).toFixed(2);
      return  prev + +amount;
    }, 0);

    this.setState({
      subtotal: sum
    });
  }

  handleShow = async () => {
    this.setState({
      show: true
    });
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  sendInvoice = () => {
    const nowDate = new Date();

    const startDate = new Date(nowDate.getFullYear(), 5, 26);
    const endDate = new Date(nowDate.getFullYear(), 5, 30);

    let not_claim_period = nowDate >= startDate && nowDate <= endDate;
    // console.log(not_claim_period);

    if (not_claim_period && this.props.month === "06") {
      toast.warning("Claiming period starts from the first of July");
      return;
    }
      

    this.setState({
      loading: true,
    });
    this.props
      .submitClaim(this.props.profile._uid, this.props.month, this.props.year)
      .then(() => {
        this.setState({
          loading: false,
        });

        if (this.props.submit_status) {
          toast.success("Submitted successfully!");
          setTimeout(() => {
            this.handleClose();
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  render () {
    const lineItems = this.props.profile.stats.stores_data && this.props.profile.stats.stores_data.map((data, index) =>
      <tr key={index}>
        <td>{data.store_name}</td>
        <td>{data.store_code}</td>
        <td>{data.total_crown_value}</td>
        <td>ZAR {data.total_amount_value}</td>
        <td>ZAR {data.total_free_pie_redeemed_value}</td>
        <td>ZAR {data.total_free_chip_redeemed_value}</td>
        <td>ZAR {data.total_free_coke_redeemed_value}</td>
        <td>ZAR {data.total_line_item_value}</td>
      </tr>
    );

  
    return (
      <>
        <button className="btn btn-primary" onClick={this.handleShow} disabled={this.state.loading}>
          {
            this.state.loading ? <>Please wait...</> : <>Preview Invoice</>
          }
        </button>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="xl"
          className="invoice-modal"
        >
          <Modal.Header>
            <div className="row invoice-head">
              <div className="col-md-6">
                <p>{this.props.profile.address}</p>
                <p>{this.props.profile.region}</p>
                <p>{this.props.profile.postal_code}</p>
              </div>
              <div className="col-md-6 text-right">
                <p>Invoice date: {new Date().toJSON().slice(0, 10)} </p>
                <p>Vat number: {this.props.profile.vat_number}</p>
                <p>Period start: {this.props.profile.stats.period_start}</p>
                <p>Period end: {this.props.profile.stats.period_end}</p>
              </div>
            </div>
            <h5 className="text-center">
              {
                this.state.loading && (
                  <p>
                    Please wait ...
                  </p>
                )
              }
              {this.props.profile.stats.invoice_title}
            </h5>
          </Modal.Header>
          <Modal.Body>
            <table width="100%" className="table invoice-line-items table-bordered">
              <thead>
                <tr>
                  <th>Store name</th>
                  <th>Store code</th>
                  <th>Crown redeemed</th>
                  <th>Amount redeemed</th>
                  <th>Free pie redeemed</th>
                  <th>Free chip redeemed</th>
                  <th>Free coke redeemed</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {lineItems}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total inc VAT.</td>
                  <td>ZAR {parseFloat(this.state.subtotal).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Body>
            <div className="row invoice-head">
              <div className="col-md-6">
                <p>Bank: {this.props.profile.bank}</p>
                <p>Account number: {this.props.profile.account_number}</p>
                <p>Branch code {this.props.profile.branch_code}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.loading}
              variant="secondary"
              onClick={this.handleClose}
            >
              Close
            </Button>
            {this.props.send && (
              <Button
                disabled={
                  this.state.loading || this.props.profile?.has_claimed === true
                }
                className={`${this.props.profile?.has_claimed === true ? 'cursor-none' : ''}`}
                variant="danger"
                onClick={this.sendInvoice}
              >
                {this.state.loading && <Spinner animation="grow" size="sm" />}
                Send Now (This action cannot be undone!)
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    submit_status: state.claimReducer.submit_status,
    error_message: state.claimReducer.error_message,
    selectedClaim: state.claimReducer.claim
  };
};

export default connect(mapStateToProps, {submitClaim}) (InvoicePreviewModal);
