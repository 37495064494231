import http from "../../instances/http";
import { toast } from 'react-toastify';


// This function is used to strip off leading numbers in a response, until we figure out what is wrong with the server.
const processResponse = (response) => {
  if (Object.prototype.toString.call(response.data) === "[object String]") {
    let data = JSON.stringify(response.data)
    const index = data.indexOf('{')
    const text = data.substring(1, index)
    if(text != '{'){
      data = data.replace(text, '')
      data = JSON.parse(JSON.parse(data))
      return data
    }
    return data 
  }else{
    return response.data;
  }
}

export const getClaims = () => {
  return dispatch => {
    return http.get('claims?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        // let data = JSON.stringify(response.data)
        // data = data.replace('000251.4000000015', '')
        // data = JSON.parse(JSON.parse(data))
          dispatch({
            type: "SET_CLAIMS",
            payload: processResponse(response).data
          });
      })
      .catch(error => {
        console.log(error.data);
      });
  }
};

export const getClaim = (id) => {
  return dispatch => {
    return http.get('claims/' + id + '?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
          dispatch({
            type: "SET_CLAIM",
            payload: response.data.data
          });
      })
      .catch(error => {
        console.log(error.data);
      })
  }
}

export const getProfiles = (month, year, invoice_profile_id) => {
  return dispatch => {
    return http.post('invoice-profile/statsv2/all?api_token=' + sessionStorage.getItem('access_token'),
    {
      month: month,
      year: year,
      invoice_profile_id: invoice_profile_id
    })
      .then(response => {
        dispatch({
          type: "SET_PROFILES",
          payload: processResponse(response).data
        });
      })
      .catch(error => {
        toast.error(processResponse(error).message);
      });
  };  
};

export const getEntities = () => {
  return dispatch => {
    return http.get('invoice-profile/all/entities?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {


          dispatch({
            type: "SET_ENTITIES",
            payload: response.data.data
          });
      })
      .catch(error => {
        console.log(error.data);
      });
  }
};

export const submitClaim = (invoice_profile_id, month, year) => {
  return dispatch => {
    return http.post('claims?api_token=' + sessionStorage.getItem('access_token'), {invoice_profile_id: invoice_profile_id, month: month, year: year})
      .then(response => {
        dispatch({
          type: "SEND_CLAIM",
          payload: processResponse(response).data
        });
        toast.success("Claim sent successfully");
      })
      .catch(error => {
        dispatch({
          type: "SEND_CLAIM",
          payload: {success:false, message: error.data.errors.invoice_profile_id[0] ?? ''}
        });
        toast.error(processResponse(error).errors.invoice_profile_id[0]);
      })
  }
}
