export const can = (user_permission, permissions = []) => {
  return permissions.includes(user_permission);
};

export const formatSelectRoles = (roles = []) => {
  let formatted_roles = [];
  if (roles.length) {
    for (let i = 0; i < roles.length; i++) {
      formatted_roles.push({value: roles[i], label: roles[i]});
    }
  }
  return formatted_roles;
};