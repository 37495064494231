import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getMemberStats,
  getMemberCardStats,
} from "../../../store/actions/member";
import { getProfile } from "../../../store/actions/user";
import DateRangePickerWidget from "../widgets/DateRangePicker";
import HeaderBar from "../../partials/Header/HeaderBar";
import SideBar from "../../partials/SideBar";
import { formatDate } from "../../../helpers/date";
import CounterWigdet from "../dashboard/widgets/CounterWidget";
import MembersTransactionTable from "../widgets/transaction/MembersTransactionTable";
import Loader from "../../utils/Loader";
import MemberStatusModal from "../user/modals/MemberStatusModal";
import MemberVipStatusModal from "../user/modals/MemberVipStatusModal";
import BackButton from "../../utils/BackButton";
import MemberBlackListModal from "../user/modals/MemberBlackListModal";
import EditMember from "../user/member/Widgets/EditMember";

class MemberTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      loading: false,
      member: null,
      email: "",
      card_stats: null,
      stats: null,
      errors: null,
      crown_balance: 0,
      user_id: null,
      roles: "",

      start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      end_date: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ),
    };
  }
  fetchProfile = () => {
    this.props.getProfile().then(() => {
      this.setState({
        email: this.props.admin?.email,
      });
      // console.log(this.state.email)
    });
  };

  componentDidMount = async () => {
    const {
      match: { params },
    } = this.props;
    this.setState({
      user_id: params.id,
    });
    this.setState({
      loading: true,
    });

    let role = sessionStorage.getItem("roles");
    this.setState({
      roles: role,
    });

    this.fetchProfile();

    this.props
      .getMemberStats(params.id, [
        formatDate(this.state.start_date) + " 00:00:00",
        formatDate(this.state.end_date) + " 23:59:59",
      ])
      .then(() => {
        this.setState({
          member: this.props.member,
          stats: this.props.stats,
          // crown_balance:
          //   this.props?.stats?.total_earned - this.props?.stats?.total_redeemed,
          // transactions: this.props?.transactions,
          loading: false,
        });
      });

    this.setState({
      loading: true,
    });

    this.props
      .getMemberCardStats(params.id, [
        formatDate(this.props?.member?.created_at),
        formatDate(this.state.end_date),
      ])
      .then(() => {
        this.setState({
          start_date: this.props?.member?.created_at
            ? formatDate(this.props?.member?.created_at)
            : new Date(new Date().getFullYear(), new Date().getMonth(), 1),

          card_stats: this.props.stats,
          transactions: this.props?.transactions,
          crown_balance:
            this.props?.stats?.total_earned - this.props?.stats?.total_redeemed,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          errors: error,
        });
      });
  };

  fetchTransactions = async (e) => {
    this.setState({
      loading: true,
    });
    await this.props.getMemberTransactions(
      this.state.cell_number,
      this.state.account_id
    );
    this.setState({
      transactions: this.props?.transactions,
    });
    this.setState({
      loading: false,
    });
  };

  handleSelect = (ranges) => {
    this.setState({
      start_date: ranges.start_date,
      end_date: ranges.end_date,
    });
    this.setState({
      loading: true,
    });

    this.props
      .getMemberStats(this.state.user_id, [
        formatDate(ranges.start_date),
        formatDate(ranges.end_date),
      ])
      .then(() => {
        this.setState({
          member: this.props?.member,
          stats: this.props?.stats,
          transactions: this.props?.transactions,
          crown_balance:
            this.props?.stats?.total_earned - this.props?.stats?.total_redeemed,

          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          errors: error,
        });
      });
  };

  render() {
    let total_orders = this.state.transactions
      .filter(
        (item) =>
          item?.transaction_type === "credit" &&
          item?.order_reference !== "Registration"
      )
      .map((item) => item);
    let last_store =
      this.state.transactions && this.state.transactions[0]?.name;

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <>
            <BackButton />
          </>
          <div className="container-fluid">
            {this.state.loading ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  {this.state.account_id && (
                    <a href={"/members/" + this.state.account_id}>
                      <i className="fa fa-angle-left"> </i> Back to members list
                    </a>
                  )}

                  <div className="card">
                    {this.state.member && (
                      <div className="card-header modal__header_tiltle_container">
                        <h4 className="card-title ">
                          {this.state.member
                            ? this.state.member.first_name
                            : ""}
                          's report
                        </h4>
                        <div className="position-relative edit">
                          <EditMember
                            id={this.state.member.id}
                            name={this.state.member.first_name}
                            last_name={this.state.member.last_name}
                            cell_number={this.state.member.cell_number}
                          />
                        </div>
                      </div>
                    )}

                    <div className="card-body">
                      {this.state.loading && (
                        <div className="text-center">
                          <Loader />
                        </div>
                      )}
                      {this.state.member && (
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="pt-10 pb-10 d-flex">
                                <>
                                  {this.state?.email?.includes("vetro.co.za") ||
                                  this.state?.email?.includes(
                                    "devosd@kingpie.co.za"
                                  ) ||
                                  this.state?.roles?.includes(
                                    "Franchise Owner"
                                  ) ||
                                  this.state?.email?.includes(
                                    "Kajeez@kingpie.co.za"
                                  ) ? (
                                    <MemberStatusModal
                                      user={this.state.member}
                                    />
                                  ) : (this.state.member.is_active === 1) ===
                                    1 ? (
                                    <span className="badge badge-success">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      Blocked
                                    </span>
                                  )}
                                </>

                                <>
                                  {this.state?.email?.includes("vetro.co.za") ||
                                  this.state?.email?.includes(
                                    "devosd@kingpie.co.za"
                                  ) ||
                                  this.state?.roles?.includes(
                                    "Franchise Owner"
                                  ) ||
                                  this.state?.email?.includes(
                                    "Kajeez@kingpie.co.za"
                                  ) ? (
                                    <div className="ml-20">
                                      <MemberVipStatusModal
                                        user={this.state.member}
                                      />
                                    </div>
                                  ) : this.state.member.is_vip === 1 ? (
                                    <span className="badge badge-success">
                                      VIP
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      Non Vip
                                    </span>
                                  )}
                                </>
                                <>
                                  {this.state?.email?.includes("vetro.co.za") ||
                                  this.state?.email?.includes(
                                    "devosd@kingpie.co.za"
                                  ) ||
                                  // this.state?.roles?.includes(
                                  //   "Franchise Owner"
                                  // ) ||
                                  this.state?.email?.includes(
                                    "Kajeez@kingpie.co.za"
                                  ) ? (
                                    <div className="ml-20">
                                      <MemberBlackListModal
                                        user={this.state.member}
                                      />
                                    </div>
                                  ) : (
                                    <> </>
                                  )}
                                </>

                                {/* // this.state.member.is_blacklist === 0 ? (
                                  //   <span className="badge badge-dark">
                                  //     Blacklisted
                                  //   </span>
                                  // ) : (
                                  //   <span className="badge badge-light">
                                  //     Not blacklisted
                                  //   </span>
                                  // )} */}
                              </div>
                              <h4>
                                {this.state.member.first_name}{" "}
                                {this.state.member.last_name}
                              </h4>

                              <p>{this.state.member.cell_number}</p>
                              <p>
                                Status:{" "}
                                {this.state.member.is_active === 1
                                  ? "Active"
                                  : "Blocked"}
                              </p>
                              <p>
                                Registered on: {this.state.member.created_at}
                              </p>
                              <p>
                                Store registered at.:{" "}
                                {this.state.member?.store_name ?? "N/A"}
                              </p>
                              <p>Store last purchased at: {last_store}</p>

                              {this.state.member.is_active === 0 && (
                                <div className="pt-50 pb-50">
                                  <h4>Blocked Status</h4>

                                  <p>
                                    Blocked on :{" "}
                                    {this.state?.member?.date_blocked ?? "-"}
                                  </p>
                                  <p>
                                    Blocked by :{" "}
                                    {this.state.member?.blocked_by
                                      ? this.state.member?.blocked_by
                                      : "Admin"}{" "}
                                  </p>
                                  <p>
                                    Reason :{" "}
                                    {this.state?.member?.reason_blocked ??
                                      "FO/FM blocked from fraud alert"}
                                  </p>
                                </div>
                              )}
                              {this.state.member.is_vip === 1 && (
                                <div className="pt-50 pb-50">
                                  <h4>VIP Status</h4>

                                  <p>
                                    Made VIP on :{" "}
                                    {this.state?.member?.vip_date ?? "-"}
                                  </p>
                                  <p>
                                    added by :{" "}
                                    {this.state.member?.vip_add_by
                                      ? this.state.member?.vip_add_by
                                      : "-"}
                                  </p>
                                  <p>
                                    Reason :{" "}
                                    {this.state?.member?.vip_reason ?? "-"}
                                  </p>
                                </div>
                              )}
                              {this.state?.member?.is_blacklist === 1 && (
                                <div className="pt-50 pb-50">
                                  <h4>Black list Status</h4>

                                  <p>
                                    Black listed on :{" "}
                                    {this.state?.member?.blacklist_date ?? "-"}
                                  </p>
                                  <p>
                                    Black listed by :{" "}
                                    {this.state.member?.blacklist_by
                                      ? this.state.member?.blacklist_by
                                      : "-"}
                                  </p>
                                  <p>
                                    Reason :{" "}
                                    {this.state?.member?.blacklist_reason ??
                                      "-"}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row ">
                            <div className="col-md-3">
                              <CounterWigdet
                                icon="shopping-cart"
                                title="Total Orders"
                                color="blue"
                                top_value={
                                  total_orders ? total_orders.length : 0
                                }
                                bottom_value=""
                              />
                            </div>
                            <div className="col-md-3">
                              <CounterWigdet
                                icon="crown"
                                title="Crown Balance"
                                color="green"
                                top_value={`${
                                  this.state.stats
                                    ? this.state.crown_balance.toFixed(2) ?? 0
                                    : 0
                                } Crowns`}
                                bottom_value=""
                              />
                            </div>
                            <div className="col-md-3">
                              <CounterWigdet
                                icon="crown"
                                title="Total earned"
                                color="yellow"
                                top_value={`${
                                  this.state.stats
                                    ? this.state.stats.total_earned ?? 0
                                    : 0
                                } Crowns`}
                                bottom_value=""
                              />
                            </div>
                            <div className="col-md-3">
                              <CounterWigdet
                                icon="crown"
                                title="Total redeemed"
                                color="red"
                                top_value={`${
                                  this.state.stats
                                    ? this.state.stats.total_redeemed ?? 0
                                    : 0
                                } Crowns`}
                                bottom_value=""
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-center">
                    <p>
                      {` Showing transactions from ${formatDate(
                        this.state.start_date
                      )} to
                      ${formatDate(this.state.end_date)}`}
                    </p>

                    {this.props?.member?.created_at && (
                      <DateRangePickerWidget
                        start_date={this.state.start_date}
                        end_date={this.state.end_date}
                        handleUpdateDates={this.handleSelect}
                      />
                    )}
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <h4>Transactions</h4>
                    </div>
                    <div className="card-body">
                      {this.state.transactions.length > 0 && (
                        <form
                          method="post"
                          action={
                            process.env.REACT_APP_RESOURCE_URL +
                            "loyalty/members/stats/download"
                          }
                        >
                          <input
                            type="hidden"
                            name="user_id"
                            value={this.state.user_id}
                          />
                          <input
                            type="hidden"
                            name="date_range[]"
                            value={formatDate(
                              this.props?.member?.created_at
                                ? this.state.start_date
                                : this.state.start_date
                            )}
                          />
                          <input
                            type="hidden"
                            name="date_range[]"
                            value={formatDate(this.state.end_date)}
                          />
                          <button type="submit" className="btn btn-primary">
                            Download
                          </button>
                        </form>
                      )}
                      <MembersTransactionTable
                        transactions={this.state.transactions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    member: state.memberReducer.member,
    stats: state.memberReducer.stats,
    card_stats: state.memberReducer.stats,
    transactions: state.memberReducer.transactions,
    admin: state.userReducer.user,
  };
};

export default connect(mapStateToProps, {
  getMemberStats,
  getProfile,
  getMemberCardStats,
})(MemberTransactions);
