import React, { Component } from 'react';
import ClientTransactions from '../widgets/ClientTransactions';
import ClientAccountForm from '../widgets/ClientAccountForm';
import ClientCounterWidgets from './widgets/ClientCounterWidgets';
import { getClientReport } from '../../../store/actions/report';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import DateRangePickerWidget from '../widgets/DateRangePicker';
import {formatDate} from '../../../helpers/date';
import DashboardStores from './widgets/DashboardStores';

class ClientDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: null,
      
      loading: false,
      start_date: new Date().setDate(new Date().getDate() - 7),
      end_date: new Date(),
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    await this.fetchReportData();

    this.setState({
      loading: false,
    });
    this.getRoles()

    
  }

  

getRoles = () => {

  const roles = sessionStorage.getItem('roles');

  if(!roles){
    sessionStorage.setItem("roles", JSON.stringify(this.props?.user?.roles));
  }

}
 

  fetchReportData = async () => {
    await this.props.getClientReport(this.props.user.id);
    this.setState({
      payload: this.props.payload,
    });
  };

  handleSelect = (ranges) => {
    this.setState({
      start_date: ranges.start_date,
      end_date: ranges.end_date,
    });
    this.setState({
      loading: true,
    });
    this.props
      .getClientReport(this.props.user.id, [
        formatDate(ranges.start_date) + " 00:00:00",
        formatDate(ranges.end_date) + " 23:59:00",
      ])
      .then(() => {
        this.setState({
          payload: this.props.payload,
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <div className="lock-loading">
            <div className="text-center">
              <Spinner animation="border" size="sm" />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col text-center">
            <DateRangePickerWidget
              handleUpdateDates={this.handleSelect}
              start_date={this.state.start_date}
              end_date={this.state.end_date}
            />
            <br />
          </div>
        </div>
        {this.state.payload && (
          <ClientCounterWidgets
            report={this.state.payload}
            roles={this.props.user.roles}
            admin={this.props.admin}
          />
        )}
        {this.state.payload && (
          <DashboardStores stores={this.state.payload.stores} />
        )}
        <div className="row">
          <div className={this.props.account_form ? "col-md-9" : "col-md-12"}>
            {this.state.loading === false && (
              <>
                <ClientTransactions
                  user={this.props.user}
                  start_date={this.state.start_date}
                  end_date={this.state.end_date}
                />
              </>
            )}
          </div>
          {this.props.account_form && (
            <div className="col-md-3">
              <ClientAccountForm user={this.props.user} />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payload: state.reportReducer.payload
  };
};

export default connect (mapStateToProps, { getClientReport }) (ClientDashboard);