import React from 'react';
import DataTable from 'react-data-table-component';
import ShowStore from '../../stores/modals/ShowStore';

class DashboardStores extends React.Component {
  render () {
    let columns = [
      {
        name: 'Date created',
        selector: 'created_at',
        sortable: true
      },
      {
        name: 'Code',
        selector: 'code',
        sortable: true
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true
      },
      {
        name: 'Address',
        selector: 'address',
        sortable: true
      },
      {
        name: 'Has voucher',
        selector: 'has_voucher',
        cell: row => row.has_voucher ? 'Yes' : 'No',
        sortable: true
      },
      {
        name: 'Has loyalty',
        selector: 'has_loyalty',
        cell: row => row.has_loyalty ? 'Yes' : 'No',
        sortable: true
      },
      {
        name: 'Registered members',
        selector: 'total_members',
        sortable: true
      },
      {
        name: 'Actions',
        cell: row =>  <>
                        <ShowStore store={row} />
                      </>
      }

    ];
    return (
      <div className='row'>
        <div className='col'>
          <div className='card'>
            <div className='card-header'>
              <h3>My stores</h3>
            </div>
            <div className='card-body'>
              <DataTable
                columns={columns}
                data={this.props.stores}
                pagination
                className='transaction-table'
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DashboardStores;
