const initialState = {
  fields: [],
  status: false,
  errors: null
};

export default function field (state = initialState, action) {
  switch (action.type) {
    case "SET_FIELDS":
      return {
        ...state,
        fields: action.payload
      };
    case "SAVE_FIELD":
      return {
        ...state,
        status: action.payload.success ?? false
      };
    case "SET_FIELD_ERRORS":
      return {
        ...state,
        errors: action.payload.errors
      };
    default:
      return state;
  }
}
