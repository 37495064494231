import React from 'react';
import HeaderBar from '../../partials/Header/HeaderBar';
import SideBar from '../../partials/SideBar';
import Select from 'react-select';
import DateRangePickerWidget from '../widgets/DateRangePicker';
import { promotion_types } from '../../../helpers/promotions';
import { getPromotion, updatePromotion } from '../../../store/actions/promotion';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class EditPromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      min_order_value: '0.00',
      type: 'order',
      starts_at: null,
      ends_at: null,
      url: '',
      sms: '',
      is_active: 0,
      loading: false,
      redirect: false
    };
  }

  componentDidMount () {
    const { match: {params}} = this.props;
    this.setState({
      loading: true
    });
    this.props.getPromotion(params.id)
      .then(() => {
        this.setState({
          loading: false,
          name: this.props.promotion.name,
          min_order_value: this.props.promotion.min_order_value,
          type: this.props.promotion.type,
          starts_at: new Date(this.props.promotion.starts_at),
          ends_at: new Date(this.props.promotion.ends_at),
          url: this.props.promotion.url,
          sms: this.props.promotion.sms,
          is_active: this.props.promotion.is_active,
        });
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  handleDatesChange = (dates) => {
    this.setState({
      starts_at: dates.start_date,
      ends_at: dates.end_date
    });
  }

  handleActiveChange = (option) => {
    this.setState({
      is_active: option.value
    });
  }

  handleTypeChange = (option) => {
    this.setState({
      type: option.value
    });
  }

  save = () => {
    this.setState({
      loading: true
    });
    const { match: {params}} = this.props;
    this.props.updatePromotion(params.id, {
      name: this.state.name,
      min_order_value: this.state.min_order_value,
      type: this.state.type,
      starts_at: this.state.starts_at,
      ends_at: this.state.ends_at,
      url: this.state.url,
      sms: this.state.sms,
      is_active: this.state.is_active,
    })
    .then(() => {
      this.setState({
        loading: false
      });
      if (this.props.status) {
        this.setState({
          redirect: true
        });
      }
    })
    .catch(() => {
      this.setState({
        loading: false
      });
    });
  }

  render () {
    const active_statuses = [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Inactive',
        value: 0
      }
    ];

    if (this.state.redirect) {
      return (
        <Redirect to='/promotions' />
      );
    }

    return (
      <>
        <HeaderBar />
        <SideBar />
        <div className="main-content">
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h3>Add a promotion</h3>
                  </div>
                  <div className='card-body'>
                    <div className='form-group'>
                      <label>
                        Name
                      </label>
                      <input type='name' disabled={this.state.loading} value={this.state.name} name='name' className='form-control' onChange={this.handleChange} />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.name}</span>
                        )
                      }
                    </div>
                    <div className='form-group'>
                      <label>Select promotion type</label>
                      <Select
                        options={promotion_types}
                        onChange={this.handleTypeChange}
                        value={promotion_types.filter(promotion_type => promotion_type.value === this.state.type)}
                      />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.type}</span>
                        )
                      }
                    </div>
                    <div className='form-group'>
                      <label>Select start and end date</label>
                      <br/>
                      {
                        this.state.starts_at !== null && (
                          <>
                            <DateRangePickerWidget
                              handleUpdateDates={this.handleDatesChange}
                              title='Select start and end dates of promotion'
                              start_date={this.state.starts_at}
                              end_date={this.state.ends_at}
                            />
                          </>
                        )
                      }
                      {
                        this.props.errors && (
                          <>
                            <span className='text-danger'>{this.props.errors.starts_at}</span>
                            <span className='text-danger'>{this.props.errors.ends_at}</span>
                          </>
                        )
                      }
                    </div>
                    <div className='form-group'>
                      <label>Select status</label>
                      <Select
                        options={active_statuses}
                        onChange={this.handleActiveChange}
                        value={active_statuses.filter(option => option.value === this.state.is_active)}
                      />
                      {
                        this.props.errors && (
                          <span className='text-danger'>{this.props.errors.is_active}</span>
                        )
                      }
                    </div>
                    {
                      this.state.type === 'order' && (
                        <>
                          <div className='form-group'>
                            <label>
                              Minimum order value (In Rands)
                            </label>
                            <input type='number' disabled={this.state.loading} name='min_order_value' value={this.state.min_order_value} className='form-control' onChange={this.handleChange} />
                            {
                              this.props.errors && (
                                <span className='text-danger'>{this.props.errors.min_order_value}</span>
                              )
                            }
                          </div>
                          <div className='form-group'>
                            <label>Sms text</label>
                            <textarea disabled={this.state.loading} className='form-control' name='sms' onChange={this.handleChange} value={this.state.sms} />
                            {
                              this.props.errors && (
                                <span className='text-danger'>{this.props.errors.sms}</span>
                              )
                            }
                          </div>
                          <div className='form-group'>
                            <label>Enter the url to redirect user to play for the campaign rewards</label>
                            <input disabled={this.state.loading} value={this.state.url} type='text' name='url' className='form-control' onChange={this.handleChange} placeholder='http://url_of_the_campaign' />
                            {
                              this.props.errors && (
                                <span className='text-danger'>{this.props.errors.url}</span>
                              )
                            }
                          </div>
                        </>
                      )
                    }
                    <button onClick={this.save} disabled={this.state.loading} type='button' className='btn btn-primary'>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.promotionReducer.status,
    errors: state.promotionReducer.errors,
    promotion: state.promotionReducer.promotion
  }
};

export default connect(mapStateToProps, {getPromotion, updatePromotion }) (EditPromotion);
