const initialState = {
  transactions: [],
  status: false,
  errors: [],
  transaction: null
};

export default function transactions (state = initialState, action) {
  switch(action.type) {
    case "SET_TRANSACTIONS":
      return {
        transactions: action.payload?.data,
        status: action.payload?.success,
        errors: action.payload?.errors 
      };

    case "SET_TRANSACTION":
      return {
        transaction: action.payload
      };
    default:
      return state;
  }
}