import http from "../../instances/http";
import { toast } from "react-toastify";

export const getProducts = (promotion_id) => {
  return dispatch => {
    return http.get('product/' + promotion_id + '/all?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SET_PRODUCTS",
          payload: response.data.data
        });
      })
      .catch(error => {
        console.log(error.data);
        toast.error('Could not fetch products');
      });
  };
};

export const storeProduct = (data) => {
  return dispatch => {
    return http.post('product?api_token=' + sessionStorage.getItem('access_token'), data)
      .then(response => {
        dispatch({
          type: "SAVE_PRODUCT",
          payload: response.data
        });
      })
      .catch(error => {
        toast.error('Could not store product');
        dispatch({
          type: "SET_ERRORS",
          payload: error.data
        });
      });
  };
};

export const deleteProduct = (id) => {
  return dispatch => {
    return http.delete('product/' + id +'?api_token=' + sessionStorage.getItem('access_token'))
      .then(response => {
        dispatch({
          type: "SAVE_PRODUCT",
          payload: response.data
        });
      })
      .catch(error => {
        toast.error('Could not delete product');
        dispatch({
          type: "SAVE_PRODUCT",
          payload: error.data
        });
      });
  };
};
