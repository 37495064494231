import http from '../../instances/http';

export const getClientReport = (user_id = null, date_range = []) => {
  let url = 'dashboard/client';
  if (user_id) {
    url = url + '/' + user_id;
  }
  return dispatch => {
    return http.post(url +'?api_token=' + sessionStorage.getItem('access_token'), {
      date_range: date_range
    })
      .then(response => {
        dispatch({
          type: "SET_PAYLOAD",
          payload: response.data
        }); 
      })
      .catch(error => {
        dispatch({
          type: "SET_PAYLOAD",
          payload: error.data
        }); 
      });
  }
}