import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ShowStore extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false
    };
  }

  handleShow = () => {
    this.setState({
      show: true
    });
  }

  handleClose = () => {
    this.setState({
      show: false
    });
  }

  render () {
    return (
      <>
        <button className='btn btn-link' onClick={this.handleShow}><i className='fa fa-eye'></i> </button>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Store information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="store-info">
              <tbody>
                <tr>
                  <td>Store name</td>
                  <td> : {this.props.store.name}</td>
                </tr>
                <tr>
                  <td>Store code</td>
                  <td> : {this.props.store.code}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td> : {this.props.store.address}</td>
                </tr>
                <tr>
                  <td>Registered for vouchers</td>
                  <td> : {this.props.store.has_voucher ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td>Registered for loyalty</td>
                  <td> : {this.props.store.has_loyalty ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <td>Registered members</td>
                  <td> : {this.props.store.total_members}</td>
                </tr>
                <tr>
                  <td>Store created on</td>
                  <td> : {this.props.store.created_at}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={this.state.loading} variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}


export default ShowStore;