import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import TransactionTypeWidget from '../TransactionTypeWidget';
import TransactionModal from "../../transaction/modals/TransactionModal";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


class TransactionTable extends Component {
  render () {
    let columns = [
      {
        name: 'Date',
        selector: 'created_at',
        sortable: true
      },
      {
        name: 'Member',
        selector: row => row.cell_number,
        cell: row => <><i className='fa fa-phone-square'> </i> &nbsp;{row.cell_number}</>,
        sortable: true
      },
      {
        name: 'Points',
        selector: 'amount',
        cell: row => row.amount,
        sortable: true
      },
      {
        name: 'Amount',
        selector: 'currency_amount',
        cell: row => 'R ' + row.currency_amount,
        sortable: true
      },
      {
        name: 'Total order',
        selector: 'order_total',
        cell: row => 'R ' + (row.order_total ?? 0),
        sortable: true
      },
      {
        name: 'Gross order total',
        selector: 'gross_order_total',
        cell: row => 'R ' + (row.gross_order_total ?? 0),
        sortable: true
      },
      {
        name: 'Status',
        selector: 'transaction_status',
        sortable: true
      },

      {
        name: 'Type',
        selector: 'transaction_type',
        cell: row => <TransactionTypeWidget type={row.transaction_type} />,
        sortable: true
      },
      {
        name: '',
        cell: row => <TransactionModal id={row.id} />
      }
    ];

    const data = this.props?.transactions;
    const tableData = {
      columns,
      data
    };
    return (
      <>
      <DataTableExtensions {...tableData} filter={false}>
        <DataTable
            columns={columns}
            data={data}
            className='transaction-table'
            pagination
          />
          </DataTableExtensions>
      </>
    );
  }
}

export default TransactionTable;
