import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { formatDate } from "../../../../helpers/date";
import { downLoadExcel } from "../../../../helpers/export";
import { getStoreMembersTransaction } from "../../../../store/actions/stores";
import Loader from "../../../utils/Loader";
import DateRangePickerWidget from "../../widgets/DateRangePicker";

function DownLoadTransactionsReport({ user, store_id }) {
  const [show, setShow] = useState(false);

  const { log } = console;
  // get 2 months date range from today
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(new Date().setMonth(new Date().getMonth() + 2)),
  });
  const [loading, setLoading] = useState(false);

  const downloadReport = () => {
    let user_id = user.id;
    const body = {
      store_id: store_id ?? '',
      user_id: user_id,
      from: formatDate(dateRange.startDate),
      to: formatDate(dateRange.endDate),
    };
    try {
      setLoading(true);
      getStoreMembersTransaction(body).then((data) => {
        if (data.length > 0) {
          let transaction = data.map((data) => {
            return {
              "Order Date": data?.order_date,
              "Cashier Code": data?.cashier_code ?? "N/A",
              "Transaction Type": data?.transaction_type,
              "Transaction Status": data?.transaction_status,
              "Order Reference": data?.order_reference,

              "Crowns Earned": `${data?.amount}`,
              "Crowns Redeemed": `${data?.redeem_points}`,
              "Crowns Earned (rand value)": `R ${data?.amount / 10} `,
              "Crowns Redeemed (rand value)": `R ${data?.redeem_points / 10} `,
              "Order Total": `R ${data?.gross_order_total}`,
            };
          });

          if (transaction) {
            setLoading(false);
            downLoadExcel(transaction, `${user?.first_name}`);
                  toast.success("Report generated successfully");
                  handleClose()
          }
        } else {
          toast.error(
            "No transactions found in selected period,try a different date range"
          );
          setLoading(false);
        }
      });
    } catch (err) {
      setLoading(false);
      log(err);
      toast.error(
        "Could not download report at this time, please try again later"
      );
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDateChange = (ranges) => {
    setDateRange({
      startDate: ranges.start_date,
      endDate: ranges.end_date,
    });
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Download
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="text-center">{`${
              user?.first_name
            }'s transation report from ${formatDate(
              dateRange.startDate
            )} to ${formatDate(dateRange.endDate)} `}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <h4>Select date Range</h4>
              <DateRangePickerWidget
                start_date={dateRange.startDate + " 00:00:00"}
                end_date={dateRange.endDate + " 23:59:00"}
                handleUpdateDates={handleDateChange}
              />
            </div>

            <div>
              {loading ? (
                <Loader />
              ) : (
                <button className="btn btn-primary" onClick={downloadReport}>
                  Generate report
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DownLoadTransactionsReport;
